import { Campaign, CampaignType } from "models/campaign"
import { Pagination } from "utils/pagination"
import { api } from "./api"

type CampaignResponse = {
  counters: CampaignCounters
  campaigns: Pagination<Campaign>
}

export type CampaignCounters = {
  invested: CampaignResponseItem
  leads: CampaignResponseItem
  visits: CampaignResponseItem
}

type CampaignResponseItem = {
  current: number
  past: number
}

export const getCampaignsResume = async (initDate: Date, endDate: Date): Promise<CampaignResponse> => {
  const { data } = await api.get<CampaignResponse>(
    `v1/campaigns?initDate=${initDate.toISOString()}&endDate=${endDate.toISOString()}`
  )
  return data
}

type CampaignItemResponse = {
  id: string
  name: string
  type: CampaignType
  counters: CampaignCounters
  campaigns: {
    numberOfPages: number
    perPage: number
    data: Campaign[][]
  }
}

export const getCampaignsById = async (campaignId: string, perPage: number, initDate: Date, endDate: Date): Promise<CampaignItemResponse> => {
  const { data } = await api.get<CampaignItemResponse>(
    `/v1/campaigns/${campaignId}?perPage=${perPage}&initDate=${initDate.toISOString()}&endDate=${endDate.toISOString()}`
  )
  return data
}
