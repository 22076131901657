import { HTMLInputTypeAttribute } from "react";

export const useInputType = (type?: HTMLInputTypeAttribute, showPassword?: boolean) => {
  const inputType = () => {
    switch (type ?? "text") {
      case "password":
        return showPassword ? "text" : "password";
      case "number":
        return "number"
      case "date":
        return "date"
      default:
        return "text";
    }
  }

  const inputMode = (): "email" | "text" | "numeric" | "search" | "none" | "tel" | "url" | "decimal" | undefined => {
    switch (type ?? "text") {
      case "number":
      case "money":
      case "masked_number":
      case "date":
        return "numeric"
      case "phone":
        return "tel"
      case "email":
        return "email"
      default:
        return "text"
    }
  }

  return {
    inputType: inputType(),
    inputMode: inputMode()
  }
}