import { MultiSelect } from "components/Forms/MultiSelect"
import { socialOrigins } from "models/lead"
import { IState } from "store"
import { useDispatch, useSelector } from "react-redux"
import { updateOriginFilter } from "store/slices/boardFilters"
import { LeadPeriodFilter } from "models/filter"
import { Checkbox } from "components/Forms/Checkbox"
import { useFilters } from "hooks/useFilters"
import { updateSelectedTypeOfDate } from "store/slices/filters"

export type FilterOption = 'date' | 'all' | 'selled'

const leadsPeriodFilters: { value: LeadPeriodFilter, label: string }[] = [
  { value: 'default', label: 'Data de entrada do Lead' },
  { value: 'update', label: 'Data de atualização do Lead' },
  { value: 'selled', label: 'Data de venda do Lead' },
]

export const LeadBoardFilters = () => {

  const dispatch = useDispatch()
  const { originFilters } = useSelector((state: IState) => state.boardFilters)
  const { selectedLeadPeriodFilter } = useFilters()

  const handleChangeOriginsFilters = (values: string[]) => {
    dispatch(updateOriginFilter(values))
  }

  const handleChangeLeadPeriodFilter = (value: LeadPeriodFilter) => () => {
    dispatch(updateSelectedTypeOfDate(value))
  }

  return (
    <div className="flex flex-col gap-4">
      <p className="text-base">Filtros de Leads:</p>
      {
        leadsPeriodFilters.map(period => (
          <Checkbox
            key={period.value}
            label={period.label}
            isChecked={period.value === selectedLeadPeriodFilter}
            onClick={handleChangeLeadPeriodFilter(period.value)}
          />
        ))
      }
      <MultiSelect 
        onChange={handleChangeOriginsFilters}
        options={socialOrigins}
        selected={originFilters}
        variant="secondary"
        clearable
        placeholder="Origem dos leads"
        invertOptionsBox
      />
    </div>
  )
}
