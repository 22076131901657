export enum Masks {
  INTERNAL_PHONE = "+NN (NN) NNNNN-NNNN",
  PHONE = "(NN) NNNNN-NNNN",
  OLD_PHONE = "(NN) NNNN-NNNN",
  CEP = "NNNNN-NNN",
  CPF = "NNN.NNN.NNN-NN",
  MONEY = "NNNNNNNNN,NN",
  DATE = "NN/NN/NNNN",
  RG = "NN.NNN.NNN-N",
  CNPJ = "NN.NNN.NNN/NNNN-NN",
}

function unMaskText(value: string): string {
  return value.replace(/[^a-zA-z0-9]/g, '').trim()
}

function unMaskMoney(value: string): string {
  return unMaskText(value).replace("$", "").replace("R", "")
}

function unMaskMoneyToNumber(value: string): number {
  return parseFloat(unMaskMoney(value)) / 100
}

function maskText(newText: string, mask: Masks | Masks[], oldTextLenght: number = 0): string {
  const unmaskedValue = unMaskText(newText)
  let maskedValue = ""
  
  let currentMask = ""

  if (Array.isArray(mask)) {
    const masksByLenght = mask.sort((a, b) => a.length - b.length)
    currentMask = masksByLenght[0]

    if (mask.includes(Masks.OLD_PHONE) && mask.includes(Masks.PHONE)) {
      if (unmaskedValue.length === 11) currentMask = Masks.PHONE
      else currentMask = Masks.OLD_PHONE
    } else {
      for (let i = 1; i < masksByLenght.length; i++) {
        if (newText.length >= masksByLenght[i - 1].length) {
          currentMask = masksByLenght[i]
        }
      }
    }
  } else {
    currentMask = mask
  }

  if (unmaskedValue) {
    let unmaskedLenght = 0
    for (let i = 0; i < currentMask.length; i++) {
      const nextWord = unmaskedValue[unmaskedLenght]

      if (oldTextLenght > newText.length && nextWord === undefined) {
        break
      }

      if (currentMask[i] !== "N") {
        maskedValue += currentMask[i]
      } else {
        if (nextWord) {
          if (currentMask[i] === "N" && !isNaN(parseInt(nextWord))) {
            maskedValue += nextWord
          }
        } else {
          break
        }

        unmaskedLenght++
      }
    }
    return maskedValue
  }
  return unmaskedValue
}

function maskMoney(newText: string): string {
  const formatter = Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" })
  const unmaskedValue = unMaskMoney(newText)
  const maskedValue = parseFloat(unmaskedValue) / 100

  if (Number.isNaN(maskedValue)) {
    return formatter.format(0)
  }

  return formatter.format(maskedValue)
}

export { unMaskText, unMaskMoney, unMaskMoneyToNumber, maskText, maskMoney }