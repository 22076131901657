import { cn } from "utils/styles"
import { ConvertionCard } from "./ConvertionCard"
import { Resume1Card } from "./Resume1Card"
import { Resume2Card } from "./Resume2Card"
import { SalesCard } from "./SalesCard"
import { SalesCostCard } from "./SalesCostCard"
import { SellsData } from "services/dashboardService"

type Props = {
  variant?: 'primary' | 'secondary'
  sellsData: SellsData
}

export const SalesResume = ({ variant = 'primary', sellsData }: Props) => {

  return (
    <div className={cn(
      "flex flex-col desktop:flex-row gap-3 desktop:gap-1.5 rounded-2xl",
      variant === 'primary' ? 'bg-primary/40 p-4' : 'flex-1'
    )}>
      <div className="flex flex-col laptop:flex-row gap-3 desktop:gap-1.5 desktop-xl:flex-1">
        <SalesCard
          variant={variant}
          salles={sellsData.soldGrouped.leads}
          oldSalles={sellsData.oldSoldGrouped.leads}
        />
        <ConvertionCard
          variant={variant}
          convertion={sellsData.soldGrouped.leadsPercentage}
        />
        <SalesCostCard
          variant={variant}
          sellCost={sellsData.soldGrouped.leadsCost}
        />
      </div>
      <div className="flex gap-3 desktop:gap-1.5 flex-1 flex-col laptop:flex-row">
        <Resume1Card
          variant={variant}
          installed={sellsData.sold.leads}
          negotiation={sellsData.negotiation.leads}
          open={sellsData.open.leadsPercentage}
          scheduled={sellsData.scheduled.leads}
          alreadyClient={sellsData.alreadyClient.leads}
        />
        <Resume2Card
          variant={variant}
          gaveUp={sellsData.gaveUp.leads}
          lostLeads={sellsData.lostLead.leads}
          lostOportunity={sellsData.lostOpportunity.leadsPercentage}
          stopResponding={sellsData.stopResponding.leads}
        />
      </div>
    </div>
  )
}