import { Combobox, Transition } from '@headlessui/react'
import { XMarkIcon  } from '@heroicons/react/20/solid'

import { Label } from "../Label";

import { forwardRef, Fragment, useState } from 'react';
import { cn } from 'utils/styles';
import { DotLoading } from 'components/DotLoading';

export type SelectItem = {
  label: string
  value: string|number
}

type SelectProps = {
  id?: string
  label?: string
  selected?: string
  error?: string
  disabled?: boolean
  options: SelectItem[]
  onChange: (value: string | undefined) => void
  required?: boolean
  borderless?: boolean
  invertOptionsBox?: boolean
  placeholder?: string
  clearable?: boolean
  centered?: boolean
  variant?: "primary" | "secondary"
  isLoading?: boolean
}

const Select = forwardRef<HTMLDivElement, SelectProps>(({
  label, selected = "", error, disabled, options, onChange, required, invertOptionsBox = false,
  placeholder, clearable, centered, variant = 'primary', isLoading
}: SelectProps, ref) => {

  const [query, setQuery] = useState('')

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase())
        })

  const handleClear = () => {
    setQuery('')
    onChange(undefined)
  }

  return (
    <div className="w-full flex flex-col relative" ref={ref}>
      {
        label && <Label className='px-5 mb-3'>{label} {required ? "*" : ""}</Label>
      }
      {
        isLoading && (
          <DotLoading className={cn(
            'absolute top-1/2 -translate-y-1/2 right-1/3 pl-4',
            label ? 'mt-4.5' : ''
          )} />
        )
      }
      <Combobox value={selected} onChange={onChange} disabled={disabled}>
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden outline-none text-left sm:text-sm">
            <Combobox.Input
              className={`
                w-full border-none outline-none py-2 px-5 text-base 
                ${disabled ? 'text-gray-500' : 'text-white'} rounded-full 
                ${variant === 'primary'
                  ? disabled ? 'bg-primaryMultiply placeholder:text-white/30' : 'bg-primaryMultiply placeholder:text-white/30'
                  : disabled ? 'bg-gray-500/30' : 'bg-white/30'}
                  ${centered && "text-center"}`
              }
              placeholder={placeholder}
              displayValue={(option: string) => options.find(item => item.value === option)?.label ?? ""}
              onChange={(event) => setQuery(event.target.value)}
            />
            {selected && clearable && !disabled && (
              <Combobox.Button className="absolute inset-y-0 right-5 flex items-center pr-6" onClick={handleClear}>
                <XMarkIcon
                  className={cn("h-5 w-5 text-secondary")}
                  aria-hidden="true"
                />
              </Combobox.Button>
            )}
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-5">
              <img
                src="/icons/form/select_arrow.svg"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className={`font-input absolute ${invertOptionsBox && "bottom-10"} z-50 mt-1 max-h-60 w-full overflow-auto bg-primaryMultiply/90 text-base shadow-xl focus:outline-none sm:text-sm rounded-2xl`}>
              {filteredOptions.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-white">
                  Não encontrado.
                </div>
              ) : (
                filteredOptions.map((option) => (
                  <Combobox.Option
                    key={option.value}
                    className={({ active }) => cn(
                      "relative select-none text-white",
                      active ? 'bg-primary/40 rounded-xl' : '',
                      centered ? "text-center" : "text-left"
                    )}
                    value={option.value}
                  >
                    {({ selected }) => (
                      <span
                        className={`block truncate cursor-pointer py-2 px-4 ${
                          selected ? 'bg-primary/40 rounded-xl' : ''
                        }`}
                      >
                        {option.label}
                      </span>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {error
        && <p
            className={`${error ? "text-red-500 text-xs py-2 leading-4 px-5" : ""}`}
          >
            {error}
          </p>}
    </div>
  )
})

export { Select }