import { APISeller, Seller, parseAPISeller } from "models/seller"
import { PaginatedData } from "utils/pagination"
import { api } from "./api"

export const getSeller = async (id: string): Promise<Seller> => {
  const { data } = await api.get<{ data: APISeller }>(`/v1/sellers/${id}`)
  return parseAPISeller(data.data)
}

export const getSellers = async (page: number, perPage: number, selectedProvider: string | undefined): Promise<PaginatedData<Seller>> => {
  const query = new URLSearchParams()

  query.append("page", page.toString())
  query.append("perPage", perPage.toString())

  if (selectedProvider) {
    query.append("providerId", selectedProvider)
  }

  const { data } = await api.get<PaginatedData<APISeller>>(`/v1/sellers?${query.toString()}`)
  return {
    ...data,
    data: data.data.map(parseAPISeller)
  }
}

export const changeSellerStatus = async (providerId: string, status: number): Promise<void> => {
  await api.patch(`/v1/sellers/${providerId}/status`, { status })
}

type NewSeller = {
  email: string
  name: string
  password: string
  status: number
}

export const addSeller = async (seller: NewSeller): Promise<string> => {
  const { data } = await api.post<{ data: { _id: string } }>("/v1/sellers", seller)
  return data.data._id
}

export const updateSeller = async (seller: Seller): Promise<void> => {
  await api.put(`/v1/sellers/${seller.id}`, seller)
}

// export const deleteSeller = async (id: string): Promise<void> => {
//   await api.delete(`/v1/sellers/${id}`)
// }
