import { DatePeriods, SelectedDate } from "./filters";

export const datesAreOnSameDay = (first: Date, second: Date) => {
  return first.getFullYear() === second.getFullYear()
  && first.getMonth() === second.getMonth()
  && first.getDate() === second.getDate();
}

export const isToday = (date: Date) => {
  const second = new Date()
  return date.getFullYear() === second.getFullYear()
  && date.getMonth() === second.getMonth()
  && date.getDate() === second.getDate();
}

export const diffInMonthsFromToday = (date: Date) => {
  return Math.ceil(
    (new Date().getTime() - date.getTime()) / (1000 * 3600 * 24 * 30)
  )
}

export const diffInMonths = (first: Date, second: Date) => {
  return Math.ceil(
    (second.getTime() - first.getTime()) / (1000 * 3600 * 24 * 30)
  )
}

type ToMsParams = {
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
  milliseconds?: number
}

export const toMs = ({ days = 0, hours = 0, minutes = 0, seconds = 0, milliseconds = 0 }: ToMsParams) => {
  return (days * 24 * 60 * 60 * 1000)
    + (hours * 60 * 60 * 1000)
    + (minutes * 60 * 1000)
    + (seconds * 1000)
    + milliseconds
}

export const dateRangeFromPeriod = (period: DatePeriods, reset?: boolean): SelectedDate | undefined => {
  const today = new Date();
  let newDateRange: SelectedDate | undefined = undefined

  switch(period) {
    case "today":
      newDateRange = {
        initDate: new Date(today),
        endDate: new Date(today)
      }
      break;
    case "yesterday": {
      const yesterday = new Date(today.setDate(today.getDate() - 1));
      newDateRange = {
        initDate: new Date(yesterday),
        endDate: new Date(yesterday)
      }
      break;
    }
    case "last7days":
      newDateRange = {
        initDate: new Date(new Date().setDate(new Date().getDate() - 6)),
        endDate: new Date(today)
      }
      break;
    case "last14days":
      newDateRange = {
        initDate: new Date(new Date().setDate(new Date().getDate() - 13)),
        endDate: new Date(today)
      }
      break;
    case "last30days":
      newDateRange = {
        initDate: new Date(new Date().setDate(new Date().getDate() - 29)),
        endDate: new Date(today)
      }
      break;
    case "thisMonth":
      newDateRange = {
        initDate: new Date(new Date().setDate(1)),
        endDate: new Date(today)
      }
      break;
    case "lastMonth": {
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      newDateRange = {
        initDate: new Date(lastMonth),
        endDate: new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0)
      }
      break;
    }
    case "last6Months":
      newDateRange = {
        initDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
        endDate: new Date(today)
      }
      break;
    case "thisYear": {
      const thisYear = new Date(today.getFullYear(), 0, 1);
      const endOfYear = new Date(today);
      newDateRange = {
        initDate: new Date(thisYear),
        endDate: new Date(endOfYear)
      }
      break;
    }
    case "lastYear": {
      const lastYear = new Date(today.getFullYear() - 1, 0, 1);
      newDateRange = {
        initDate: new Date(lastYear),
        endDate: new Date(today.getFullYear(), 0, 0)
      }
      break
    }
  }
  
  return newDateRange
}

export const dateRangeFromPeriodAndSelectedDate = (
  period: DatePeriods, selectedDate?: SelectedDate, reset?: boolean
): SelectedDate | undefined => {
  let newDateRange: SelectedDate | undefined = undefined

  switch(period) {
    case 'custom':
      if (reset && selectedDate) {
        newDateRange = {
          initDate: new Date(selectedDate!.initDate),
          endDate: selectedDate.endDate ? new Date(selectedDate.endDate) : new Date(selectedDate.initDate)
        }
      }
      break
    default:
      newDateRange = dateRangeFromPeriod(period)
      break
  }
  
  return newDateRange
}