import { Button } from "components/Button"
import { DotLoading } from "components/DotLoading"
import { TableBody, TableField, TableOverride, TableTransform } from "./components/TableBody"
import { cn } from "utils/styles"
import { SpinnerLoading } from "components/SpinnerLoading"

type TableProps<T> = {
  className?: string
  headers: string[]
  fields: TableField<T>[]
  transforms?: TableTransform<T>[]
  overrides?: TableOverride[]
  data?: T[]
  actions?: (item: T, index: number) => JSX.Element | null
  isLoading?: boolean
  hasError?: boolean
  onRetry?: () => void
  variant?: 'primary' | 'secondary'
  isRetching?: boolean
}

export function Table<T,>({
  className, headers, fields, transforms, overrides, data, actions, isLoading,
  hasError, onRetry, variant = 'primary', isRetching
}: TableProps<T>) {

  return (
    <div className={cn(
      className,
      "h-mobileTable tablet:h-table w-full overflow-y-scroll rounded-2xl relative",
      variant === 'primary'
      ? 'border-white/30 border-2 p-8'
      : ''
    )}>
      {isRetching ? <div className="absolute top-4 left-4"><SpinnerLoading /></div> : null}
      {!isLoading && !hasError && data && data.length > 0 && (
        <table className="w-full text-left border-separate border-spacing-y-4">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th 
                  key={index}
                  colSpan={index < headers.length - 1 ? 1 : 2}
                  className={cn(
                    "text-secondary font-regular font-normal text-base leading-none first:pl-8 pr-2 whitespace-nowrap",
                    variant === 'primary' ? 'pb-3' : ''
                  )}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <TableBody 
            fields={fields}
            transforms={transforms}
            overrides={overrides}
            data={data}
            actions={actions}
            variant={variant}
          />
        </table>
      )}
      {isLoading && (
        <div className="flex items-center justify-center h-full flex-col gap-2 pb-32 text-lg">
          <p>Carregando informações</p>
          <DotLoading/>
        </div>
      )}
      {!isLoading && !hasError && data?.length === 0 && (
        <div className="flex items-center justify-center h-full pb-32 text-lg">
          <p>Nenhum dado encontrado</p>
        </div>
      )}
      {
        hasError && (
          <div className="flex items-center justify-center h-full flex-col gap-4 pb-32 text-lg">
            <p>Ocorreu um erro ao buscar os dados</p>
            <Button onClick={onRetry}>Tentar novamente</Button>
          </div>
        )
      }
    </div>
  )
}