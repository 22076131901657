import React, { InputHTMLAttributes, useEffect, useState } from "react";

import { maskMoney, unMaskMoney } from "utils/mask";

import { Label } from "../Label";

import { cn } from "utils/styles";

type MoneyTextFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'id' | 'onChange'> & {
  id?: string;
  label: string;
  error?: string;
  helpText?: string;
  required?: boolean
  value?: string
  onChange?: (value: string) => void
  containerClassName?: string
  variant?: "primary" | "secondary"
}

export function MoneyTextField({
  label, value, type = "text", containerClassName,
  error, helpText, onChange, className, variant = 'primary',
  disabled, required, id, ...props
}: MoneyTextFieldProps) {

  const [masked, setMasked] = useState(false)

  function handleMask(event : React.ChangeEvent<HTMLInputElement>) {
    const text = unMaskMoney(event.target.value)

    onChange?.(maskMoney(text))
  }

  useEffect(() => {
    if (value === undefined) return
    if (masked) return
    if (value.includes('R$')) {
      setMasked(true)
    }

    const timeout = setTimeout(() => {
      const _value = value ?? ""
      onChange?.(maskMoney(Number(_value).toFixed(2)))
    })

    return () => clearTimeout(timeout)
  }, [value, masked])

  return (
    <div className={cn(containerClassName, "flex flex-col w-full")}>
      { label && <Label className="px-5 mb-3">{label} {required ? "*" : ""}</Label> }
      <div className={cn('flex rounded-full', variant === 'primary' ? 'bg-primaryMultiply' : 'bg-white/30')}>
        <input
          id={id}
          name={id}
          value={value}
          onChange={handleMask}
          disabled={disabled}
          className={cn(className, "py-2 px-5 outline-none border-transparent bg-transparent w-full text-base placeholder:text-white/30")}
          {...props}
        />
      </div>
      {(error || helpText) && (
        <p className={cn(error ? 'text-red-500' : 'text-black', 'text-xs py-2 leading-4 px-5')}>
          {error ?? helpText}
        </p>
      )}
    </div>
  );
}