import { DndContext, closestCorners, DragOverlay, defaultDropAnimation } from '@dnd-kit/core'
import { LeadColumn } from './LeadColumn'
import { LeadCardItem } from './LeadCardItem'
import { LeadColumnNew } from './LeadColumnNew'
import { useViewModel } from './viewModel'
import { DotLoading } from 'components/DotLoading'
import { Button } from 'components/Button'
import { LeadDialogForm } from './LeadDialogForm'
import { useBoardDragDropViewModel } from './boardDragDropViewModel'
import { useBoardCrudViewModel } from './boardCrudViewModel'
import { LeadColumnDialogForm } from './LeadColumnDialogForm'
import { LeadSellingForm } from './LeadSellingForm'
import { cardColumnColor } from 'models/board'
import ErrorBoundary from 'components/ErrorBoundary'
import { Label } from 'components/Forms/Label'

export const LeadsBoard = () => {

  const controller = useViewModel()
  const dragDropController = useBoardDragDropViewModel({
    leadsColumns: controller.leadsColumns,
    setLeadsColumns: controller.setLeadsColumns,
    refetch: controller.fetchLeadsBoard
  })
  const crudController = useBoardCrudViewModel({
    leadsColumns: controller.leadsColumns,
    setLeadsColumns: controller.setLeadsColumns,
    refetch: controller.fetchLeadsBoard,
    originsFilter: controller.originFilters
  })

  if (controller.screenState === 'loading') {
    return (
      <div className='w-full flex flex-col gap-6 items-center justify-center h-[calc(75svh)]'>
        <p className='text-accent-500 text-xl'>Carregando funil de leads</p>
        <DotLoading />
      </div>
    )
  }

  if (controller.screenState === 'error') {
    return (
      <div className='w-full flex flex-col gap-6 items-center justify-center text-center h-[calc(75svh)]'>
        <p className='text-accent-500 text-xl'>Ops, algo deu errado</p>
        <p>Não foi possível carregar o funil de leads,<br></br>por favor tente novamente</p>
        <Button onClick={controller.retry}>Tentar novamente</Button>
      </div>
    )
  }

  return (
    <div>
      {controller.lastUpdateAt && (
        controller.refetching ? (
          <p className='text-xs h-[22px] py-0.5'>Atualizando...</p>
        ) : (
          <div className='flex gap-2 items-center'>
            <p className='text-xs'>Última atualização: {Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'medium' }).format(controller.lastUpdateAt)}</p>
            <button className='text-xs border border-white rounded px-1.5 py-0.5' onClick={controller.refetch}>Atualizar</button>
          </div>
        )
      )}
      {
        crudController.editLead ? (
          <ErrorBoundary fallback={<Label>teste</Label>}>
            <LeadDialogForm
              onClose={crudController.handleCloseEditLead}
              lead={crudController.editLead}
              onSave={crudController.handleConfirmEditLead}
              sellers={controller.sellers}
              columns={controller.leadsColumns}
            />
          </ErrorBoundary>
        ) : null
      }
      {
        dragDropController.leadSold ? (
          <LeadSellingForm
            onClose={dragDropController.rollbackMove}
            lead={dragDropController.leadSold.leardCardMove.item}
            onSave={dragDropController.confirmMove}
          />
        ) : null
      }
      {
        crudController.isAddingOrEditingColumn ? (
          <LeadColumnDialogForm
            column={crudController.selectedColumn?.column}
            onClose={crudController.handleCloseAddOrEditColumnDialog}
            onSave={crudController.handleAddOrUpdateColumn}
            beforeColumnIndex={crudController.selectedColumn?.beforeIndex}
          />
        ) : null
      }
      <div className='flex gap-8 overflow-x-scroll scrollbar mt-4 bg-primary/40 rounded-2xl px-4 pt-8 pb-8'>
        <DndContext
          sensors={dragDropController.sensors}
          collisionDetection={closestCorners}
          onDragEnd={dragDropController.handleDragEnd}
          onDragOver={dragDropController.handleDragOver}
          onDragStart={dragDropController.handleDragStart}
        >
          {
            controller.leadsColumns.map((column, index) => (
              <div key={column.id}>
                <LeadColumn
                  index={index}
                  {...column}
                  onDeleteColumn={crudController.handleDeleteColumn(index)}
                  onAddNewColumnBefore={crudController.handleAddNewColumnBefore(index)}
                  isDragging={dragDropController.isDraging}
                  onLoadMore={controller.handleLoadMore(index)}
                  onEditLead={crudController.handleOpenEditLead}
                  onEditColumn={crudController.handleShowAddOrEditColumnDialog}
                />
              </div>
            ))
          }
          <DragOverlay dropAnimation={{ ...defaultDropAnimation }}>
            {dragDropController.activeItem ? (
              <LeadCardItem
                {...dragDropController.activeItem.item}
                color={cardColumnColor[
                  controller.leadsColumns.find(
                    item => dragDropController.activeItem?.item.status === item.id
                  )?.tag?.value ?? 'unkown'
                ]}
              />
            ) : null}
          </DragOverlay>
          {controller.user?.role !== 'seller'
            ? <LeadColumnNew handleAddNewColumn={crudController.handleAddNewColumn} />
            : null
          }
        </DndContext>
      </div>
    </div>
  )
}