import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { getProvider } from "services/providerService"
import { useQuery } from "react-query"
import useAuth from "hooks/useAuth"
import { PROVIDER_SELECT_QUERY } from "components/Page/PageFilter/ProviderFilter"
import { ILeadSocialOrigin } from "models/lead"
import { Errors } from "utils/error"
import { createLead } from "services/leadService"
import { useNavigate } from "react-router-dom"

export type FormFields = {
  name: string
  date: string
  phone: string
  socialOrigin: ILeadSocialOrigin
  columnId: string
  seller: string
  selledAt: string
}

export const useViewModel = () => {
  
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { selectedProvider, sellers, user } = useAuth()
  const { register, formState: { errors }, handleSubmit, setValue, watch } = useForm<FormFields>()
  const { data: providerColumns, isLoading: isLoadingProviderColumns } = useQuery(
    [PROVIDER_SELECT_QUERY, selectedProvider],
  () => getProvider(selectedProvider), { 
    enabled: selectedProvider !== 'admin' || (user && user.role !== 'admin'),
    select: provider => {
      return provider.leadsStatus.map(status => ({ label: status.name, value: status.id, tag: status.tag }))
    }
  })

  const phone = watch("phone") ?? ""
  const columnId = watch("columnId")
  const socialOrigin = watch("socialOrigin")
  const seller = watch("seller")

  const isSelledColumn = useMemo(() => {
    if (!providerColumns) return false
    const colum = providerColumns.find(c => c.value === columnId)
    return colum?.tag?.value === "sold" || colum?.tag?.value === "scheduled"
  }, [columnId, providerColumns])

  useEffect(() => {
    register("phone", {
      required: { value: true, message: "O telefone é obrigatório" },
      minLength: { value: 14, message: "O telefone é inválido" },
      maxLength: { value: 15, message: "O telefone é inválido" }
    })
    register("socialOrigin", { required: { value: true, message: "A origem do lead é obrigatória" } })
    register("columnId", { required: { value: true, message: "A coluna é obrigatória" } })
  }, [register])

  const sellerOptions = useMemo(() => {
    const options = sellers?.map(seller => ({ label: seller.name, value: seller.id }))
    
    return options
  }, [sellers])

  const handleChangeSocialOrigin = (value: string | undefined) => setValue("socialOrigin", value as ILeadSocialOrigin)
  const handleChangeColumnId = (value: string | undefined) => setValue("columnId", value as string)
  const handleChangePhone = (phone: string) => setValue("phone", phone)
  const handleChangeSeller = (value: string | undefined) => {
    if (value) {
      setValue("seller", value)
    }
  }

  const addTimezone = (date: Date) => {
    date.setHours(date.getHours() + 3)
    return date
  }

  async function handleOnSubmit(fields: FormFields) {
    setLoading(true)

    const phone = "55" + fields.phone.replace(/\D/g, "")
    
    const seller = user?.role === 'seller' ? user._id : fields.seller

    try {
      await createLead({
        ...fields,
        seller: seller ?? null,
        date: addTimezone(new Date(fields.date)).toISOString(),
        status: fields.columnId,
        phone: phone,
        channel: "whatsapp",
        contact: phone,
        contactType: "phone",
        document: phone,
        documentType: "phone",
        selledAt: fields.selledAt ? addTimezone(new Date(fields.selledAt)).toISOString() : null
      })
      navigate('/leads')
    } catch(err) {
      const _err = err as AxiosError
      if (_err.isAxiosError) {
        const errorData = _err.response?.data as { errors: { code: Errors} }
        if (errorData) {
          switch(errorData.errors.code) {
            case 'general.bad_request':
              toast.error("A coluna selecionada não é válida")
              
              return
            case 'lead.already_exists':
              return toast.error("Já existe um lead cadastrado com esse telefone!")
          }
        }
      }
      toast.error("Ocorreu um erro ao adicionar o lead")
    } finally {
      setLoading(false)
    }
  }

  return {
    user,
    loading,
    register,
    errors,
    handleSubmit: handleSubmit(handleOnSubmit),
    socialOrigin,
    providerColumns,
    isLoadingProviderColumns,
    phone,
    handleChangePhone,
    handleChangeSocialOrigin,
    handleChangeColumnId,
    columnId,
    sellerOptions,
    handleChangeSeller,
    seller,
    selectedProvider,
    isSelledColumn
  }
}