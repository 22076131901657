import { botApi } from "./api"

export type WppResponseStatus = "connecting" | "connected" | "waiting_qr_code" | "error"

type WppConnectionResponse = {
  message: string
  data: {
    status: WppResponseStatus
    qrCode: string | null
  }
}

export const getWppConnectionData = async (session: string): Promise<WppConnectionResponse> => {
  const { data } = await botApi.get<WppConnectionResponse>(`v2/sessions/${session}/connect`)
  return data
}