import { LeadCard, socialOrigins } from "models/lead"
import { useMemo } from "react"
import { Masks, maskText } from "utils/mask"
import { cn } from "utils/styles"

type Props = LeadCard & {
  color: string
  statusLabel?: string
}

export const LeadCardItem = ({
  name, origin, date, contact, contactType, socialOrigin, seller, selledAt, color, statusLabel, campaign, altPhone
}: Props) => {
  const _contact = contact
    ? contactType === 'phone' ? maskText(altPhone ?? contact, Masks.INTERNAL_PHONE).slice(3) : contact
    : "Sem contato"
  const _socialOrigin = socialOrigins.find(item => item.value === socialOrigin)?.label

  const adjustTimeZone = (date: Date) => {
    date.setHours(date.getHours() + 3)
    return date
  }

  const isValidContact = useMemo(() => {
    if (!contact) return false
      
    if (contactType === 'phone') {
      if (contact.startsWith('55')) {
        return contact.length >= 12
      }
      return contact.length >= 9
    }
  
    if (contactType === 'email') {
      return contact.includes('@') && contact.includes('.')
    }
    
    return false
  }, [contact, contactType])

  return (
    <div className="bg-white rounded-lg font-input text-black">
      <div className={cn("rounded-md text-white h-4", color)} />
      <div className="flex justify-between text-sm items-center px-4 pt-1.5">
        <div>
          <p className="leading-4 line-clamp-2 break-words max-w-[10rem]">{name}</p>
          <p className={cn(isValidContact ? '' : 'text-red-500')}>
            {isValidContact ? null : <span className="bg-red-200 rounded text-xs">🚫</span>}
            <span>{_contact}</span>
          </p>
        </div>
        <img className="w-6 h-6" src={`/icons/chat-bots/${origin}.png`} alt="" />
      </div>

      <div className="flex flex-col flex-1 font-input text-xs px-4 pb-1.5">
        { statusLabel ? <p>Coluna: {statusLabel}</p> : null }
        <p>Entrada: {date.toLocaleDateString()}</p>
        { selledAt ? <p>Vendido: {adjustTimeZone(new Date(selledAt)).toLocaleDateString()}</p> : null }
        { seller ? <p>Vendedor: {seller.name}</p> : null }
        <p className="flex gap-0.5">Origem: {_socialOrigin} {campaign && <img width={14} height={14} src="/icons/board/check.svg" />}</p>
      </div>
      <div className="w-4" />
    </div>
  )
}