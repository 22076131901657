import { cn } from "utils/styles";
import { Label } from "../Label";
import { toast } from "react-toastify";

type CopyTextProps = {
  label?: string;
  text: string;
  helpText?: string;
  variant?: "primary" | "secondary";
}

export function CopyText({
  label, text, variant = 'primary'
}: CopyTextProps) {

  function handleCopyText() {
    navigator.clipboard.writeText(text)
    toast.success("Texto copiado com sucesso!")
  }

  return (
    <div>
      { label && <Label className="px-5">{label}</Label> }
      <div className={cn('flex rounded-full py-2 px-5 mt-3', variant === 'primary' ? 'bg-primaryMultiply' : 'bg-white/30')}>
        <span className={cn("line-clamp-1 flex-1 mr-2 text-ellipsis")}>{text}</span>
        <img
          className="cursor-pointer"
          src={`/icons/form/copy.svg`}
          onClick={handleCopyText}
          width="24"
          height="24"
          alt="Copiar texto"
        />
      </div>
    </div>
  );
}