import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LeadPeriodFilter } from "models/filter";
import { DatePeriods } from "utils/filters";

//State
export interface IFiltersState {
  selectedPeriod: DatePeriods
  selectedDate: string
  selectedLeadPeriodFilter: LeadPeriodFilter
}

const INITIAL_STATE: IFiltersState = {
  selectedPeriod: "last30days",
  selectedDate: JSON.stringify({
    initDate: new Date(new Date().setDate(new Date().getDate() - 29)),
    endDate: new Date(new Date().setDate(new Date().getDate()))
  }),
  selectedLeadPeriodFilter: 'default'
};

//Slice
const filtersSlice = createSlice({
  name: "filters",
  initialState: INITIAL_STATE,
  reducers: {
    updateSelectedDate: (state: IFiltersState, action: PayloadAction<string>) => {
      state.selectedDate = action.payload;
    },
    updateSelectedPeriod: (state: IFiltersState, action: PayloadAction<DatePeriods>) => {
      state.selectedPeriod = action.payload;
    },
    updateSelectedTypeOfDate: (state: IFiltersState, action: PayloadAction<LeadPeriodFilter>) => {
      state.selectedLeadPeriodFilter = action.payload;
    }
  }
})

export const { updateSelectedDate, updateSelectedPeriod, updateSelectedTypeOfDate } = filtersSlice.actions
export default filtersSlice.reducer