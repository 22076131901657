import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { ChatIntegration, ChatIntegrationType } from "models/chatIntegration"
import { AxiosError } from "axios"
import { messageToError, ValidationError } from "utils/validation"
import { toast } from "react-toastify"
import { getIntegrationSellers } from "services/chatIntegrationService"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { IntegrationSeller } from "models/integrationSeller"

export type MatrixIntegrationForm = {
  type: ChatIntegrationType
  name: string
  matrix: {
    host: string
    token: string
    sellers: string[]
  }
}

export const useViewModel = (selectedIntegration: ChatIntegration | undefined, handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction) => {
  const {
    register, formState: { errors }, setValue, handleSubmit, setError, watch
  } = useForm<MatrixIntegrationForm>()

  const [step, setStep] = useState(0)
  const [loadingForm, setLoadingForm] = useState(false)
  const [sellers, setSellers] = useState<IntegrationSeller[]>([])

  const selectedSellers = watch("matrix.sellers") ?? []

  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" }
    })
  }, [register])

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "")
    setValue("type", selectedIntegration?.type ?? "matrix")
    setValue("matrix.host", selectedIntegration?.matrix?.host ?? "")
    setValue("matrix.sellers", selectedIntegration?.matrix?.sellers ?? [])
    setValue("matrix.token", selectedIntegration?.matrix?.token ?? "")
  }, [selectedIntegration, setValue])

  async function handleSubmitFormFirstStep(data: MatrixIntegrationForm) {
    if (loadingForm) {
      return
    }
    setLoadingForm(true)

    try {
      toast.info("Verificando integração...")
      const { attendents } = await getIntegrationSellers({
        type: data.type,
        matrix: {
          host: data.matrix.host,
          token: data.matrix.token,
        }
      })
      setSellers(attendents)
      toast.success("Integração verificada com sucesso!")
      setStep(1)
    } catch (error) {
      toast.error(errorMessage(error))
    }
    setLoadingForm(false)
  }

  const errorMessage = (error: unknown) => {
    const _err = error as AxiosError
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as { errors: ValidationError[] | undefined }

      if (data.errors) {
        const fields = data.errors.map(error => {
          setError(error.key as keyof MatrixIntegrationForm, { message: messageToError(error.message) })
          return error.key
        })
        return `Os campos ${fields.join(", ")} são inválidos`
      }
      if (_err.response.status === 400) return "O domínio ou o token são inválidos"
    }
    return "Ocorreu um erro, por favor tente novamente"
  }

  async function handleSubmitFormSecondStep(data: MatrixIntegrationForm) {
    if (loadingForm) {
      return
    }
    setLoadingForm(true)

    await handleSaveOrUpdate(data)

    setLoadingForm(false)
  }

  return {
    selectedIntegration,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    step,
    sellers,
    selectedSellers
  }
}