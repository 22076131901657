import { DotLoading, DotLoadingStyle } from "components/DotLoading"
import ErrorBoundary from "components/ErrorBoundary"
import { ButtonHTMLAttributes } from "react"

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: "small" | "default" | "big" | "tiny"
  variant?: "default" | "outline" | "text" 
  theme?: "default" | "highlight" | "cancel"
  loading?: boolean
  leftIcon?: string
}

export function Button({
  children, size = "default", onClick, loading, variant = "default", theme = "default",
  className, leftIcon, ...props
}: ButtonProps) {

  const buttonClass = {
    "tiny": "text-sm py-1 px-2.5",
    "small": "text-sm py-2 px-3",
    "default": "text-sm py-2 px-4",
    "big": "text-base py-2 w-full tablet:w-auto px-4 tablet:px-6"
  }

  const iconHeightClass = {
    "tiny": "h-3",
    "small": "h-3",
    "default": "h-3",
    "big": "h-4"
  }

  const loadingHeight = {
    "tiny": "h-5",
    "small": "h-5",
    "default": "h-5",
    "big": "h-6"
  }

  const dotLoadingStyle = {
    "default": "default",
    "outline": "primary",
    "text": "primary"
  }

  const themeClass = {
    "default": {
      "default": "bg-secondary text-white text-center hover:bg-secondary/90 transition-colors duration-200",
      "outline": "bg-transparent border-secondary border text-secondary text-center hover:bg-secondary/5 transition-colors duration-200",
      "text": "bg-transparent text-secondary text-center hover:text-secondary/80 transition-colors duration-200 px-0"
    },
    "highlight": {
      "default": "bg-gradient-to-r from-primary to-secondary text-white text-center hover:opacity-90 transition-opacity duration-200",
      "outline": "bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text text-center border-secondary border ",
      "text": "bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text text-center hover:text-secondary/20 transition-colors duration-200 px-0"
    },
    "cancel": {
      "default": "bg-tertiary text-white text-center hover:bg-tertiary/90 transition-colors duration-200",
      "outline": "bg-transparent border-tertiary border text-tertiary text-center hover:bg-tertiary/20 transition-colors duration-200",
      "text": "bg-transparent text-tertiary text-center hover:text-tertiary/80 transition-colors duration-200 px-0"
    },
  }

  const Button = (
    <button 
      onClick={onClick}
      className={[className, buttonClass[size], themeClass[theme][variant], "rounded-full flex gap-1.5 items-center justify-center outline-none"].join(" ")}
      {...props}
    >
      {!loading && leftIcon && <img src={leftIcon} className={iconHeightClass[size]} alt="Ícone do botão" />}
      {loading ? <DotLoading style={dotLoadingStyle[variant] as DotLoadingStyle } className={`${loadingHeight[size]}`} /> : children}
    </button>
  )

  return (
    <ErrorBoundary fallback={Button}>
      {Button}
    </ErrorBoundary>
  )
}