import { Select } from "components/Forms/Select"
import { CampaignType } from "models/campaign"
import { Integration } from "models/integration"
import { useState } from "react"
import { MetaForm } from "./MetaForm"
import { SaveOrUpdateCampaignsIntegrationFunction } from "../viewModel"
import { GoogleForm } from "./GoogleForm"

const options = [
  {value: "facebook", label: "Facebook"},
  {value: "google", label: "Google"}
]

const videoLinks = {
  facebook: 'https://www.loom.com/share/26a698dc452543cebed125b06d914d29',
  google: 'https://www.loom.com/share/8928065993d2486bb64fe8da44688a64'
}

type Props = {
  selectedIntegration: Integration | undefined
  initialIntegrationType: CampaignType
  onSaveOrUpdate: SaveOrUpdateCampaignsIntegrationFunction
  onClose: () => void
}

export const CampaignsIntegrationsForm = ({
  onClose, onSaveOrUpdate, selectedIntegration: selectedIntegrationObject, initialIntegrationType
}: Props) => {

  const [selectedIntegration, setSelectedIntegration] = useState<CampaignType>(selectedIntegrationObject?.type ?? initialIntegrationType)

  const handleChangeIntegration = (value: string | undefined) => {
    setSelectedIntegration(value as CampaignType)
  }

  const FormComponents: Record<CampaignType, JSX.Element> = {
    'facebook': <MetaForm selectedIntegration={selectedIntegrationObject} onClose={onClose} onSaveOrUpdate={onSaveOrUpdate} />,
    'google': <GoogleForm selectedIntegration={selectedIntegrationObject} onClose={onClose} onSaveOrUpdate={onSaveOrUpdate} />
  }

  return (
    <div>
      <div 
        onClick={onClose}
        className="modalBackground"
      />
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-md py-8 px-12 rounded-xl w-96 z-50 bg-modal flex flex-col gap-5">
        <a
          href={videoLinks[selectedIntegration]}
          className="absolute right-8"
          target='_blank'
          rel='noreferrer'
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#F15A22" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
          </svg>
        </a>
        <Select
          label='Selecione a integração'
          onChange={handleChangeIntegration}
          options={options}
          selected={selectedIntegration}
          variant="secondary"
        />
        {FormComponents[selectedIntegration]}
      </div>
    </div>
  )
}