import { InputHTMLAttributes } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import { Label } from "../Label";
import { cn } from "utils/styles";

type TextFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  id?: string
  label?: string;
  error?: string;
  helpText?: string;
  required?: boolean
  register?: UseFormRegisterReturn
  containerClassName?: string
  variant?: "primary" | "secondary"
}

export function DatePicker({
  label, placeholder, variant = 'primary', error, helpText,
  register, className, disabled, required, id, containerClassName, ...props
}: TextFieldProps) {

  return (
    <div className={cn(containerClassName, "flex flex-col w-full")}>
      { label && <Label className="px-5 mb-3">{label} {required ? "*" : ""}</Label> }
      <div className={cn('flex rounded-full', variant === 'primary' ? 'bg-primaryMultiply' : 'bg-white/30')}>
        <input
          id={id}
          disabled={disabled}
          className={cn(className, "py-2 px-5 outline-none border-transparent bg-transparent w-full text-base placeholder:text-white/35")}
          type="date"
          placeholder={placeholder}
          {...register}
          {...props}
        />
      </div>
      {(error || helpText) && (
        <p className={cn(error ? 'text-red-500' : variant === 'primary' ? 'text-black' : 'text-white', 'text-xs py-2 leading-4 px-5')}>
          {error ?? helpText}
        </p>
      )}
    </div>
  );
}