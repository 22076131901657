import { Button } from "components/Button"
import { Form, FormColumn, FormRow } from "components/Forms/Form"
import { Select } from "components/Forms/Select"
import { TextField } from "components/Forms/TextField"

import { useViewModel } from "./viewModel"
import { MaskedTextField } from "components/Forms/MaskedTextField"
import { Masks } from "utils/mask"
import { socialOrigins } from "models/lead"
import { PageHeader } from "components/Page/PageHeader"
import { PageFilter } from "components/Page/PageFilter"

export function CreateLeadForm() {

  const {
    handleSubmit, errors, register, loading, handleChangeSocialOrigin, user,
    columnId, handleChangeColumnId, phone, handleChangePhone, isLoadingProviderColumns,
    providerColumns, socialOrigin, handleChangeSeller, seller, sellerOptions,
    selectedProvider, isSelledColumn
  } = useViewModel()

  if (selectedProvider === 'admin') {
    return (
      <section>
        <div className="flex justify-between items-center">
          <PageHeader 
            title="Novo Lead"
            backPath="/leads"
          />
          <PageFilter />
        </div>
        <div className='w-full flex flex-col gap-6 items-center justify-center text-center h-[calc(75svh)]'>
          <p className='text-white text-xl text-center'>Selecione um provedor, para ver o formulário</p>
        </div>
      </section>
    )
  }

  return (
    <section>
      <div className="flex justify-between items-center">
        <PageHeader 
          title="Novo Lead"
          backPath="/leads"
        />
        <PageFilter />
      </div>
      <Form onSubmit={handleSubmit} className="mt-12 tablet:mt-24">
        <FormRow>
          <FormColumn>
            <TextField
              label="Nome"
              placeholder="Digite o nome do cliente"
              error={errors.name?.message}
              register={register("name", {
                required: { value: true, message: "O nome do laed é obrigatório" }
              })}
            />
            <TextField
              label="Data de entrada"
              placeholder="Digite a data de entrada"
              error={errors.date?.message}
              type="date"
              register={register("date", {
                required: { value: true, message: "A data de entrada é obrigatória" }
              })}
            />
            <MaskedTextField
              label="Telefone"
              placeholder="Digite o telefone"
              error={errors.phone?.message}
              mask={[Masks.OLD_PHONE, Masks.PHONE]}
              value={phone}
              onChange={handleChangePhone}
            />
            <Select
              onChange={handleChangeSocialOrigin}
              selected={socialOrigin}
              options={socialOrigins}
              placeholder="Selecione a origem do lead"
              label="Origem do lead"
              error={errors.socialOrigin?.message}
            />
            <Select
              label="Coluna do cliente"
              placeholder="Selecione a coluna do cliente"
              selected={columnId}
              options={providerColumns ?? []}
              onChange={handleChangeColumnId}
              isLoading={isLoadingProviderColumns}
              error={errors.columnId?.message}
            />
            {
              user && user?.role !== 'seller' ? (
                <Select
                  label="Vendedor(a)"
                  options={sellerOptions ?? []}
                  onChange={handleChangeSeller}
                  selected={seller}
                  placeholder="Selecione o vendedor(a)"
                  isLoading={sellerOptions === undefined}
                />
              ) : null
            }
            <TextField
              register={register("selledAt", {
                //Check if is a date
                validate: (value) => {
                  if (!value) {
                    if (isSelledColumn) {
                      return "Data da venda é obrigatória"
                    }
                    return true
                  }
                  const date = new Date(value)
                  return date.toString() !== "Invalid Date"
                }
              })}
              placeholder="Digite a data da venda"
              label={`Data da venda${isSelledColumn ? "" : " (opcional)"}`}
              error={errors.selledAt?.message}
              type="date"
            />
          </FormColumn>
        </FormRow>
        <Button
          type="submit" 
          size="default"
          loading={loading}
          className="w-48 mt-20"
        >
          Adicionar
        </Button>
      </Form>
    </section>
  )
}