import { configureStore } from "@reduxjs/toolkit";
import auth, { IAuthState } from "./slices/auth"
import filters, { IFiltersState } from "./slices/filters"
import boardFilters, { IBoardFiltersState } from "./slices/boardFilters"

export interface IState {
  auth: IAuthState
  filters: IFiltersState
  boardFilters: IBoardFiltersState
}

export default configureStore<IState>({
  reducer: {
    auth,
    filters,
    boardFilters
  }
})