import { AxiosError } from "axios"
import { Button } from "components/Button"
import { Dialog } from "components/Dialog"
import { TextField } from "components/Forms/TextField"
import { LeadCard } from "models/lead"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { updateLeadData } from "services/leadService"
import { Log } from "utils/log"

export type LeadForm = {
  selling: string
}

type Props = {
  lead: LeadCard
  onSave: (lead: LeadCard) => void
  onClose: () => void
}

export const LeadSellingForm = ({ lead, onSave, onClose }: Props) => {

  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm<LeadForm>({
    defaultValues: {
      selling: lead.selledAt ?? new Date().toISOString().split('T')[0],
    }
  })

  const onSubmit = async (data: LeadForm) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      await updateLeadData(lead.id, {
        name: lead.name,
        socialOrigin: lead.socialOrigin,
        seller: lead.seller?.id ?? undefined,
        selledAt: data.selling
      })
      onSave({
        ...lead,
        ...data,
        selledAt: data.selling
      })
    } catch (error) {
      const _error = error as AxiosError

      if (_error.isAxiosError) {
        const { status } = _error.response!

        if (status === 403) {
          toast.error("O lead já está atribuído a outro vendedor(a)")
          Log.error("Error on update lead", _error)
          return
        }
      }
      
      toast.error("Não foi possível editar o lead")
      Log.error("Error on update lead", error as Error)
    }

    setIsLoading(false)
  }

  return (
    <Dialog
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <TextField
          register={register("selling", {
            required: { value: true, message: "Campo obrigatório" },
            //Check if is a date
            validate: (value) => {
              const date = new Date(value)
              return date.toString() !== "Invalid Date"
            }
          })}
          placeholder="Digite o data da venda"
          label="Data da venda"
          error={errors.selling?.message}
          type="date"
          variant="secondary"
        />
        <div className="flex items-center justify-end gap-2 mt-4">
          <Button theme="cancel" type="button" onClick={onClose}>Cancelar</Button>
          <Button theme="highlight" className="px-5" loading={isLoading}>Editar</Button>
        </div>
      </form>
    </Dialog>
  )
}