import { useMemo } from 'react';
import { cn } from 'utils/styles';

type InfoCardTypes = "invested" | "leads" | "visits" | "leadCost"

export type InforCardData = {
  current: number
  past: number
}

export type InfoCardProps = {
  type: InfoCardTypes
  value?: InforCardData
}

export const InfoCard = ({ value, type }: InfoCardProps) => {

  const titles = {
    invested: "Total investido",
    leads: "Total de Leads",
    visits: "Total de Visitas",
    leadCost: "Custo do Lead"
  }

  const textType = useMemo(() => {
    return type === "invested" || type === "leadCost" ? { style: "currency", currency: "BRL" } : {}
  }, [type])
  const changePercent = useMemo(() => {
    if (!value) return 0
    if (value.past === 0) {
      return value.current
    }
    return (value.current - value.past) / value.past
  }, [value])

  if (!value) {
    return (
      <div></div>
    )
  }

  return (
    <div className="bg-primaryMultiply py-7 px-8 rounded-2xl flex flex-row desktop:flex-col items-center desktop:items-start justify-between">
      <div className='flex items-center gap-2'>
        <img src={`/icons/campaigns/${type}.svg`} height="60" alt='Campanha' />
        <p className="">{titles[type]}</p>
      </div>
      <div className="desktop:mt-6 font-input">
        <p className="text-2xl font-bold">
          {Intl.NumberFormat("pt-BR", textType as any).format(value.current)}
        </p>
        <div className="flex gap-1 text-xs">
          <img src={`/icons/campaigns/trending-${changePercent >= 0 ? "up" : "down"}.svg`} alt="" />
          <p className={cn(changePercent >= 0 ? 'text-active' : 'text-secondary')}>
            {Intl.NumberFormat("pt-BR", { style: "percent", minimumFractionDigits: 2 }).format(changePercent)}
            {" "}
            <span>do período anterior</span>
          </p>
        </div>
      </div>
    </div>
  )
}