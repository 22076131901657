type PublicRoute = {
  element: JSX.Element
}

export default function PublicRoute({ element }: PublicRoute) {
  return (
    <main className="bg-main bg-cover bg-center">
      {element}
    </main>
  )
}