import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { ChatIntegration } from "models/chatIntegration"
import { AxiosError } from "axios"
import { messageToError, ValidationError } from "utils/validation"
import { toast } from "react-toastify"
import { getAuthUrl } from "services/chatIntegrationService"

export type HuggyIntegrationForm = {
  clientId: string
}

export const useViewModel = (
  selectedIntegration: ChatIntegration | undefined,
  providerId: string
) => {
  const {
    register, formState: { errors }, setValue, handleSubmit, setError
  } = useForm<HuggyIntegrationForm>()

  const [loadingForm, setLoadingForm] = useState(false)
  const status = selectedIntegration
    ? selectedIntegration.huggy?.tags.length === 0
      ? 'pending' : 'old'
    : 'new'

  useEffect(() => {
    setValue("clientId", selectedIntegration?.huggy?.clientId ?? "")
  }, [selectedIntegration, setValue])

  async function handleSubmitFormFirstStep(data: HuggyIntegrationForm) {
    if (loadingForm) return
    setLoadingForm(true)

    try {
      toast.info("Gerando url de autorização...")
      const url = await getAuthUrl(data.clientId, providerId)
      window.location.href = url
    } catch (error) {
      toast.error(errorMessage(error as Error))
      setLoadingForm(false)
    }
  }

  const errorMessage = (error: Error) => {
    const _err = error as AxiosError
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as { errors: ValidationError[] | undefined }

      if (data.errors) {
        const fields = data.errors.map(error => {
          setError(error.key as keyof HuggyIntegrationForm, { message: messageToError(error.message) })
          return error.key
        })
        return `Os campos ${fields.join(", ")} são inválidos`
      }
      if (_err.response.status === 400) return "O domínio ou o token são inválidos"
    }
    return "Ocorreu um erro, por favor tente novamente"
  }

  return {
    selectedIntegration,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    status
  }
}