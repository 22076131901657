export const toCurrency = (value: number) => {
  return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
}

export const toPercent = (value: number) => {
  return Intl.NumberFormat('pt-BR', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
}

export const toDecimal = (value: number) => {
  return Intl.NumberFormat('pt-BR', { style: 'decimal', maximumFractionDigits: 2 }).format(value)
}
