import { externalApi } from "./api"

type DashboardResponse<T> = {
  data: DashboardData<T>
}

type DashboardData<T> = {
  resume: T
  channels: {
    facebook: T
    google: T
    organic: T
  }
  sellerResume: T
  sellerChannels: {
    facebook: T
    google: T
    organic: T
  }
}

export type LeadsData = {
  dailyInvested: number
  totalInvested: number
  totalLeads: number
  totalLeadCost: number
  dailyLeads: number
  dailyTotalLeadCost: number
}

export const getDashboardLeadsData = async (
  initDate: Date, endDate: Date, providerId: string
): Promise<DashboardData<LeadsData>> => {
  const query = new URLSearchParams({
    initDate: initDate.toISOString(),
    endDate: endDate.toISOString(),
    providerId: providerId
  })

  const { data } = await externalApi.get<DashboardResponse<LeadsData>>(
    `v1/dashboard/external/leads?${query.toString()}`
  )

  return data.data
}

type AreaDataItem = {
  leads: number
  leadsCost: number
  leadsDailyCost: number
  leadsPercentage: number
}

export type AreaData = {
  insideArea: AreaDataItem
  outsideArea: AreaDataItem
  pending: AreaDataItem
}

export const getDashboardInsideAreaData = async (
  initDate: Date, endDate: Date, providerId: string
): Promise<DashboardData<AreaData>> => {
  const query = new URLSearchParams({
    initDate: initDate.toISOString(),
    endDate: endDate.toISOString(),
    providerId
  })

  const { data } = await externalApi.get<DashboardResponse<AreaData>>(
    `v1/dashboard/external/inside-area?${query.toString()}`
  )

  return data.data
}

type SellsDataItem = {
  leads: number
  leadsCost: number
  leadsPercentage: number
}

export type SellsData = {
  negotiation: SellsDataItem
  lostLead: SellsDataItem
  stopResponding: SellsDataItem
  scheduled: SellsDataItem
  sold: SellsDataItem
  soldGrouped: SellsDataItem
  oldSoldGrouped: SellsDataItem
  gaveUp: SellsDataItem
  insideArea: SellsDataItem
  open: SellsDataItem
  lostOpportunity: SellsDataItem
  alreadyClient: SellsDataItem
}

export const getDashboardSellsData = async (
  initDate: Date, endDate: Date, providerId: string
): Promise<DashboardData<SellsData>> => {
  const query = new URLSearchParams({
    initDate: initDate.toISOString(),
    endDate: endDate.toISOString(),
    providerId
  })

  const { data } = await externalApi.get<DashboardResponse<SellsData>>(
    `v1/dashboard/external/sells?${query.toString()}`
  )

  return data.data
}