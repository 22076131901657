import { QueryClient } from "react-query";
import { toMs } from "utils/date";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: toMs({ minutes: 1 })
    }
  }
})

export { queryClient }