import { CampaignCounters } from "services/campaignService"

export const isDifferentCounters = (newCounter: CampaignCounters, previous: CampaignCounters) => {
  return newCounter.invested.current !== previous.invested.current ||
    newCounter.invested.past !== previous.invested.past ||
    newCounter.leads.current !== previous.leads.current ||
    newCounter.leads.past !== previous.leads.past ||
    newCounter.visits.current !== previous.visits.current ||
    newCounter.visits.past !== previous.visits.past
}

export const divideCost = (value: number, leads: number) => {
  if (value === 0 && leads === 0) return 0
  if (value === 0) return 0
  if (leads === 0) return value
  return value / leads
}