import { Select } from "components/Forms/Select"
import useAuth from "hooks/useAuth"
import { ProviderItem } from "models/provider"
import { useMemo } from "react"
import { useQuery } from "react-query"
import { getAllProviders } from "services/providerService"
import { queryClient } from "services/queryClient"

export const PROVIDER_SELECT_QUERY = 'all-providers'

export const ProviderFilter = () => {

  const { user, selectedProvider, handleSelectProvider } = useAuth()

  const { data: providers } = useQuery(PROVIDER_SELECT_QUERY, getAllProviders, { 
    enabled: user?.role === "admin",
    refetchInterval: false,
    staleTime: Infinity
  })

  const providerItems: ProviderItem[] = useMemo(() => {
    if (providers) {
      return [
        { id: "admin", name: "Administrador" },
        ...providers.map(provider => ({ id: provider.id, name: provider.name })),
      ]
    }
    return []
  }, [providers])

  const handleChangeSelectedProvider = (provider: string | undefined) => {
    handleSelectProvider(String(provider))

    queryClient.invalidateQueries()
  }

  return (
    <div className="flex flex-col gap-4">
      <p className="text-base">Selecione o provedor:</p>
      <Select
        onChange={handleChangeSelectedProvider}
        options={providerItems.map(provider => ({ label: provider.name, value: provider.id }))}
        selected={selectedProvider}
        variant="secondary"
      />
    </div>
  )
}