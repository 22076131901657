import { Button } from "components/Button"
import { TextField } from "components/Forms/TextField"
import { useViewModel } from "./viewModel"
import { ChatIntegration } from "models/chatIntegration"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { Label } from "components/Forms/Label"
import { Select } from "components/Forms/Select"

type Props = {
  selectedIntegration: ChatIntegration | undefined
  onClose: () => void
  onSave: SaveOrUpdateChatIntegrationFunction
}

export const UpChatForm = ({ selectedIntegration, onClose, onSave }: Props) => {
  
  const viewModel = useViewModel(selectedIntegration, onSave)

  if (viewModel.step === 0) {
    return (
      <form 
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
        className="flex flex-col gap-5"
      >
        <TextField
          label='Nome da integração'
          placeholder='Digite o nome da integração'
          error={viewModel.errors.name?.message}
          register={viewModel.register("name", {
            required: { value: true, message: "O nome é obrigatório" }
          })}
          variant="secondary"
        />
        <TextField
          label='Domínio UpChat'
          placeholder='Digite o seu domínio personalizado do UpChat'
          error={viewModel.errors.upChat?.host?.message}
          register={viewModel.register("upChat.host", {
            required: { value: true, message: "O domínio é obrigatório" }
          })}
          variant="secondary"
        />
        <TextField
          label='Token de acesso'
          placeholder='Digite o token de acesso'
          error={viewModel.errors.upChat?.token?.message}
          register={viewModel.register("upChat.token", {
            required: { value: true, message: "O token é obrigatório" }
          })}
          variant="secondary"
        />
        <div className="modalButtons">
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button loading={viewModel.loadingForm} theme="highlight">
            Salvar
          </Button>
        </div>
      </form>
    )
  }
  
  return (
    <form 
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)}
      className="flex flex-col gap-5"
    >
      <Select
        label="Canal de leads"
        options={viewModel.channels}
        variant="secondary"
        onChange={viewModel.handleSelectChannel}
        error={viewModel.errors.upChat?.departments?.message}
        selected={viewModel.selectedChannel}
      />
      <div>
        <Label className="px-5">Departamentos</Label>
        <p className="text-sm leading-4 my-3">Copia e cole os departamentos válidos do UpChat</p>
        <div className="flex flex-col max-h-[50vh] overflow-y-scroll gap-5">
          {
            viewModel.queueFields.map((_, index) => (
              <div className="flex gap-2 items-center" key={index}>
                <TextField
                  placeholder="Cole o nome do departamento"
                  register={viewModel.register(`upChat.queues.${index}.id`)}
                  error={viewModel.errors.upChat?.queues?.[index]?.id?.message}
                  id={`tags.${index}.id`}
                  variant="secondary"
                />
                {
                  viewModel.queueFields.length > 1
                  && <img
                    src="/icons/form/delete_secondary.svg"
                    alt="Remover departamento"
                    className="p-0.5 cursor-pointer h-6"
                    onClick={viewModel.handleRemoveQueue(index)}
                  />
                }
              </div>
            ))
          }
        </div>
        <Button
          variant="text"
          type="button"
          onClick={viewModel.handleAppenedQueue}
        >
          + Adicionar departamento
        </Button>
      </div>
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button loading={viewModel.loadingForm} theme="highlight">
          {viewModel.selectedIntegration ? 'Editar' : 'Adicionar'}
        </Button>
      </div>
    </form>
  )
}