type Roles = "admin" | "provider" | "seller"

export type Route = {
  path: string
  name: string
  icon: string
}

export const getRoutes = (
  role: Roles | undefined, active: boolean | undefined
): Route[] => {
  if (!role) {
    return []
  }

  if (!active) {
    return [
      {
        path: "/",
        name: "Visão Geral",
        icon: "dashboard",
      }
    ]
  }

  if (role === "admin") {
    return [
      {
        path: "/",
        name: "Visão Geral",
        icon: "dashboard",
      },
      {
        path: "/clientes",
        name: "Clientes",
        icon: "clients",
      },
      {
        path: "/vendedores",
        name: "Vendedores",
        icon: "sellers",
      },
      {
        path: "/campanhas",
        name: "Campanhas",
        icon: "campaigns",
      },
      {
        path: "/relatorios",
        name: "Relatórios",
        icon: "dashboard",
      },
      {
        path: "/leads",
        name: "Leads",
        icon: "leads",
      }
    ]
  }

  if (role === "seller") {
    return [
      {
        path: "/",
        name: "Leads",
        icon: "leads",
      }
    ]
  }

  return [
    {
      path: "/",
      name: "Visão Geral",
      icon: "dashboard",
    },
    {
      path: "/vendedores",
      name: "Vendedores",
      icon: "sellers",
    },
    {
      path: "/campanhas",
      name: "Campanhas",
      icon: "campaigns",
    },
    {
      path: "/relatorios",
      name: "Relatórios",
      icon: "dashboard",
    },
    {
      path: "/leads",
      name: "Leads",
      icon: "leads",
    }
  ]
}