import { cn } from "utils/styles"
import { InvestedCard } from "./InvestedCard"
import { LeadsCard } from "./LeadsCard"
import { LeadsCost } from "./LeadsCost"
import { AreaData, LeadsData } from "services/dashboardService"

type Props = {
  variant?: 'primary' | 'secondary'
  leads: LeadsData
  area: AreaData
}

export const LeadsResume = ({ variant = 'primary', leads, area }: Props) => {

  return (
    <div className={cn(
      "grid grid-cols-1 desktop:grid-cols-3 gap-3 rounded-2xl",
      variant === 'primary' ? 'p-4 bg-primary/40' : 'flex-1'
    )}>
      <InvestedCard
        variant={variant}
        dailyInvested={leads.dailyInvested}
        invested={leads.totalInvested}
        leads={leads.totalLeads}
        dailyLeads={leads.dailyLeads}
      />
      <LeadsCard
        variant={variant}
        insideArea={area.insideArea.leads}
        insideAreaPercent={area.insideArea.leadsPercentage}
        outsideArea={area.outsideArea.leads}
        outisdeAreaPercent={area.outsideArea.leadsPercentage}
        pending={area.pending.leads}
        pendingPercent={area.pending.leadsPercentage}
      />
      <LeadsCost
        variant={variant}
        costInsideArea={area.insideArea.leadsCost}
        dailyCostInsideArea={area.insideArea.leadsDailyCost}
        totalLeadCost={leads.totalLeadCost}
        dailyLeadCost={leads.dailyTotalLeadCost}
      />
    </div>
  )
}