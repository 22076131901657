import { toCurrency } from "utils/format"
import { cn } from "utils/styles"

type Props = {
  variant?: 'primary' | 'secondary'
  totalLeadCost: number
  dailyLeadCost: number
  costInsideArea: number
  dailyCostInsideArea: number
}

export const LeadsCost = ({ variant = 'primary', costInsideArea, totalLeadCost, dailyLeadCost, dailyCostInsideArea }: Props) => {

  return (
    <div className={cn(
      "rounded-2xl",
      variant === 'primary' ? 'bg-primaryMultiply/70 pt-7 pb-5 px-7' : 'border-white/30 border py-2 px-4 flex flex-col justify-center'
    )}>
      <div className={cn("flex items-center", variant === 'primary' ? 'gap-2.5' : 'gap-1')}>
        <img className={cn(variant === 'primary' ? '' : 'h-3.5')} src="/icons/campaigns/cost.svg" alt="" />
        <p className={cn(variant === 'primary' ? '' : 'text-xs')}>Custo do Lead</p>
      </div>
      <div className={cn("flex justify-between font-input", variant === 'primary' ? 'mt-2 desktop:mt-5' : 'mt-1')}>
        <div>
          <p className="text-secondary text-xs">Custo geral</p>
          <p className={cn("font-bold", variant === 'primary' ? 'text-2xl' : 'text-xl')}>{toCurrency(totalLeadCost)}</p>
          <p className="text-secondary text-xs">{toCurrency(dailyLeadCost)}/dia</p>
        </div>
        <div>
          <p className="text-secondary text-xs">Custo dentro da área</p>
          <p className={cn("font-bold", variant === 'primary' ? 'text-2xl' : 'text-xl')}>{toCurrency(costInsideArea)}</p>
          <p className="text-secondary text-xs">{toCurrency(dailyCostInsideArea)}/dia</p>
        </div>
      </div>
    </div>
  )
}