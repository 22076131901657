import { Provider } from "models/provider"
import { APIRequest, PaginatedData, PaginationV2 } from "utils/pagination"
import { api } from "./api"
import { BoardColumnQuery } from "models/board"

type ProviderResponse = {
  id: string,
  userId: string
  name: string,
  email: string,
  client: string,
  whatsapp: string,
  responsible: string,
  status: number,
  date: string,
  columns: BoardColumnQuery[]
  averageTicket: number | null
}

export const getProvider = async (id: string): Promise<Provider> => {
  const { data: { data: provider } } = await api.get<APIRequest<ProviderResponse>>(`/v1/providers/${id}`)

  return {
    ...provider,
    leadsStatus: provider.columns.map(column => ({ id: column.id, name: column.name, tag: column.tag }))
  }
}

export const getProviders = async (page: number, perPage: number): Promise<PaginatedData<Provider>> => {
  const { data } = await api.get<PaginatedData<ProviderResponse>>(`/v1/providers?page=${page}&perPage=${perPage}`)
  return {
    ...data,
    data: data.data.map(provider => ({
      ...provider,
      leadsStatus: provider.columns.map(column => ({ id: column.id, name: column.name }))
    }))
  }
}

export const getAllProviders = async (): Promise<Provider[]> => {
  const { data: { data } } = await api.get<APIRequest<Provider[]>>(`/v1/providers/all`)
  return data
}

export const changeProviderStatus = async (providerId: string, status: number): Promise<void> => {
  await api.patch(`/v1/providers/${providerId}/status`, { status })
}

type NewProvider = {
  email: string
  name: string
  client: string
  whatsapp: string
  responsible: string
  status: number
  date: string
}

export const addProvider = async (provider: NewProvider): Promise<string> => {
  const { data } = await api.post<APIRequest<Provider>>("/v1/providers", provider)
  return data.data.id
}

export const updateProvider = async (provider: Provider): Promise<void> => {
  await api.put(`/v1/providers/${provider.id}`, provider)
}
