import { SheetIntegration } from "models/integration"

import { Pagination } from "utils/pagination"

import { api } from "./api"

type CreateOrUpdateSheetIntegration = Omit<SheetIntegration, "_id"> & {
  provider: string
}

export const getSheetIntegrations = async (
  clientId: string
): Promise<Pagination<SheetIntegration>> => {
  const { data } = await api.get<Pagination<SheetIntegration>>(`v1/sheet-integrations?providerId=${clientId}`)
  return data
}

export const createSheetIntegration = async (
  integration: CreateOrUpdateSheetIntegration
): Promise<SheetIntegration> => {
  const { data } = await api.post<SheetIntegration>("v1/sheet-integrations", integration)
  return data
}

export const updateSheetIntegration = async (
  id: string, integration: CreateOrUpdateSheetIntegration
): Promise<SheetIntegration> => {
  const { data } = await api.put<SheetIntegration>(`v1/sheet-integrations/${id}`, integration)
  return data
}

type SheetsHeaderResponse = {
  data: string[]
}

export const getSheetsHeaders = async (sheetId: string): Promise<SheetsHeaderResponse> => {
  const { data } = await api.post<SheetsHeaderResponse>(
    `v1/sheet-integrations/headers`,
    { sheetId }
  )
  return data
}
