type IntegrationRowProps = {
  name: string
  path: string
  children?: React.ReactNode | React.ReactNode[]
}

export const IntegrationRow = ({ children, path, ...integration }: IntegrationRowProps) => {
  return (
    <div className="bg-primaryMultiply rounded-full flex py-3 pl-8 pr-4 items-center">
      <div className="flex items-center flex-1 gap-2.5">
        <img src={path} className="max-h-6 max-w-6" />
        <p className="flex-1 text-xl line-clamp-1">{integration.name}</p>
      </div>
      <div className="flex gap-5">
        {children}
      </div>
    </div>
  )
}