import { useState } from "react"

export function AuthCheckbox() {

  const [isChecked, setIsChecked] = useState(true)

  function handleToggleCheck() {
    setIsChecked(!isChecked)
  }

  return (
    <div className="flex gap-1.5 text-white text-xs cursor-pointer items-center" onClick={handleToggleCheck}>
      <img src={`/icons/form/checkbox_${isChecked ? "checked" : "unchecked"}.svg`} alt="Manter logado" />
      <span className="">Mantenha-me logado</span>
    </div>
  )
}