import { useQuery } from "react-query"

import {
  getDashboardLeadsData, getDashboardInsideAreaData, getDashboardSellsData
} from "services/externalDashboardService"
import { useFilters } from "hooks/useFilters"
import { useState } from "react"
import { useParams } from "react-router-dom"

const KEY = 'external-dashboard'

type TAB = 'leads' | 'sales'

export const useViewmodel = () => {
  const { selectedDate } = useFilters()
  const { providerId } = useParams<{ providerId: string }>()

  const [tab, setTab] = useState<TAB>('leads')
  
  const range = `${selectedDate?.initDate.toDateString()}-${selectedDate?.endDate.toDateString()}`

  const leadsData = useQuery(
    [KEY, "leads", range],
    () => getDashboardLeadsData(selectedDate!.initDate, selectedDate!.endDate, providerId!),
  )
  leadsData.isLoading = leadsData.isLoading || (leadsData.isFetching && !leadsData.data)

  const insideAreaData = useQuery(
    [KEY, "inside-area", range],
    () => getDashboardInsideAreaData(selectedDate!.initDate, selectedDate!.endDate, providerId!),
  )
  insideAreaData.isLoading = insideAreaData.isLoading || (insideAreaData.isFetching && !insideAreaData.data)

  const sellsData = useQuery(
    [KEY, "sells", range],
    () => getDashboardSellsData(selectedDate!.initDate, selectedDate!.endDate, providerId!),
  )
  sellsData.isLoading = sellsData.isLoading || (sellsData.isFetching && !sellsData.data)

  const refetch = () => {
    leadsData.refetch()
    insideAreaData.refetch()
  }

  return {
    sellsData: sellsData.data,
    leadsData: leadsData.data,
    insideAreaData: insideAreaData.data,
    isLoading: leadsData.isLoading || insideAreaData.isLoading || sellsData.isLoading,
    isError: leadsData.isError || insideAreaData.isError || sellsData.isError,
    refetch,
    tab,
    setTab,
    hasData: leadsData.data && insideAreaData.data && sellsData.data,
  }
}
