import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

type Props = {
  id: string
  children: React.ReactNode
}

export const SortableItem = ({ id, children }: Props) => {
  const {
    attributes, listeners, setNodeRef, transform, transition, isDragging
  } = useSortable({
    id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <li
      ref={setNodeRef}
      className={`${isDragging ? '!opacity-20' : '!opacity-100'} touch-none`}
      style={{
        ...style
      }}
      {...attributes}
      {...listeners}
    >
      {children}
    </li>
  )
}