import { useMemo } from "react"

export const useQueryParams = <T>(): Partial<T> => {
  return useMemo(() => {
    if (window) {
      const indexOfFirstQuery = window.location.href.split("").indexOf("?")
      if (indexOfFirstQuery !== -1) {
        const queries = window.location.href.substring(indexOfFirstQuery + 1)
        return queries.split("&").reduce((acc, curr) => {
          const [key, value] = curr.split("=")
          acc[key] = decodeURIComponent(value)
          return acc
        }, {} as Record<string, string>) as unknown as Partial<T>
      }
      return {} as Partial<T>
    }
    return {} as Partial<T>
  }, [window])
}