import useAuth from "hooks/useAuth";
import { useFilters } from "hooks/useFilters";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateSelectedSeller } from "store/slices/auth";
import { updateSelectedDate, updateSelectedPeriod } from "store/slices/filters";
import { dateRangeFromPeriod } from "utils/date";
import { DatePeriodItem, DatePeriods } from "utils/filters";

export const useViewModel = () => {

  const { user, selectedProvider, sellers, selectedSeller } = useAuth()
  const { selectedPeriod } = useFilters()
  const dispatch = useDispatch()

  function handleChangePeriods(value: string | undefined) {
    dispatch(updateSelectedPeriod(value as DatePeriods))
    handleCalculateDateFromPeriod(value as DatePeriods)
  }

  const sellerOptions = useMemo(() => {
    const options = sellers?.map(seller => ({ label: seller.name, value: seller.id }))

    if (options) {
      options.push({ label: "Não atribuídos", value: 'unassigned' })
      options.unshift({ label: "Todos", value: 'all' })
    }
    return options
  }, [sellers])

  const datePeriodOptions: DatePeriodItem[] = useMemo(() => {
    const periods: DatePeriodItem[] = [
      { label: "Hoje", value: "today" },
      { label: "Ontem", value: "yesterday" },
      { label: "Últimos 7 dias", value: "last7days" },
      { label: "Últimos 14 dias", value: "last14days" },
      { label: "Últimos 30 dias", value: "last30days" },
      { label: "Este mês", value: "thisMonth" },
      { label: "Mês passado", value: "lastMonth" },
      { label: "Últimos 6 meses", value: "last6Months" },
      { label: "Este ano", value: "thisYear" },
      { label: "Ano passado", value: "lastYear" }
    ]

    if (selectedPeriod === 'custom') {
      periods.push({ label: "Personalizado", value: "custom" })
    }

    return periods
  }, [selectedPeriod])

  const handleCalculateDateFromPeriod = (period: DatePeriods) => {
    const newDateRange = dateRangeFromPeriod(period)

    if (newDateRange) {
      dispatch(updateSelectedDate(JSON.stringify(newDateRange)))
    }
  }

  const handleChangeSeller = (value: string | undefined) => {
    if (value) {
      dispatch(updateSelectedSeller(value))
    }
  }

  return {
    selectedPeriod,
    handleChangePeriods,
    datePeriodOptions,
    sellerOptions,
    selectedProvider,
    selectedSeller,
    handleChangeSeller,
    isSeller: user?.role === 'seller'
  }
}