import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { FormStates } from "components/Forms/FormStates"

import { getProvider, updateProvider } from "services/providerService"

import { ProviderForm } from "../form"
import { FormFields } from "../form/viewModel"

import { queryClient } from "services/queryClient"
import { PROVIDER_CLIENTS_QUERY_KEY } from "../viewModel"
import { PageHeader } from "components/Page/PageHeader"
import { PROVIDER_SELECT_QUERY } from "components/Page/PageFilter/ProviderFilter"
import { useQuery } from "react-query"

type QueryParams = {
  id: string
}

export function EditClient() {
  
  const { id } = useParams<QueryParams>()
  const navigate = useNavigate()

  const { data, isLoading, isError, refetch } = useQuery(
    [PROVIDER_CLIENTS_QUERY_KEY, 'form', id],
    () => getProvider(id!),
    { enabled: !!id }
  )

  async function handleSubmit(fields: FormFields) {
    toast("Atualizando dados do cliente...", { type: "info" })
    await updateProvider({ id: id!, leadsStatus: data!.leadsStatus, ...fields})
    queryClient.invalidateQueries(PROVIDER_CLIENTS_QUERY_KEY)
    queryClient.invalidateQueries(PROVIDER_SELECT_QUERY)
    toast("Cliente cadastrado com sucesso!", { type: "success" })
    navigate(`/clientes/${id!}`)
  }

  return (
    <section>
      <PageHeader
        title="Editar Cliente"
        backPath="/clientes"
        action={{ label: "Visualizar", onClick: () => navigate(`/clientes/${id!}`) }}
      />
      <FormStates 
        isLoading={isLoading}
        hasError={isError}
        onRetry={refetch}
      />
      { data && (
        <ProviderForm 
          enabled
          provider={data}
          onSubmit={handleSubmit}
          action="Editar"
        />
      )}
    </section>
  )
}