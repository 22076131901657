//Based on https://www.w3schools.com/js/js_cookies.asp
const Cookies = {
  get(key: string): string | undefined {
    const name = key + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  },
  set(key: string, value: string, expirationInDays?: number) {
    const date = new Date()
    if (expirationInDays) {
      date.setTime(date.getTime() + (expirationInDays*24*60*60*1000))
    } else {
      date.setTime(date.getTime() + (365*24*60*60*1000))
    }
    const expires = "expires="+ date.toUTCString();
    document.cookie = key + "=" + value + ";" + expires + ";path=/";
  },
  remove(key: string) {
    this.set(key, "", 0)
  }
}

export default Cookies