import { toPercent } from "utils/format"
import { cn } from "utils/styles"

type Props = {
  variant?: 'primary' | 'secondary'
  negotiation: number
  open: number
  scheduled: number
  installed: number
  alreadyClient: number
}

export const Resume1Card = ({ variant = 'primary', alreadyClient, installed, negotiation, open, scheduled }: Props) => {

  return (
    <div className={cn(
      "rounded-2xl font-input relative laptop:flex-1 desktop:flex-none desktop-xl:flex-1 flex flex-col justify-center",
      variant === 'primary' ? 'bg-primaryMultiply/70 py-5 px-6 text-sm' : 'border border-white/30 py-4 px-4 text-xs'
    )}>
      <div className="h-2 w-2 bg-active rounded-full absolute top-2.5 left-2.5" />
      <div className={cn(
        "flex items-center justify-between",
        variant === 'primary' ? 'gap-14' : 'gap-4 desktop-lg:gap-8'
      )}>
        <p className="text-secondary">Já são clientes</p>
        <p>{alreadyClient}</p>
      </div>
      <div className={cn(
        "flex items-center justify-between",
        variant === 'primary' ? 'gap-14' : 'gap-4 desktop-lg:gap-8'
      )}>
        <p className="text-secondary">Em negociação</p>
        <p>{negotiation}</p>
      </div>
      <div className={cn(
        "flex items-center justify-between",
        variant === 'primary' ? 'gap-14' : 'gap-4 desktop-lg:gap-8'
      )}>
        <p className="text-secondary">Em aberto</p>
        <p>{toPercent(open)}</p>
      </div>
      <div className={cn(
        "flex items-center justify-between",
        variant === 'primary' ? 'gap-14' : 'gap-4 desktop-lg:gap-8'
      )}>
        <p className="text-secondary">Agendados</p>
        <p>{scheduled}</p>
      </div>
      <div className={cn(
        "flex items-center justify-between",
        variant === 'primary' ? 'gap-14' : 'gap-4 desktop-lg:gap-8'
      )}>
        <p className="text-secondary">Instalados</p>
        <p>{installed}</p>
      </div>
    </div>
  )
}