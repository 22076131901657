import { useViewmodel } from "./viewModel";
import { DotLoading } from "components/DotLoading";
import { Button } from "components/Button";
import { cn } from "utils/styles";
import { LeadsResume } from "./components/LeadsResume";
import { SalesResume } from "./components/SalesResume";
import { PlatformRow } from "./components/PlatformRow";

export const Dashboard = () => {

  const {
    hasProvider, insideAreaData, leadsData, sellsData,
    isLoading, isError, refetch, hasData, tab, setTab,
    isAllSellers, financialResumeData
  } = useViewmodel()

  if (!hasProvider) {
    return (
      <div className='w-full flex flex-col gap-6 items-center justify-center h-[calc(75svh)]'>
        <p className='text-white text-xl text-center'>Selecione um provedor, para ver o seu relatório</p>
      </div>
    )
  }

  if (isLoading) return (
    <div className='w-full flex flex-col gap-6 items-center justify-center h-[calc(75svh)]'>
      <p className='text-white text-xl'>Carregando relatório</p>
      <DotLoading />
    </div>
  )

  if (isError) return (
    <div className='w-full flex flex-col gap-6 items-center justify-center text-center h-[calc(75svh)]'>
        <p className='text-accent-500 text-xl'>Ops, algo deu errado</p>
        <p>Não foi possível carregar o relatório,<br></br>por favor tente novamente</p>
        <Button onClick={refetch}>Tentar novamente</Button>
      </div>
  )
  if (!hasData) {
    return (
      <div className='w-full flex flex-col gap-6 items-center justify-center h-[calc(75svh)]'>
        <p className='text-white text-xl'>O provedor não tem dados cadastrados</p>
      </div>
    )
  }

  return (
    <div>
      <div className="flex justify-around text-center text-xl my-3">
        <div />
        <button
          onClick={() => setTab('leads')}
          className={cn("w-32 py-1.5 rounded-full", tab === 'leads' ? 'bg-primary/40' : 'bg-primaryMultiply')}
        >
          Leads
        </button>
        <button
          onClick={() => setTab('sales')}
          className={cn("w-32 py-1.5 rounded-full", tab === 'sales' ? 'bg-primary/40' : 'bg-primaryMultiply ')}
        >
          Vendas
        </button>
        <div />
      </div>
      {
        tab === 'leads' ? (
          <LeadsResume
            leads={isAllSellers ? leadsData!.resume : leadsData!.sellerResume}
            area={isAllSellers ? insideAreaData!.resume : insideAreaData!.sellerResume}
          />
        ) : (
          <SalesResume
            sellsData={isAllSellers ? sellsData!.resume : sellsData!.sellerResume}
            financialResumeData={isAllSellers ? financialResumeData!.resume : financialResumeData!.sellerResume}
          />
        )
      }
      {
        tab === 'leads' ? (
          <div className="mt-8 flex flex-col gap-4">
            <PlatformRow plataform="meta_ads" className="gap-8">
              <LeadsResume 
                variant="secondary"
                leads={isAllSellers ? leadsData!.channels.facebook : leadsData!.sellerChannels.facebook}
                area={isAllSellers ? insideAreaData!.channels.facebook : insideAreaData!.sellerChannels.facebook}
              />
            </PlatformRow>
            <PlatformRow plataform="google_ads" className="gap-8">
              <LeadsResume 
                variant="secondary"
                leads={isAllSellers ? leadsData!.channels.google : leadsData!.sellerChannels.google}
                area={isAllSellers ? insideAreaData!.channels.google : insideAreaData!.sellerChannels.google}
              />
            </PlatformRow>
            <PlatformRow plataform="organic" className="gap-8">
              <LeadsResume 
                variant="secondary"
                leads={isAllSellers ? leadsData!.channels.organic : leadsData!.sellerChannels.organic}
                area={isAllSellers ? insideAreaData!.channels.organic : insideAreaData!.sellerChannels.organic}
              />
            </PlatformRow>
          </div>
        ) : (
          <div className="mt-8 flex flex-col gap-4">
            <PlatformRow plataform="meta_ads" className="gap-8 desktop:gap-0">
              <SalesResume 
                variant="secondary"
                sellsData={isAllSellers ? sellsData!.channels.facebook : sellsData!.sellerChannels.facebook}
                financialResumeData={isAllSellers ? financialResumeData!.channels.facebook : financialResumeData!.channels.facebook}
              />
            </PlatformRow>
            <PlatformRow plataform="google_ads" className="gap-8 desktop:gap-0">
              <SalesResume 
                variant="secondary"
                sellsData={isAllSellers ? sellsData!.channels.google : sellsData!.sellerChannels.google}
                financialResumeData={isAllSellers ? financialResumeData!.channels.google : financialResumeData!.channels.google}
              />
            </PlatformRow>
            <PlatformRow plataform="organic" className="gap-8 desktop:gap-0">
              <SalesResume 
                variant="secondary"
                sellsData={isAllSellers ? sellsData!.channels.organic : sellsData!.sellerChannels.organic}
                financialResumeData={isAllSellers ? financialResumeData!.channels.organic : financialResumeData!.channels.organic}
              />
            </PlatformRow>
          </div>
        )
      }
    </div>
  )
}