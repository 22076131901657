import { cn } from "utils/styles";
import { DateFilterCalendarDay } from "../DateFilterCalendarDay";

import { useViewModel } from "./viewModel";

type SelectedDate = {
  initDate: Date
  endDate?: Date
}

type CalendarType = "left" | "right"

type DateFilterCalendarProps = {
  calendar: CalendarType
  targetDate: Date
  onChangeFrame: (changeToLeft: boolean) => void
  selectedDate?: SelectedDate
  handleSelectManualDate: (date: Date) => void
  handleSelectDateFrame: (date: Date) => void
}

export function DateFilterCalendar({ 
  calendar, targetDate, onChangeFrame, selectedDate, handleSelectManualDate, handleSelectDateFrame
}: DateFilterCalendarProps) {

  const { 
    dateAreInLimit, months, dateItems
  } = useViewModel(targetDate, handleSelectDateFrame)

  return (
    <div className={cn(
      "rounded-2xl bg-white/20",
      calendar === 'left' ? '' : 'hidden laptop:block'
    )}>
      <div className="flex items-center justify-between py-5 px-6">
        { (!dateAreInLimit.minLimit && calendar === 'left') ? (
          <button className="w-10 flex justify-start px-4 py-1" onClick={() => onChangeFrame(true)}>
            <img
              src="/icons/form/left_arrow.svg"
              alt="Voltar"
            /> 
        </button>
        ) : <button className="w-10 h-[26px]" /> }
        <p className="text-sm">
          {months[targetDate.getMonth()].label}
          {" "}
          {targetDate.getFullYear()}
        </p>
          {
            !dateAreInLimit.maxLimit ? (
              <button className="w-10 flex justify-end px-4 py-1" onClick={() => onChangeFrame(false)}>
                <img 
                  src="/icons/form/right_arrow.svg"
                  alt="Avançar"
                  className={calendar === "right" ? "" : "laptop:hidden"}
                />
              </button>
            ) : <button className="w-10 h-[26px]" />
          }
      </div>
      <div className="px-5 font-input">
        <div className="flex justify-between text-sm gap-4 pb-2">
          <p className="w-6">seg</p>
          <p className="w-6">ter</p>
          <p className="w-6">qua</p>
          <p className="w-6">qui</p>
          <p className="w-6">sex</p>
          <p className="w-6">sáb</p>
          <p className="w-6">dom</p>
        </div>
        <div className="flex flex-col gap-0.5 h-44 pb-2 justify-center">
          {
            dateItems.map((weeks, index) => (
              <div className="flex gap-4" key={index}>
                {
                  weeks.map((day, index) => (
                    <DateFilterCalendarDay 
                      key={`${index}-${day.date.getMilliseconds()}`}
                      item={day}
                      selectedDate={selectedDate}
                      onClick={handleSelectManualDate}
                    />
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}