import { AxiosError } from "axios"
import { Button } from "components/Button"
import { Dialog } from "components/Dialog"
import { CopyText } from "components/Forms/CopyText"
import { Select } from "components/Forms/Select"
import { TextField } from "components/Forms/TextField"
import useAuth from "hooks/useAuth"
import { BoardColumn } from "models/board"
import { ILeadSocialOrigin, LeadCard, socialOrigins, StoreLeadCard } from "models/lead"
import { Seller } from "models/seller"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { updateLeadData } from "services/leadService"
import { Log } from "utils/log"

type LeadForm = {
  name: string
  socialOrigin: ILeadSocialOrigin
  seller?: string
  selledAt?: string
  column: string
}

type Props = {
  lead: StoreLeadCard
  onSave: (lead: LeadCard, columnId: string) => Promise<void>
  onClose: () => void
  sellers: Seller[]
  columns: BoardColumn<LeadCard>[]
}

export const LeadDialogForm = ({ lead, columns, onSave, onClose, sellers }: Props) => {

  const { user } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const { register, setValue, watch, handleSubmit, formState: { errors } } = useForm<LeadForm>({
    defaultValues: {
      name: lead.name,
      socialOrigin: lead.socialOrigin,
      seller: lead.seller?.id ?? undefined,
      selledAt: lead.selledAt ? lead.selledAt.split('T')[0] : "",
    }
  })

  const socialOrigin = watch("socialOrigin")
  const seller = watch("seller")
  const selectedColumn = watch("column")

  const isSelledColumn = useMemo(() => {
    const colum = columns.find(c => c.id === selectedColumn)
    return colum?.tag?.value === "sold" || colum?.tag?.value === "scheduled"
  }, [selectedColumn])
  
  const phone = useMemo(() => {
    if (lead.contact && lead.contact.startsWith("55")) {
      return lead.contact.slice(2)
    }
    return lead.contact ?? ""
  }, [lead.contact])

  useEffect(() => {
    register("socialOrigin", { required: true })
  }, [register])

  const columnsItems = useMemo(() => {
    return columns.map(c => ({ label: c.title, value: c.id }))
  }, [columns.length])

  useEffect(() => {
    setValue('column', lead.status)
  }, [lead.status])

  const onSubmit = async ({ column, ...data }: LeadForm) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const selledAt = data.selledAt === ""
        ? undefined
        : data.selledAt

      const { seller } = await updateLeadData(lead.id, {
        name: data.name,
        socialOrigin: data.socialOrigin,
        seller: data.seller,
        selledAt: selledAt,
      })

      const _seller = sellers.find(s => s.id === seller) ?? null

      await onSave({
        ...lead,
        ...data,
        status: column,
        seller: _seller,
        selledAt: selledAt,
        date: new Date(lead.date)
      }, lead.status)
    } catch (error) {
      const _error = error as AxiosError

      if (_error.isAxiosError) {
        const { status } = _error.response!

        if (status === 403) {
          toast.error("O lead já está atribuído a outro vendedor(a)")
          Log.error("Error on update lead", _error)
          return
        }
      }

      toast.error("Não foi possível editar o lead")
      Log.error("Error on update lead", error as Error)
    }

    setIsLoading(false)
  }

  return (
    <Dialog
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        <TextField
          register={register("name", { required: true })}
          placeholder="Digite o nome do lead"
          label="Nome do lead"
          error={errors.name?.message}
          variant="secondary"
        />
        <CopyText
          text={phone}
          label="Telefone"
          variant="secondary"
        />
        <Select
          label="Coluna"
          placeholder="Selecione uma coluna"
          variant="secondary"
          selected={selectedColumn}
          options={columnsItems}
          onChange={column => setValue('column', column ?? lead.status)}
        />
        <Select
          onChange={(value) => setValue("socialOrigin", String(value) as ILeadSocialOrigin)}
          selected={socialOrigin}
          options={socialOrigins}
          label="Origem do lead"
          error={errors.socialOrigin?.message}
          variant="secondary"
          disabled={lead.campaign !== undefined}
        />
        {lead.campaign  && <CopyText
          label="Campanha"
          text={lead.campaign?.url ?? "Não disponível"}
          variant="secondary"
        />}
        <TextField
          register={register("selledAt", {
            //Check if is a date
            validate: (value) => {
              if (!value) {
                if (isSelledColumn) {
                  return "Data da venda é obrigatória"
                }
                return true
              }
              const date = new Date(value)
              return date.toString() !== "Invalid Date"
            }
          })}
          placeholder="Digite o data da venda"
          label={`Data da venda${isSelledColumn ? "" : " (opcional)"}`}
          error={errors.selledAt?.message}
          type="date"
          variant="secondary"
        />
        {user?.role !== "seller" ? (
          <Select
            onChange={(value) => setValue("seller", String(value) as ILeadSocialOrigin)}
            selected={seller}
            options={sellers.map(seller => ({ label: seller.name, value: seller.id }))}
            label="Vendedor do lead (opcional)"
            error={errors.seller?.message}
            placeholder="Selecione um vendedor"
            variant="secondary"
          />
        ) : null}
        <div className="flex items-center justify-end gap-2 mt-4">
          <Button theme="cancel" type="button" onClick={onClose}>Cancelar</Button>
          <Button theme="highlight" className="px-5" loading={isLoading}>Editar</Button>
        </div>
      </form>
    </Dialog>
  )
}