import Cookies from "cookies";

const TOKEN_COOKIE_KEY = "DR-Token"

export const TokenCookie = {
  get: () => Cookies.get(TOKEN_COOKIE_KEY),
  set: (token: string) => Cookies.set(TOKEN_COOKIE_KEY, token),
  remove: () => Cookies.remove(TOKEN_COOKIE_KEY)
}

const REFRESH_TOKEN_COOKIE_KEY = "DR-Refresh-Token"

export const RefreshTokenCookie = {
  get: () => Cookies.get(REFRESH_TOKEN_COOKIE_KEY),
  set: (refreshToken: string) => Cookies.set(REFRESH_TOKEN_COOKIE_KEY, refreshToken),
  remove: () => Cookies.remove(REFRESH_TOKEN_COOKIE_KEY)
}
