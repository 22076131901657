export type BoardColumn<T> = {
  id: string
  title: string
  items: T[]
  hasMore: boolean
  state: 'loading' | 'success' | 'error'
  nbItems: number
  tag?: BoardTag
}

export type BoardColumnQuery = {
  id: string
  name: string
  tag: BoardTag
}

export type BoardTag = {
  value: BoardTagValue
  label: string
}

type BoardTagValue = 'pending' | 'waiting_cep' | 'out_area' | 'already_client' | 'finance' | 'negotiation' | 'stopped_responding' | 'lost_lead' | 'scheduled' | 'sold' | 'gave_up'

export const tagList: BoardTag[] = [
  { value: 'pending', label: 'Pendente' },
  { value: 'waiting_cep', label: 'Aguardando CEP' },
  { value: 'out_area', label: 'Fora da área' },
  { value: 'already_client', label: 'Já é cliente' },
  { value: 'finance', label: 'Bloqueio financeiro' },
  { value: 'negotiation', label: 'Em negociação' },
  { value: 'stopped_responding', label: 'Parou de responder' },
  { value: 'lost_lead', label: 'Lead Perdido' },
  { value: 'scheduled', label: 'Agendado' },
  { value: 'sold', label: 'Vendido' },
  { value: 'gave_up', label: 'Desistiu' },
]

export const tagColumnColor: Record<BoardTagValue | 'unkown', string> = {
  pending: 'bg-active',
  waiting_cep: 'bg-paused',
  out_area: 'bg-purple',
  already_client: 'bg-purple',
  finance: 'bg-purple',
  negotiation: 'bg-paused',
  stopped_responding: 'bg-paused',
  lost_lead: 'bg-purple',
  scheduled: 'bg-purple',
  sold: 'bg-purple',
  gave_up: 'bg-purple',
  unkown: 'bg-tertiary'
}

export const cardColumnColor: Record<BoardTagValue | 'unkown', string> = {
  pending: 'bg-secondary',
  waiting_cep: 'bg-paused',
  out_area: 'bg-inactive',
  already_client: 'bg-purple',
  finance: 'bg-purple',
  negotiation: 'bg-paused',
  stopped_responding: 'bg-inactive',
  lost_lead: 'bg-inactive',
  scheduled: 'bg-active',
  sold: 'bg-active',
  gave_up: 'bg-inactive',
  unkown: 'bg-tertiary'
}

export const sellTags = ['scheduled', 'sold', 'gave_up']