type CheckboxProps = {
  onClick?: () => void
  isChecked: boolean
  label: string
  description?: string
}

export const Checkbox = ({ onClick, isChecked, label, description }: CheckboxProps) => {

  return (
    <div className="flex gap-1.5">
      <button onClick={onClick} type="button">
        <img src={`/icons/form/form_checkbox_${isChecked ? 'checked' : 'unchecked'}.svg`} alt={label} className="w-4 h-4 min-w-[1rem]" />
      </button>
      <label onClick={onClick} className="flex flex-col justify-center cursor-pointer">
        <p className="text-sm">{label}</p>
        { description && <p className="text-xs">{description}</p> }
      </label>
    </div>
  )
}