import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"

import { ChatIntegration, ChatIntegrationType } from "models/chatIntegration"
import { AxiosError } from "axios"
import { messageToError, ValidationError } from "utils/validation"
import { toast } from "react-toastify"
import { getIntegrationSellers, webhookCheck } from "services/chatIntegrationService"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { queryClient } from "services/queryClient"
import { IntegrationSeller } from "models/integrationSeller"

export type OpaIntegrationForm = {
  name: string
  type: ChatIntegrationType
  opaSuite: {
    host: string
    token: string
    sellers: string[]
  }
}

export const useViewModel = (
  selectedIntegration: ChatIntegration | undefined, handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction
) => {
  const {
    register, formState: { errors }, setValue, handleSubmit, setError, watch
  } = useForm<OpaIntegrationForm>()

  const [step, setStep] = useState(0)
  const [loadingForm, setLoadingForm] = useState(false)
  const [sellers, setSelers] = useState<IntegrationSeller[]>([])
  const [verifyLoading, setVerifyLoading] = useState(false)

  const selectedSellers = watch("opaSuite.sellers") ?? []
  
  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" }
    })
  }, [register])

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "")
    setValue("type", selectedIntegration?.type ?? "opaSuite")
    setValue("opaSuite.host", selectedIntegration?.opaSuite?.host ?? "")
    setValue("opaSuite.sellers", selectedIntegration?.opaSuite?.sellers ?? [])
    setValue("opaSuite.token", selectedIntegration?.opaSuite?.token ?? "")
  }, [selectedIntegration, setValue])

  async function handleSubmitFormFirstStep(data: OpaIntegrationForm) {
    if (loadingForm) return
    setLoadingForm(true)

    try {
      toast.info("Buscando os vendedores...")
      const { attendents } = await getIntegrationSellers({
        type: data.type as ChatIntegrationType,
        opaSuite: {
          host: data.opaSuite.host,
          token: data.opaSuite.token
        }
      })
      setSelers(attendents)
      setLoadingForm(false)
      setStep(1)
      toast.success("Vendedores buscados com sucesso!")
    } catch (error) {
      toast.error(errorMessage(error as Error))
      setLoadingForm(false)
    }
  }

  const errorMessage = (error: Error) => {
    const _err = error as AxiosError
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as { errors: ValidationError[] | undefined }

      if (data.errors) {
        const fields = data.errors.map(error => {
          setError(error.key as keyof OpaIntegrationForm, { message: messageToError(error.message) })
          return error.key
        })
        return `Os campos ${fields.join(", ")} são inválidos`
      }
      if (_err.response.status === 400) return "O domínio ou o token são inválidos"
    }
    return "Ocorreu um erro, por favor tente novamente"
  }

  async function handleSubmitFormSecondStep(data: OpaIntegrationForm) {
    if (loadingForm) return
    setLoadingForm(true)
    await handleSaveOrUpdate(data)
    setLoadingForm(false)
  }

  const handleCancelWebhookStep = () => {
    setStep(0)
  }

  const handleShowWebhookStep = () => {
    setStep(2)
  }

  const handleVerifyWebhook = async () => {
    if (!selectedIntegration) return
    
    setVerifyLoading(true)

    let refetch = false
    
    try {
      const isVerified = await webhookCheck(selectedIntegration.id)
      selectedIntegration.opaSuite!.webhookVerified = isVerified
      if (isVerified) {
        toast.success("Webhook verificado com sucesso!")
        refetch = true
      } else {
        toast.warn("O webhook não está verificado")
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao verificar o webhook")
    }

    setVerifyLoading(false)

    if (refetch) {
      await queryClient.invalidateQueries("chatIntegrations")
    }
  }

  return {
    selectedIntegration,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    step,
    sellers,
    selectedSellers,
    verifyLoading,
    handleShowWebhookStep,
    handleVerifyWebhook,
    handleCancelWebhookStep
  }
}
