import { Provider } from "./provider"

export type APIReport = Omit<Report, 'initDate' | 'endDate' | 'createdAt' | 'updatedAt'> & {
  initDate: string
  endDate: string
  createdAt: string
  updatedAt: string
}

export type ReportType = 'lead.list' | 'lead.list.updated' | 'lead.list.selled'

export default class Report {

  constructor(
    readonly id: string,
    readonly provider: Provider,
    readonly type: ReportType,
    readonly status: 'pending' | 'success' | 'error',
    readonly initDate: Date,
    readonly endDate: Date,
    readonly path: string | null,
    readonly createdAt: Date,
    readonly updatedAt: Date
  ) {}

  static fromAPI(apiReport: APIReport): Report {
    return new Report(
      apiReport.id,
      apiReport.provider,
      apiReport.type,
      apiReport.status,
      new Date(apiReport.initDate),
      new Date(apiReport.endDate),
      apiReport.path,
      new Date(apiReport.createdAt),
      new Date(apiReport.updatedAt)
    )
  }

  get statusFormatted() {
    switch (this.status) {
      case 'pending':
        return 'Pendente'
      case 'success':
        return 'Sucesso'
      case 'error':
        return 'Erro'
    }
  }

  get name() {
    switch (this.type) {
      case 'lead.list':
        return 'Lista de leads - Data de entrada'
      case 'lead.list.updated':
        return 'Lista de leads - Data de atualização'
      case 'lead.list.selled':
        return 'Lista de leads - Data de venda'
    }
  }

  get downloadUrl() {
    return `${import.meta.env.VITE_ASSETS_URL}/${this.path}`
  }

  get dateRange() {
    return `${this.initDate.toLocaleDateString()} - ${this.endDate.toLocaleDateString()}`
  }

  get createdAtFormatted() {
    return this.createdAt.toLocaleString()
  }

}
