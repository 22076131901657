import { useState } from "react"

import { Select } from "components/Forms/Select"
import { SaveOrUpdateChatIntegrationFunction } from "../viewModel"
import { OpaSuiteForm } from "./OpaSuiteForm"
import { ChatIntegration, ChatIntegrationType } from "models/chatIntegration"
// import { SZChatForm } from "./SZChatForm"
import { MatrixForm } from "./MatrixChatForm"
import { WhatsappForm } from "./WhatsappForm"
import { HuggyForm } from "./HuggyForm"
import { UpChatForm } from "./UpChatForm"
import { BlipForm } from "./BlipForm"
import { Multi360Form } from "./Multi360Form"

const integrationsList: {value: ChatIntegrationType, label: string}[] = [
  {value: 'opaSuite', label: 'Opa Suite'},
  // {value: 'szChat', label: 'SZ Chat (Fortics)'},
  {value: 'matrix', label: 'MatrixGO/OmniChat'},
  {value: 'huggy', label: 'Huggy'},
  {value: 'whatsappUnofficial', label: 'Whatsapp'},
  {value: 'upChat', label: 'UpChat'},
  {value: 'blip', label: 'Blip'},
  {value: 'multi360', label: "Multi 360"},
]

const videoLinks = {
  opaSuite: 'https://www.loom.com/share/5b2cb5d34c7640cfb3b1910d9b261a3d',
  // szChat: 'https://www.loom.com/share/d2ec4b82f1e242a2a736d46a00d85158',
  matrix: 'https://www.loom.com/share/f7f69a655a5c47c3a9864a3d2ee03bf6',
  huggy: '',
  whatsappUnofficial: 'https://www.loom.com/share/01dc644042b54670975d05f191816e29',
  upChat: '',
  blip: '',
  multi360: ''
}

type Props = {
  providerId: string
  selectedIntegrationObject: ChatIntegration | undefined
  initialIntegration: ChatIntegrationType
  onClose: () => void
  onSaveOrUpdate: SaveOrUpdateChatIntegrationFunction
}

export const ChatIntegrationsForm = ({ selectedIntegrationObject, onClose, onSaveOrUpdate, providerId, initialIntegration }: Props) => {
  const [selectedIntegration, setSelectedIntegration] = useState<ChatIntegrationType>(selectedIntegrationObject?.type ?? initialIntegration)

  const handleChangeIntegration = (value: string | undefined) => {
    setSelectedIntegration(value as ChatIntegrationType)
  }

  const FormComponents: Record<ChatIntegrationType, JSX.Element> = {
    opaSuite: <OpaSuiteForm selectedIntegration={selectedIntegrationObject} onClose={onClose} onSave={onSaveOrUpdate} />,
    // szChat: <SZChatForm selectedIntegration={selectedIntegrationObject} onClose={onClose} onSave={onSaveOrUpdate} />,
    matrix: <MatrixForm selectedIntegration={selectedIntegrationObject} onClose={onClose} onSave={onSaveOrUpdate} />,
    whatsappUnofficial: <WhatsappForm selectedIntegration={selectedIntegrationObject} onClose={onClose} onSave={onSaveOrUpdate} />,
    huggy: <HuggyForm selectedIntegration={selectedIntegrationObject} onClose={onClose} providerId={providerId} />,
    upChat: <UpChatForm selectedIntegration={selectedIntegrationObject} onClose={onClose} onSave={onSaveOrUpdate} />,
    blip: <BlipForm selectedIntegration={selectedIntegrationObject} onClose={onClose} onSave={onSaveOrUpdate} />,
    multi360: <Multi360Form selectedIntegration={selectedIntegrationObject} onClose={onClose} onSave={onSaveOrUpdate} />
  }

  return (
    <div>
      <div 
        onClick={onClose}
        className="modalBackground"
      />
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-md py-8 px-12 rounded-xl w-96 z-50 bg-modal flex flex-col gap-5">
        {
          videoLinks[selectedIntegration].length > 0 && (
            <a
              href={videoLinks[selectedIntegration]}
              className="absolute right-8"
              target='_blank'
              rel='noreferrer'
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#F15A22" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
              </svg>
            </a>
          )
        }
        <Select
          label='Integração'
          onChange={handleChangeIntegration}
          options={integrationsList}
          selected={selectedIntegration}
          variant="secondary"
        />
        {FormComponents[selectedIntegration]}
      </div>
    </div>
  )
}