import { cn } from "utils/styles"

type DateItem = {
  date: Date
  isInCurrentMonth: boolean
}

type SelectedDate = {
  initDate: Date
  endDate?: Date
}

type DateFilterCalendarDayProps = {
  item: DateItem
  selectedDate?: SelectedDate
  onClick: (date: Date) => void
}

export function DateFilterCalendarDay({ item, selectedDate, onClick }: DateFilterCalendarDayProps) {

  if (item.isInCurrentMonth) {
    const isSelected = selectedDate && (
      selectedDate.initDate.toDateString() === item.date.toDateString()
      || (selectedDate.endDate && selectedDate.endDate.toDateString() === item.date.toDateString())
    )
    const isBetween = selectedDate && selectedDate.initDate < item.date && selectedDate.endDate && selectedDate.endDate > item.date
    const isToday = item.date.toDateString() === new Date().toDateString()
    
    return (
      <button
        className={cn(
          "w-6 h-6 leading-6 text-center text-sm font-extrabold",
          (isSelected || isBetween) && "bg-secondary/30 rounded-full",
          (!isSelected && !isBetween && isToday) && "bg-primary/30 rounded-full",
        )}
        onClick={() => onClick(item.date)}
      >
        {item.date.getDate()}
      </button>
    )
  }

  return <p className={["w-6 h-6 leading-6 text-center text-white/30 font-extrabold text-sm"].join(" ")}>{item.date.getDate()}</p>
}
