import { cn } from "utils/styles"

type Props = {
  onClose: () => void
  children: React.ReactNode | React.ReactNode[]
  className?: string
}

export const Dialog = ({ onClose, children, className = 'w-96 py-8 px-12' }: Props) => {
  return (
    <div>
      <div 
        onClick={(event) => {
          event.stopPropagation()
          onClose()
        }}
        className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-screen h-screen bg-black bg-opacity-30 z-40"
      />
      <div className={cn(
        "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-md rounded-xl z-50 bg-modal flex flex-col gap-5",
        className
      )}>
        {children}
      </div>
    </div>
  )
}