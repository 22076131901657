import { useState } from "react"
import { ProviderFilter } from "./ProviderFilter"
import { cn } from "utils/styles"
import { DateFilter } from "./DateFilter"
import useAuth from "hooks/useAuth"

type PageFilterProps = {
  hideTimeFilter?: boolean
  children?: React.ReactElement | React.ReactElement[]
}

export const PageFilter = ({ children, hideTimeFilter = false }: PageFilterProps) => {

  const [showFilter, setShowFilter] = useState(false)
  const { user } = useAuth()

  const handleToggleFilter = () => {
    setShowFilter(!showFilter)
  }

  if (user?.role !== 'admin' && hideTimeFilter) return null
  
  return (
    <div className="flex items-center">
      <button onClick={handleToggleFilter} className="h-9">
        <img src="/icons/page/filter.svg" />
      </button>
      <div className={cn(showFilter ? '' : 'hidden')}>
        <div className="modalBackground" onClick={handleToggleFilter} />
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-md py-8 px-12 rounded-xl w-96 z-50 bg-modal flex flex-col gap-4">
          { user?.role === "admin" && (
            <div className="flex flex-col gap-4">
              <ProviderFilter />
              {hideTimeFilter ? null : <div className="w-full h-[1px] bg-secondary rounded-full" />}
            </div>
          ) }
          {hideTimeFilter ? null : <DateFilter />}
          {children !== undefined && <div className="w-full h-[1px] bg-secondary rounded-full" />}
          {children}
        </div>
      </div>
    </div>
  )
}