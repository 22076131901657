import { useState } from "react"
import { useQuery } from "react-query"

import { queryClient } from "services/queryClient"
import { createIntegration, getIntegrations, updateIntegration, deleteIntegration } from "services/integrationService"

import { Integration } from "models/integration"
import { CampaignType } from "models/campaign"
import { MetaIntegrationForm } from "./form/MetaForm"
import { toast } from "react-toastify"
import { GoogleIntegrationForm } from "./form/GoogleForm"

type SaveOrUpdateData = MetaIntegrationForm | GoogleIntegrationForm

export type SaveOrUpdateCampaignsIntegrationFunction = (data: SaveOrUpdateData) => Promise<void>

export const useViewModel = (clientId: string) => {

  const { data: response, isLoading, isFetching, isError, refetch } = useQuery(["integrations", clientId], () => getIntegrations(clientId))

  const [showIntegrationModal, setShowIntegrationModal] = useState(false)
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  async function handleSubmitForm(data: SaveOrUpdateData) {
    if (selectedIntegration) {
      await updateIntegration({ 
        ...data, 
        id: selectedIntegration._id,
        type: data.type as CampaignType,
        provider: clientId
      })
    } else {
      await createIntegration({
        ...data,
        type: data.type as CampaignType,
        provider: clientId
      })
    }
    
    queryClient.invalidateQueries(["integrations", clientId])
  }

  function handleAddIntegration() {
    setSelectedIntegration(undefined)
    setShowIntegrationModal(true)
  }

  function handleEditIntegration(integration: Integration) {
    setSelectedIntegration(integration)
    setShowIntegrationModal(true)
  }

  const handleDeleteIntegration = (integration: Integration) => {
    setSelectedIntegration(integration)
    setShowDeleteModal(true)
  }

  function handleCloseIntegrationModal() {
    setSelectedIntegration(undefined)
    setShowIntegrationModal(false)
  }

  const handleCloseDeleteModal = () => setShowDeleteModal(false)

  const handleConfirmDelete = async () => {
    if (selectedIntegration) {
      setIsDeleting(true)
      try {
        await deleteIntegration(selectedIntegration._id)
        queryClient.invalidateQueries(["integrations", clientId])
        handleCloseDeleteModal()
      } catch (error) {
        toast.error("Erro ao remover integração")
      }
      setIsDeleting(false)
    }
  }

  const refetchIntegrations = () => refetch()

  return {
    response,
    refetch: refetchIntegrations,
    showIntegrationModal,
    selectedIntegration,
    isLoading: isLoading || (isFetching && !response),
    isError,
    handleSubmitForm,
    handleAddIntegration,
    handleEditIntegration,
    handleCloseIntegrationModal,
    handleDeleteIntegration,
    handleCloseDeleteModal,
    showDeleteModal,
    handleConfirmDelete,
    isDeleting
  }
}