import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAuthUser } from "models/authUser";
import { Seller } from "models/seller";

//State
export interface IAuthState {
  user?: IAuthUser
  isLoading: boolean
  selectedProvider: string
  sellers?: Seller[]
  selectedSeller: string
}

const INITIAL_STATE: IAuthState = {
  user: undefined,
  isLoading: true,
  selectedProvider: sessionStorage.getItem('providerId') ?? "admin",
  sellers: undefined,
  selectedSeller: 'all'
};

//Slice
const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    onLogin: (state: IAuthState, action: PayloadAction<IAuthUser>) => {
      state.user = action.payload
    },
    onLogout: (state: IAuthState) => {
      state.user = undefined
    },
    setIsLoadingUser: (state: IAuthState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSelectedProvider: (state: IAuthState, action: PayloadAction<string>) => {
      state.selectedProvider = action.payload
      if (action.payload === "admin") {
        sessionStorage.removeItem('providerId')
      } else {
        sessionStorage.setItem('providerId', action.payload)
      }
    },
    updateSellers: (state: IAuthState, action: PayloadAction<Seller[] | undefined>) => {
      state.sellers = action.payload
    },
    updateSelectedSeller: (state: IAuthState, action: PayloadAction<string>) => {
      state.selectedSeller = action.payload
    }
  }
})

export const { onLogin, onLogout, setIsLoadingUser, setSelectedProvider, updateSellers, updateSelectedSeller } = authSlice.actions
export default authSlice.reducer