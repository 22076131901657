import { AxiosError } from "axios"
import { InfoCardProps } from "components/InfoCard"
import useAuth from "hooks/useAuth"
import { useFilters } from "hooks/useFilters"
import { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import { CampaignCounters, getCampaignsById } from "services/campaignService"
import { isDifferentCounters } from "utils/counters"

export const useViewModel = (campaignId: string) => {
  const navigate = useNavigate()
  const { selectedProvider } = useAuth()
  const { selectedDate } = useFilters()
  const [campaignsPage, setCampaignsPage] = useState(1)
  const [counters, setCounters] = useState<CampaignCounters>()

  const range = `${selectedDate?.initDate.toDateString()}-${selectedDate?.endDate.toDateString()}`

  const {
    data: campaign,
    isLoading: isLoadingPage,
    isError: isErrorPage,
    refetch: retryPage,
    error: errorPage
  } = useQuery(
    ["campaign", campaignId, range, selectedProvider],
    () => getCampaignsById(campaignId, 20, selectedDate!.initDate, selectedDate!.endDate),
    {
      retry(failureCount, error) {
        if (error instanceof AxiosError && error.response?.status === 404) return false
        if (failureCount < 2) return true
        return false
      },
    }
  )

  const infoCards: InfoCardProps[] = useMemo(() => {
    return [
      { type: 'visits', value: counters?.visits },
      { type: 'leads', value: counters?.leads },
      { type: 'invested', value: counters?.invested },
    ] as InfoCardProps[]
  }, [counters])

  function handleChangePage(page: number) {
    setCampaignsPage(page)
  }

  useEffect(() => {
    if (isErrorPage) {
      const _error = errorPage as AxiosError
      if (_error.isAxiosError) {
        if (_error.response?.status === 404) {
          navigate("/404")
        }
      }
    }
  }, [errorPage, isErrorPage, navigate])

  useEffect(() => {
    if (campaign?.counters && (!counters || isDifferentCounters(campaign.counters, counters))) {
      setCounters(campaign?.counters)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign?.counters])

  const handleRetry = () => retryPage()

  return {
    campaign: campaign,
    campaignsPage: campaignsPage,
    handleChangePage: handleChangePage,
    isLoadingPage: isLoadingPage,
    isErrorPage: isErrorPage,
    retryPage: handleRetry,
    navigate: navigate,
    infoCards
  }
}