import { useViewModel } from "./viewModel"
import { TextField } from "components/Forms/TextField"
import { Button } from "components/Button"
import { PageHeader } from "components/Page/PageHeader"
import { Form, FormColumn, FormRow } from "components/Forms/Form"
import { MultiSelect } from "components/Forms/MultiSelect"

export const ReturnHuggy = () => {

  const viewModel = useViewModel()

  if (!viewModel.hasData) {
    return (
      <div>
        <PageHeader 
          title="Concluir integração Huggy"
          backPath={`/clientes/${viewModel.providerId}`}
        />
        <p className="mt-7 text-lg">Ocorreu um erro ao autorizar no Huggy, por favor reinicie o processo e tente novamente</p>
      </div>
    )
  }

  if (viewModel.step === 2) {
    return (
      <section>
        <PageHeader 
          title="Concluir integração Huggy"
          backPath={`/clientes/${viewModel.providerId}`}
        />
        <Form 
          onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)}
          className="mt-12 tablet:mt-24"
        >
          <FormRow>
            <FormColumn>
            <MultiSelect
              label='Selecione os vendedores'
              onChange={(value) => viewModel.setValue("sellers", value)}
              options={viewModel.sellers.map(seller => ({value: seller.id, label: seller.name}))}
              selected={viewModel.selectedSellers}
              error={viewModel.errors.sellers?.message}
              variant="secondary"
            />
            </FormColumn>
          </FormRow>
          <Button loading={viewModel.loadingForm} className="w-48 mt-20">
            Salvar
          </Button>
        </Form>
      </section>
    )
  }

  return (
    <section>
      <PageHeader 
        title="Concluir integração Huggy"
        backPath={`/clientes/${viewModel.providerId}`}
      />
      <Form 
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
        className="mt-12 tablet:mt-24"
      >
        <FormRow>
          <FormColumn>
            <TextField
              label='Nome da integração'
              placeholder='Digite o nome da integração'
              error={viewModel.errors.name?.message}
              register={viewModel.register("name", {
                required: { value: true, message: "O nome da integração é obrigatório" }
              })}
            />
            <TextField
              label='ID do cliente'
              placeholder='Digite o ID do cliente'
              error={viewModel.errors.clientId?.message}
              register={viewModel.register("clientId", {
                required: { value: true, message: "O ID do cliente é obrigatório" }
              })}
            />
            <TextField
              label='Segredo do cliente'
              placeholder='Digite o segredo do cliente'
              error={viewModel.errors.clientSecret?.message}
              register={viewModel.register("clientSecret", {
                required: { value: true, message: "O segredo do cliente é obrigatório" }
              })}
            />
          </FormColumn>
        </FormRow>
        <Button loading={viewModel.loadingForm} className="w-48 mt-20">
          Avançar
        </Button>
      </Form>
    </section>
  )
}