import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreLeadCard } from "models/lead";
import { FilterOption } from "pages/leads/components/LeadsSalesFunnel/components/LeadBoardFilters";

//State
export interface IBoardFiltersState {
  filterOption: FilterOption
  originFilters: string[]
  selectedLead: StoreLeadCard | null
}

const INITIAL_STATE: IBoardFiltersState = {
  filterOption: "date",
  originFilters: [],
  selectedLead: null
};

//Slice
const filtersSlice = createSlice({
  name: "boardFilters",
  initialState: INITIAL_STATE,
  reducers: {
    updateFilterOption: (state: IBoardFiltersState, action: PayloadAction<FilterOption>) => {
      state.filterOption = action.payload;
    },
    updateOriginFilter: (state: IBoardFiltersState, action: PayloadAction<string[]>) => {
      state.originFilters = action.payload;
    },
    updateSelectedLead: (state: IBoardFiltersState, action: PayloadAction<StoreLeadCard | null>) => {
      state.selectedLead = action.payload;
    }
  }
})

export const { updateFilterOption, updateOriginFilter, updateSelectedLead } = filtersSlice.actions
export default filtersSlice.reducer