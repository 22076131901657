import { toCurrency, toDecimal } from "utils/format"
import { cn } from "utils/styles"

type Props = {
  variant?: 'primary' | 'secondary'
  invested: number
  dailyInvested: number
  leads: number
  dailyLeads: number
}

export const InvestedCard = ({
  variant = 'primary', invested, leads, dailyInvested, dailyLeads
}: Props) => {

  return (
    <div className={cn(
      "rounded-2xl",
      variant === 'primary' ? 'bg-primaryMultiply/70 pt-7 pb-5 px-7' : 'border-white/30 border py-2 px-4 flex flex-col justify-center'
    )}>
      <div className={cn("flex items-center", variant === 'primary' ? 'gap-2.5' : 'gap-1')}>
        <img className={cn(variant === 'primary' ? '' : 'h-3.5')} src="/icons/campaigns/invested.svg" alt="" />
        <p className={cn(variant === 'primary' ? '' : 'text-xs')}>Resumo</p>
      </div>
      <div className={cn("flex justify-between font-input", variant === 'primary' ? 'mt-2 desktop:mt-5' : 'mt-1')}>
        <div>
          <p className="text-secondary text-xs">Total investido</p>
          <p className={cn("font-bold", variant === 'primary' ? 'text-2xl' : 'text-xl')}>
            {toCurrency(invested)}
          </p>
          <p className="text-secondary text-xs">{toCurrency(dailyInvested)}/dia</p>
        </div>
        <div>
          <p className="text-secondary text-xs">Leads totais</p>
          <p className={cn("font-bold", variant === 'primary' ? 'text-2xl' : 'text-xl')}>
            {leads}
          </p>
          <p className="text-secondary text-xs">{toDecimal(dailyLeads)}/dia</p>
        </div>
      </div>
    </div>
  )
}