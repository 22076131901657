import { useState, useEffect } from 'react';

function useBreakpoints(custom: number = 0) {
  function getWindowDimensions() {
    const { innerWidth: width } = window;

    return {
      isDesktop: width >= 1200,
      isTablet: width < 1200 && width >= 768,
      isMobile: width < 768,
      custom: width >= custom,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowDimensions
}

export { useBreakpoints }
