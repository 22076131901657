type ValidationMessages = "required" | "too_short" | "too_long" | "invalid" | "used"

export type ValidationError = {
  key: string
  message: ValidationMessages
}

export const messageToError = (message: ValidationMessages) => {
  switch (message) {
    case "required":
      return "O campo é obrigatório"
    case "used":
      return "Já está em uso"
    default:
      return "O campo é inválido"
  }
}