import { useLocation, useNavigate } from "react-router-dom"

import { IAuthUser } from "models/authUser"
import { getRoutes } from "utils/routes"

export const useRoutes = (user: IAuthUser | undefined, logout: () => void) => {
  const routes = getRoutes(user?.role, user?.isActive)
  const navigate = useNavigate()
  const location = useLocation()

  const isActive = (route: string): boolean => {
    if (route === "/") {
      return location.pathname === "/"
    }
    return location.pathname.includes(route)
  }

  function handleLogout() {
    logout()
    navigate("/login")
  }

  return {
    routes,
    isActive,
    navigate,
    handleLogout
  }
}