import { Button } from "components/Button"
import { IntegrationContainer } from "../components/integration-container"
import { useViewModel } from "./viewModel"
import { IntegrationRow } from "../components/integration-row"
import { CampaignsIntegrationsForm } from "./form"
import { ConfirmDialog } from "components/ConfirmDialog"

type CampaignsIntegrationProps = {
  clientId: string
}

export const CampaignsIntegration = ({ clientId }: CampaignsIntegrationProps) => {

  const { 
    response, showIntegrationModal, handleAddIntegration, handleCloseIntegrationModal,
    refetch, handleEditIntegration, selectedIntegration,  isLoading, isError,
    handleSubmitForm, handleCloseDeleteModal, handleConfirmDelete, handleDeleteIntegration,
    showDeleteModal, isDeleting
  } = useViewModel(clientId)

  return (
    <>
      {
        showIntegrationModal && <CampaignsIntegrationsForm
          selectedIntegration={selectedIntegration}
          onClose={handleCloseIntegrationModal}
          onSaveOrUpdate={handleSubmitForm}
          initialIntegrationType="facebook"
        />
      }
      {
        showDeleteModal && selectedIntegration && (
          <ConfirmDialog
            title="Remover integração"
            message={`Tem certeza que deseja remover a integração ${selectedIntegration.name}?`}
            show
            onConfirm={handleConfirmDelete}
            onCancel={handleCloseDeleteModal}
            isLoading={isDeleting}
          />
        )
      }
      <IntegrationContainer
        action={{
          label: "Adicionar",
          onClick: handleAddIntegration
        }}
        hasData={(response?.data?.length ?? 0) > 0}
        isError={isError}
        isLoading={isLoading}
        onRetry={refetch}
        title="Integrações de Campanhas"
        className="mt-12"
      >
        {
          response?.data.map(integration => (
            <IntegrationRow key={integration._id} path={`/icons/campaigns/${integration.type}.svg`} {...integration}>
              <Button onClick={() => handleEditIntegration(integration)} size="tiny" className="w-10">
                <img src="/icons/form/edit.svg" alt="Editar" className="h-5" />
              </Button>
              <Button onClick={() => handleDeleteIntegration(integration)} size="tiny" className="w-10">
                <img src="/icons/form/delete.svg" alt="Remover" className="h-5" />
              </Button>
            </IntegrationRow>
          ))
        }
      </IntegrationContainer>
    </>
  )
}