import { useState } from "react"
import { LeadsSalesFunnel } from "./components/LeadsSalesFunnel"
import useAuth from "hooks/useAuth"
import { PageHeader } from "components/Page/PageHeader"
import { PageFilter } from "components/Page/PageFilter"
import { LeadBoardFilters } from "./components/LeadsSalesFunnel/components/LeadBoardFilters"
import { LeadSearchBox } from "./components/LeadSearchBox"
import { Button } from "components/Button"
import { Link } from "react-router-dom"

export default function Leads() {
  
  const { selectedProvider } = useAuth()
  const [showSearchLead, setShowSearchLead] = useState(false)

  const handleToggleSearchLead = () => setShowSearchLead(!showSearchLead)

  return (
    <div>
      <div className="flex justify-between items-start">
        <PageHeader
          title="Leads"
        />
        <div className="flex gap-2">
          {selectedProvider !== 'admin' && (
            <button onClick={handleToggleSearchLead}><img src="/icons/form/search.svg" alt="Pesquisar lead" /></button>
          )}
          <PageFilter>
            <LeadBoardFilters />
          </PageFilter>
          <Link to="/leads/novo">
            <Button leftIcon="/icons/form/add.svg" className="ml-2">Adicionar</Button>
          </Link>
        </div>
      </div>
      <LeadsSalesFunnel />
      { showSearchLead && <LeadSearchBox onClose={handleToggleSearchLead} />}
    </div>
  )
}