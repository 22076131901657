import { useState } from "react"
import { useForm } from "react-hook-form"

import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { authCallback, authConclude } from "services/chatIntegrationService"
import { useNavigate, useParams } from "react-router-dom"
import { useQueryParams } from "hooks/useQueryParams"
import { IntegrationSeller } from "models/integrationSeller"

type HuggyParams = {
  code: string
}

export type HuggyIntegrationForm = {
  name: string
  clientId: string
  clientSecret: string
  sellers?: string[]
}

export const useViewModel = () => {
  const { code } = useQueryParams<HuggyParams>()
  const { providerId } = useParams<{ providerId: string }>()
  const navigate = useNavigate()

  const [sellers, setSellers] = useState<IntegrationSeller[]>([])
  const [step, setStep] = useState(1)
  const [loadingForm, setLoadingForm] = useState(false)
  const [integrationId, setIntegrationId] = useState<string | undefined>(undefined)

  const {
    register, formState: { errors }, setValue, handleSubmit, watch
  } = useForm<HuggyIntegrationForm>()
  const navigation = useNavigate()

  const selectedSellers = watch("sellers") ?? []

  async function handleSubmitFormFirstStep(data: HuggyIntegrationForm) {
    if (loadingForm) return
    setLoadingForm(true)

    try {
      toast.info("Validando integração...")
      const { sellers, integrationId } = await authCallback({
        ...data,
        code: code!,
        providerId: providerId!
      })
      setSellers(sellers)
      setIntegrationId(integrationId)
      setStep(step + 1)
      setLoadingForm(false)
    } catch (error) {
      const _error = error as AxiosError

      if (_error.isAxiosError && _error.response?.status) {
        if (_error.response.status === 403) {
          toast.error("O código de autorização expirou, por favor, reinicie o processo de autorização")
          navigate(`/clientes/${providerId}?new-integration=huggy`)
          return
        }

        if (_error.response.status === 400) {
          toast.error("Erro ao validar integração, verifique as informações e tente novamente")
          return
        }
      }
      
      toast.error("Ocorreu um erro inesperado, por favor, tente novamente")
    }
    setLoadingForm(false)
  }

  async function handleSubmitFormSecondStep(data: HuggyIntegrationForm) {
    if (loadingForm) return
    setLoadingForm(true)

    try {
      toast.info("Finalizando cadastro da integração...")
      await authConclude({
        sellers: data.sellers!,
        integrationId: integrationId!
      })
      toast.success("Integração concluída com sucesso")
      navigation(`/clientes/${providerId}`)
    } catch (error) {
      toast.error("Erro ao concluir integração, verifique as informações e tente novamente")
    }
    setLoadingForm(false)
  }

  return {
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    providerId,
    hasData: code !== undefined && code !== "" && providerId !== undefined && providerId !== "",
    sellers,
    step,
    selectedSellers
  }
}