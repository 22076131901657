import { Pagination } from "components/Tables/Pagination"
import { Table } from "components/Tables/Table"

import { useViewModel } from "./viewModel"
import { PageHeader } from "components/Page/PageHeader"
import { PageFilter } from "components/Page/PageFilter"
import { Button } from "components/Button"
import { CreateReportForm } from "./new"

export default function Reports() {

  const viewModel = useViewModel()

  if (!viewModel.hasProvider) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <PageHeader 
            title="Relatórios"
          />
          <div className="flex items-center gap-3">
            <PageFilter hideTimeFilter />
          </div>
        </div>
        <div className='w-full flex flex-col gap-6 items-center justify-center h-[calc(75svh)]'>
          <p className='text-white text-xl text-center'>Selecione um provedor, para ver o seu relatório</p>
        </div>
      </div>
    )
  }

  return (
    <div>
      {viewModel.showReportDialog && <CreateReportForm onClose={viewModel.handleHideReportDialog} />}
      <div className="flex justify-between items-center">
        <PageHeader 
          title="Relatórios"
        />
        <div className="flex items-center gap-3">
          <PageFilter hideTimeFilter />
          <Button leftIcon="/icons/form/add.svg" onClick={viewModel.handleShowReportDialog}>
            Novo relatório
          </Button>
        </div>
      </div>
      <Table
        className="mt-7"
        headers={["Nome","Estado", "Período", "Data de criação"]}
        fields={[{ key: "name" }, { key: "statusFormatted" }, { key: "dateRange" }, { key: "createdAtFormatted" }]}
        data={viewModel.reports}
        isLoading={viewModel.isLoading}
        hasError={viewModel.isError}
        onRetry={viewModel.retry}
        isRetching={viewModel.isRefetching}
        actions={(report) => {
          if (report.status !== 'success') return null

          return (
            <>
              <a href={report.downloadUrl}>Baixar</a>
            </>
          )
        }}
      />
      <Pagination 
        currentPage={viewModel.page}
        handleChangePage={viewModel.setPage}
        numberOfPages={viewModel.nbPages ?? 0}
      />
    </div>
  )
}
