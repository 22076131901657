import { Button } from "components/Button"
import { TextField } from "components/Forms/TextField"
import { useViewModel } from "./viewModel"
import { ChatIntegration } from "models/chatIntegration"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { CopyText } from "components/Forms/CopyText"
import { MultiSelect } from "components/Forms/MultiSelect"

type Props = {
  selectedIntegration: ChatIntegration | undefined
  onClose: () => void
  onSave: SaveOrUpdateChatIntegrationFunction
}

export const OpaSuiteForm = ({ selectedIntegration, onClose, onSave }: Props) => {
  
  const viewModel = useViewModel(selectedIntegration, onSave)

  if (viewModel.step === 0) {
    return (
      <form 
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
        className="flex flex-col gap-5"
      >
        <TextField
          label='Nome da integração'
          placeholder='Digite o nome da integração'
          error={viewModel.errors.name?.message}
          register={viewModel.register("name", {
            required: { value: true, message: "O nome é obrigatório" }
          })}
          variant="secondary"
        />
        <TextField
          label='Domínio Opa Suite'
          placeholder='Digite o seu domínio personalizado do Opa Suite'
          error={viewModel.errors.opaSuite?.host?.message}
          register={viewModel.register("opaSuite.host", {
            required: { value: true, message: "O domínio é obrigatório" }
          })}
          variant="secondary"
        />
        <TextField
          label='Token de acesso'
          placeholder='Digite o token de acesso'
          error={viewModel.errors.opaSuite?.token?.message}
          register={viewModel.register("opaSuite.token", {
            required: { value: true, message: "O token é obrigatório" }
          })}
          variant="secondary"
        />
        <div className="modalButtons">
          {
            viewModel.selectedIntegration ? (
              <div className="flex-1">
                <Button onClick={viewModel.handleShowWebhookStep} type="button" variant="outline">
                  Webhook
                </Button>
              </div>
            ) : null
          }
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button loading={viewModel.loadingForm} theme="highlight">
            Salvar
          </Button>
        </div>
      </form>
    )
  }

  if (viewModel.step === 2) {
    return (
      <div className="flex flex-col gap-5">
        <CopyText
          text={`https://delipe-campanhas-api.herokuapp.com/v1/webhooks/opa_suite/${viewModel.selectedIntegration?.id}`}
          label="URL do webhook"
          variant="secondary"
        />
        <CopyText
          text={viewModel.selectedIntegration?.opaSuite?.webhookToken ?? ""}
          label="Token do webhook"
          variant="secondary"
        />
        <div className="modalButtons">
          <Button onClick={viewModel.handleCancelWebhookStep} theme="cancel">
            Cancelar
          </Button>
          <Button loading={viewModel.verifyLoading} onClick={viewModel.handleVerifyWebhook} theme="highlight" className="px-7">
            Verificar
          </Button>
        </div>
      </div>
    )
  }
  
  return (
    <form 
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)}
      className="flex flex-col gap-5"
    >
      <MultiSelect
        label='Selecione os vendedores'
        onChange={(value) => viewModel.setValue("opaSuite.sellers", value)}
        options={viewModel.sellers.map(seller => ({value: seller.id, label: seller.name}))}
        selected={viewModel.selectedSellers}
        error={viewModel.errors.opaSuite?.sellers?.message}
        variant="secondary"
      />
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button loading={viewModel.loadingForm} theme="highlight" className="px-7">
          Salvar
        </Button>
      </div>
    </form>
  )
}