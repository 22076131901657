export const moveCard = (positionBefore?: number, positionAfter?: number): number => {
  const startValue = 100000
  const growValue  = 10000

  if (positionBefore !== undefined && positionAfter !== undefined) {
    return Math.round((positionBefore + positionAfter) / 2)
  }

  if (positionBefore !== undefined) {
    return positionBefore + (growValue * 2)
  }

  if (positionAfter !== undefined) {
    if (positionAfter >= 0 && positionAfter < 1) return 0
    return Math.round(positionAfter * 1.5 / 2) 
  }

  return startValue
}