type PaginationItemPageProps = {
  page: number
  isCurrent?: boolean
  handleChangePage: (page: number) => void
}

export function PaginationItemPage({
  page,
  isCurrent = false,
  handleChangePage
}: PaginationItemPageProps) { 

  return (
    <button
      onClick={() => handleChangePage(page)}
      className={[
        "w-9 text-center py-2.5 text-sm rounded-lg hover:bg-secondary/90 transition-colors duration-200",
        isCurrent && "bg-secondary text-white"
      ].join(" ")} 
    >
      {page}
    </button>
  )
}