import { toPercent } from "utils/format"
import { cn } from "utils/styles"

type Props = {
  variant?: 'primary' | 'secondary'
  insideArea: number
  insideAreaPercent: number
  outsideArea: number
  outisdeAreaPercent: number
  pending: number
  pendingPercent: number
}

export const LeadsCard = ({
  variant = 'primary', insideArea, insideAreaPercent, outisdeAreaPercent, outsideArea, pending, pendingPercent
}: Props) => {

  return (
    <div className={cn(
      "rounded-2xl flex flex-col",
      variant === 'primary' ? 'bg-primaryMultiply/70 pt-7 pb-5 px-7' : 'border-white/30 border py-2 px-4 flex flex-col justify-center'
    )}>
      <div className={cn("flex items-center", variant === 'primary' ? 'gap-2.5' : 'gap-1')}>
        <img className={cn(variant === 'primary' ? '' : 'h-3.5')} src="/icons/campaigns/leads.svg" alt="" />
        <p className={cn(variant === 'primary' ? '' : 'text-xs')}>Leads gerados</p>
      </div>
      <div className={cn("flex justify-between font-input", variant === 'primary' ? 'mt-2 desktop:mt-8' : 'mt-2')}>
        <div>
          <div className={cn("flex justify-center items-end font-bold", variant === 'primary' ? 'gap-2' : 'gap-1')}>
            <p className={cn("text-center", variant === 'primary' ? 'text-2xl' : 'text-xl')}>{insideArea}</p>
            <p className="text-xs mb-1">
              {toPercent(insideAreaPercent)}
            </p>
          </div>
          <p className="text-secondary text-xs">Dentro da área</p>
        </div>
        <div>
          <div className={cn("flex justify-center items-end font-bold", variant === 'primary' ? 'gap-2' : 'gap-1')}>
            <p className={cn("text-center", variant === 'primary' ? 'text-2xl' : 'text-xl')}>{outsideArea}</p>
            <p className="text-xs mb-1">
              {toPercent(outisdeAreaPercent)}
            </p>
          </div>
          <p className="text-secondary text-xs">Fora da área</p>
        </div>
        <div>
          <div className={cn("flex justify-center items-end font-bold", variant === 'primary' ? 'gap-2' : 'gap-1')}>
            <p className={cn("text-center", variant === 'primary' ? 'text-2xl' : 'text-xl')}>{pending}</p>
            <p className="text-xs mb-1">
              {toPercent(pendingPercent)}
            </p>
          </div>
          <p className="text-secondary text-xs">Aguardando CEP</p>
        </div>
      </div>
    </div>
  )
}