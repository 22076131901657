import { SearchField } from "components/Forms/SearchField"
import { Masks } from "utils/mask"

type Props = {
  value: string
  onChange: (value: string) => void
  children?: React.ReactNode
  onClose: () => void
  mask?: Masks | Masks[]
}

export const SearchBox = ({ value, onChange, children, onClose, mask }: Props) => {

  return (
    <div>
      <div className="modalBackground" onClick={onClose}></div>
      <div className="fixed top-36 left-1/2 transform -translate-x-1/2 shadow-md py-8 px-12 rounded-xl w-96 z-50 bg-modal flex flex-col gap-4">
        <SearchField
          value={value}
          onChange={onChange}
          mask={mask}
          placeholder="Pesquise pelo telefone"
          variant="secondary"
          leftIcon="/icons/form/search.svg"
        />
        <p className="-mt-2 text-xs px-3 text-secondary">Máximo de 25 resultados por pesquisa</p>
        <div className="overflow-y-auto max-h-[calc(100svh-384px)] flex flex-col gap-4">
          {children}
        </div>
      </div>
    </div>
  )
}