type Props = {
  icon: 'delete' | 'edit' | 'done' | 'close'
  onClick: () => void
}

export const ColumnAction = ({ icon, onClick }: Props) => {
  return (
    <button
      onClick={onClick}
      className='cursor-pointer hover:bg-accent-500/80 hover:ring-4 hover:ring-accent-500/30 rounded p-0.5'
    >
      <img src={`/icons/board/${icon}.svg`} width={18} />
    </button>
  )
}