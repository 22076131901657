import { Button } from "components/Button"
import { TextField } from "components/Forms/TextField"
import { useViewModel } from "./viewModel"
import { ChatIntegration } from "models/chatIntegration"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { CopyText } from "components/Forms/CopyText"

type Props = {
  selectedIntegration: ChatIntegration | undefined
  onClose: () => void
  onSave: SaveOrUpdateChatIntegrationFunction
}

export const BlipForm = ({ selectedIntegration, onClose, onSave }: Props) => {
  
  const viewModel = useViewModel(selectedIntegration, onSave)

  return (
    <form 
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitSuccess)}
      className="flex flex-col gap-5"
    >
      <TextField
        label="Nome"
        placeholder="Digite o nome da integração"
        error={viewModel.errors.name?.message}
        variant="secondary"
        register={viewModel.register("name", {
          required: { value: true, message: "O nome é obrigatório" }
        })}
      />
      {
        selectedIntegration?.id && (
          <CopyText
            text={`${import.meta.env.VITE_API_URL}/v1/webhooks/blip/${selectedIntegration.id}`}
            variant="secondary"
            label="URL do Webhook"
          />
        )
      }
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button loading={viewModel.loadingForm} theme="highlight">
          Salvar
        </Button>
      </div>
    </form>
  )
}