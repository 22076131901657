import { Button } from "components/Button"
import { Form, FormColumn, FormRow } from "components/Forms/Form"
import { Select } from "components/Forms/Select"
import { TextField } from "components/Forms/TextField"

import { Seller } from "models/seller"

import { FormFields, useViewModel } from "./viewModel"
import { StatusSelect } from "components/Forms/StatusSelect"

type SellerFormProps = {
  seller?: Seller
  onSubmit?: (fields: FormFields) => Promise<void>
  enabled: boolean
  action?: string
}

export function SellerForm({ seller, enabled, action, onSubmit }: SellerFormProps) {

  const {
    handleSubmit, errors, register, setValue, loading, status, handleChangeProvider,
    providers, selectedProviderId, user
  } = useViewModel(seller, onSubmit)

  return (
    <Form onSubmit={handleSubmit} className="mt-12 tablet:mt-24">
      <FormRow>
        <FormColumn>
          <TextField
            label="Nome"
            placeholder="Digite o nome do vendedor"
            error={errors.name?.message}
            disabled={!enabled}
            register={register("name", {
              required: { value: true, message: "O nome da empresa é obrigatório" }
            })}
          />
          <TextField 
            label="Email"
            type="email"
            placeholder="Digite o email do login"
            error={errors.email?.message}
            disabled={!enabled}
            register={register("email", {
              required: { value: true, message: "O nome da empresa é obrigatório" },
              pattern: { value: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: "O email não é válido" }
            })}
          />
          {
            seller === undefined && (
              <TextField 
                label="Senha"
                type="password"
                placeholder="Digite o senha do login"
                error={errors.password?.message}
                disabled={!enabled}
                register={register("password", {
                  required: { value: true, message: "O nome da empresa é obrigatório" },
                  minLength: { value: 6, message: "A senha deve ter no mínimo 6 caracteres" }
                })}
              />
            )
          }
          {user?.role === "admin" ? (
            <Select
              onChange={handleChangeProvider}
              options={providers?.map(provider => ({ label: provider.name, value: provider.id })) ?? []}
              selected={selectedProviderId}
              disabled={!enabled}
              placeholder="Selecione o provedor"
              label="Provedor"
              error={errors.providerId?.message}
            />
          ) : null}
          <StatusSelect
            label="Status do cliente"
            selected={String(status)}
            disabled={!enabled}
            options={[
              {label: "Ativo", value: "0"},
              {label: "Inativo", value: "1"},
            ]}
            onChange={(value) => setValue("status", Number(value))}
          />
        </FormColumn>
      </FormRow>
      { action && (
        <Button
          type="submit" 
          size="default"
          loading={loading}
          className="w-48 mt-20"
        >
          {action}
        </Button>
      ) }
    </Form>
  )
}