/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react"

import { TableRowField, TableRowTypes } from "../TableRowField"
import { cn } from "utils/styles"

export type TableField<T> = {
  key: keyof T
  type?: TableRowTypes
}

export type TableTransform<T> = {
  key: string
  transform: (value: T) => any
}

export type TableOverride = {
  key: string
  override: (index: number) => React.ReactNode
}

type TableBodyProps<T> = {
  fields: TableField<T>[]
  transforms?: TableTransform<T>[]
  overrides?: TableOverride[]
  data?: T[]
  actions?: (item: T, index: number) => JSX.Element | null
  variant?: 'primary' | 'secondary'
}

export function TableBody<T,>({ fields, transforms, overrides, data, actions, variant = 'primary' }: TableBodyProps<T>) {

  const [showMoreModal, setShowMoreModal] = useState(-1)

  useEffect(() => {
    if (showMoreModal > -1) {
      setTimeout(() => {
        document.addEventListener("click", handleClickSomewhere)
      })
      return () => document.removeEventListener("click", handleClickSomewhere)
    }
  }, [showMoreModal])

  function handleClickSomewhere(event: MouseEvent) {
    if (event.target instanceof HTMLElement) {
      if (event.target.classList.contains("drop-shadow-lg")) {
        return
      }
    }
    setShowMoreModal(-1)
  }

  function handleOpenShowMoreModal(index: number) {
    setShowMoreModal(index)
  }

  return (
    <tbody>
      {data?.map((item: T, index) => (
        <tr key={index}>
          {fields.map(({ key, type }) => {
            const value = transforms?.find((item) => item.key === key)?.transform(item) ?? item[key]
            const override: React.ReactNode|string = overrides?.find((item) => item.key === key)?.override(index) ?? value

            return (
              <TableRowField
                key={key as string}
                type={type}
                className={cn(
                  "bg-primaryMultiply/90 first:rounded-l-full first:pl-8 whitespace-nowrap font-input",
                  variant === 'primary' ? 'py-1' : 'py-5',
                )}
              >
                {override}
              </TableRowField>
            )
          })}
          {
            actions && actions(item, index) ? (
              <td className="relative bg-primaryMultiply/90 py-1 h-8 rounded-r-full min-w-[4rem] text-end">
                <button onClick={() => handleOpenShowMoreModal(index)} className="flex w-full justify-end pr-4">
                  <img
                    className="w-1 h-5"
                    src="/icons/table/show_more.svg"
                    alt="Ver mais"
                  />
                </button>
                <div className={[
                  'bg-primary text-white items-start font-input text-sm px-5 py-4.5 whitespace-nowrap right-2.5 flex-col absolute z-30 p-2 drop-shadow-lg rounded-lg w-28 gap-1',
                  showMoreModal === index ? "flex animate-open-dropdown" : 'hidden animate-close-dropdown',
                  index > data.length / 2 ? "bottom-0" : "top-0"
                ].join(" ")}
                >
                  {actions(item, index)}
                  <img className={`${index > data.length / 2 ? 'bottom-2' : 'top-2'} absolute right-2`} src="/icons/table/show_more_white.svg"/>
                </div>
              </td>
            ) : (
              <td className="rounded-r-full bg-primaryMultiply/90 min-w-[1.6rem]" />
            )
          }
        </tr>
      ))}
    </tbody>
  )
}