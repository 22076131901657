import { ILeadSocialOrigin } from "./lead"

export type ChatIntegrationType = "opaSuite" | "matrix" | "whatsappUnofficial"
| "huggy" | 'upChat' | 'blip' | 'multi360' //| "szChat"

export const chatIntegrationTypes: ChatIntegrationType[] = [
  "opaSuite", "matrix", "whatsappUnofficial", "huggy", "upChat", 'blip', 'multi360' //, "szChat"
]

export type ChatIntegrationSocialMatching = {
  keywords: string
  socialOrigin: ILeadSocialOrigin
}

export type ChatIntegrationWhatsappUnofficialModel = {
  socialMatching: ChatIntegrationSocialMatching[] // IChatIntegrationSocialMatching[]
}

export type ChatIntegrationOpaSuiteModel = {
  host: string
  token: string
  sellers: string[]
  webhookVerified: boolean
  webhookToken: string
}

export type ChatIntegrationMatrixModel = {
  host: string
  token: string
  sellers: string[]
}

export type ChatIntegrationHuggyModel = {
  token: string
  refreshToken: string
  tags: string[] // leadsChannels
  clientId: string
  clientSecret: string
  sellers: string[]
}

export type ChatIntegrationUpChatModel = {
  host: string
  token: string
  queues: number[] // departmentId
  departments: string[] // leadsChannels
}

export type ChatIntegrationMulti360Model = {
  email: string
  password: string
  sellers: string[]
}

export type ChatIntegration = {
  id: string
  provider: string
  name: string
  type: ChatIntegrationType
  whatsappUnofficial?: ChatIntegrationWhatsappUnofficialModel
  opaSuite?: ChatIntegrationOpaSuiteModel
  matrix?: ChatIntegrationMatrixModel
  huggy?: ChatIntegrationHuggyModel
  upChat?: ChatIntegrationUpChatModel
  multi360?: ChatIntegrationMulti360Model
  createdAt: Date
  updatedAt: Date
}