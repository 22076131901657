import { useEffect, useState } from "react"
import { AxiosError } from "axios"
import useAuth from "hooks/useAuth"
import { useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import { postLogin } from "services/authService"

type LoginFormInputs = {
  email: string
  password: string
}

export const useViewModel = () => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm<LoginFormInputs>()
  const { login, user } = useAuth()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (user !== undefined) {
      navigate("/404")
    }
  }, [])

  async function handleLogin({ email, password }: LoginFormInputs) {
    if (isLoading) return
    setIsLoading(true)
    try {
      const { data } = await postLogin(email, password)
      login(data.token, data.user)
      navigate("/")
    } catch(err) {
      const _err = err as AxiosError
      if (_err.response?.status === 400) {
        setError("email", { message: "Email ou senha incorreta" })
      } else {
        setError("email", { message: "Ocorreu um erro inesperado, por favor tente mais tarde" })
      }
    }
    setIsLoading(false)
  }

  return {
    register,
    errors,
    handleSubmit,
    handleLogin,
    isLoading
  }
}