import { useFilters } from "hooks/useFilters";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { updateSelectedDate, updateSelectedPeriod } from "store/slices/filters";
import { dateRangeFromPeriodAndSelectedDate, datesAreOnSameDay, isToday } from "utils/date";
import { DatePeriods, SelectedDate } from "utils/filters";

export const useViewModel = () => {

  const { selectedDate: initSelectedDate, selectedPeriod: initSelectedPeriod } = useFilters()
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState<SelectedDate|undefined>(initSelectedDate)
  const [dateFrame, setDateFrame] = useState<Date>(new Date())
  const periodRef = useRef<HTMLDivElement>(null)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState<DatePeriods>(initSelectedPeriod)

  useEffect(() => {
    if (selectedPeriod !== initSelectedPeriod) {
      setSelectedPeriod(initSelectedPeriod)
    }
  }, [initSelectedPeriod])

  function handleChangeDateFrame(changeToLeft: boolean) {
    const changeDirection = changeToLeft ? -1 : 1;
    setDateFrame(new Date(dateFrame.setMonth(dateFrame.getMonth() + changeDirection)))
  }

  function handleConfirmChanges() {
    if (!selectedDate?.endDate) {
      selectedDate!.endDate = new Date(selectedDate!.initDate)
    }
    dispatch(updateSelectedPeriod(selectedPeriod))
    dispatch(updateSelectedDate(JSON.stringify(selectedDate)))
    setShowDatePicker(false)
  }

  function handleSelectManualDate(date: Date) {
    if (date < new Date() || isToday(date)) {
      if (selectedDate?.endDate === undefined) {
        if (date >= selectedDate!.initDate) {
          setSelectedDate({initDate: selectedDate!.initDate, endDate: date})
        } else {
          setSelectedDate({initDate: selectedDate!.initDate, endDate: new Date(selectedDate!.initDate)})
        }
      } else {
        setSelectedDate({initDate: date, endDate: undefined})
      }
      setSelectedPeriod("custom")
    } else {
      if (selectedDate?.endDate === undefined) {
        setSelectedDate({initDate: selectedDate!.initDate, endDate: new Date()})
        setSelectedPeriod("custom")
      }
    }
  }

  useEffect(() => {
    handleCalculateDataFrame()
  }, [selectedPeriod])

  const handleCalculateDataFrame = (reset: boolean = false) => {
    const newDateRange = dateRangeFromPeriodAndSelectedDate(selectedPeriod, selectedDate, reset)

    if (newDateRange && newDateRange.endDate) {
      setDateFrame(new Date(newDateRange.endDate))
      setSelectedDate({initDate: new Date(newDateRange.initDate), endDate: new Date(newDateRange.endDate)})
    }
  }

  function handleSelectDateFrame(date: Date) {
    setDateFrame(date)
  }

  const handleToggleDatePicker = () => {
    setShowDatePicker(!showDatePicker)
    if (!showDatePicker) {
      handleCalculateDataFrame(true)
      setSelectedDate(initSelectedDate)
    }
  }

  const selectedDateRange = useMemo(() => {
    if (selectedDate) {
      if (!selectedDate.endDate)
        return selectedDate?.initDate.toLocaleDateString("pt-BR")
      if (datesAreOnSameDay(selectedDate.initDate, selectedDate.endDate))
        return selectedDate?.initDate.toLocaleDateString("pt-BR")
      return `${selectedDate?.initDate.toLocaleDateString("pt-BR")} às ${selectedDate?.endDate.toLocaleDateString("pt-BR")}`
    }
    return ''
  }, [selectedDate])

  return {
    dateFrame,
    selectedDate,
    selectedDateRange,
    periodRef,
    handleChangeDateFrame,
    handleConfirmChanges,
    handleSelectManualDate,
    handleSelectDateFrame,
    showDatePicker,
    handleToggleDatePicker
  }
}