import { FormColumn, FormRow } from "components/Forms/Form"
import { MaskedTextField } from "components/Forms/MaskedTextField"
import { StatusSelect } from "components/Forms/StatusSelect"
import { TextField } from "components/Forms/TextField"
import { Provider } from "models/provider"
import { useState } from "react"
import { Masks } from "utils/mask"

type ShowFormProps = Provider

export const ShowForm = ({ email, name, whatsapp, status, client, responsible }: ShowFormProps) => {

  const [whatsappPhone, setWhatsappPhone] = useState(whatsapp)

  return (
    <FormRow className="mt-10">
      <FormColumn>
        <TextField 
          label="Provedor"
          disabled
          value={name}
        />
        <TextField 
          label="Cliente"
          disabled
          value={client}
        />
      </FormColumn>
      <FormColumn>
        <MaskedTextField 
          label="WhatsApp"
          type="phone"
          mask={Masks.PHONE}
          disabled
          value={whatsappPhone}
          onChange={setWhatsappPhone}
        />
        <TextField 
          label="Email"
          type="email"
          disabled
          value={email}
        />
      </FormColumn>
      <FormColumn>
        <TextField 
          label="Responsável"
          disabled
          value={responsible}
        />
        <StatusSelect
          label="Status"
          selected={String(status)}
          disabled
          options={[
            {label: "Ativo", value: "0"},
            {label: "Pausa", value: "1"},
            {label: "Inativo", value: "2"}
          ]}
        />
      </FormColumn>
    </FormRow>
  )
}