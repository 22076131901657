import { useNavigate, useParams } from "react-router-dom"

import { Seller } from "models/seller"
import { getSeller, updateSeller } from "services/sellerService"
import { FormStates } from "components/Forms/FormStates"
import { SellerForm } from "../form"
import { FormFields } from "../form/viewModel"
import { toast } from "react-toastify"
import { queryClient } from "services/queryClient"
import { SELLER_CLIENTS_QUERY_KEY } from "../viewModel"
import { PageHeader } from "components/Page/PageHeader"
import { useQuery } from "react-query"

type QueryParams = {
  id: string
}

export function EditSeller() {
  
  const { id } = useParams<QueryParams>()
  const navigate = useNavigate()

  const { data, isLoading, isError, refetch } = useQuery(
    [SELLER_CLIENTS_QUERY_KEY, 'form', id],
    () => getSeller(id!),
    { enabled: !!id }
  )

  async function handleSubmit(fields: FormFields) {
    toast("Atualizando dados do vendedor...", { type: "info" })
    await updateSeller({ ...fields, id: id! })
    queryClient.invalidateQueries(SELLER_CLIENTS_QUERY_KEY)
    toast("Vendedor cadastrado com sucesso!", { type: "success" })
    navigate(`/vendedores/${id!}`)
  }

  return (
    <div>
      <PageHeader
        title="Editar Vendedor"
        backPath="/vendedores"
        action={{ label: "Visualizar", onClick: () => navigate(`/vendedores/${id!}`) }}
      />
      <FormStates 
        isLoading={isLoading}
        hasError={isError}
        onRetry={refetch}
      />
      { data && (
        <SellerForm 
          enabled
          seller={data}
          onSubmit={handleSubmit}
          action="Editar"
        />
      )}
    </div>
  )
}