import { BoardTag } from "./board"
import { ChatIntegrationType } from "./chatIntegration"
import { APISeller, Seller } from "./seller"

export type ILeadSocialOrigin = "facebook" | "google" | "organic" | "reactivation" | "unknown"
export type ILeadChannel = "whatsapp" | "pabx" | "telegram" | "web_chat"
| "instagram_direct" | "facebook_messenger" | "other" | "google_ads"
export type ILeadDocumentType = "document" | "phone" | "id"

export const socialOrigins = [
  { value: "facebook", label: "Facebook/Instagram" },
  { value: "google", label: "Google" },
  { value: "organic", label: "Orgânico" },
  { value: "reactivation", label: "Reativação" },
  { value: "unknown", label: "Não atribuído" },
]

export const midiaSocialOrigins = [
  { value: "facebook", label: "Facebook/Instagram" },
  { value: "google", label: "Google" },
]

export type APILeadCard = Omit<LeadCard, 'id' | 'seller'> & {
  _id: string
  seller: APISeller | null
}

type LeadIntegrationType = ChatIntegrationType | "google_sheets"

export type ILeadContantType = "phone" | "email" | "unknown"

export type Lead = {
  id: string
  name: string
  providerId: string
  document: string
  documentType: ILeadDocumentType
  origin: LeadIntegrationType
  integrationId: string
  socialOrigin: ILeadSocialOrigin
  channel: ILeadChannel
  date: Date
  externalId: string
  status: string
  position: number
  contact?: string
  altPhone?: string
  seller: Seller | null
  movedAt?: string
  contactType: ILeadContantType
  selledAt?: string | null
  provider: {
    _id: string
    leadsStatus: Array<{
      id: string
      name: string
      tag?: BoardTag
    }>
    user: {
      name: string
    }
  }
  campaign?: {
    id?: string
    title?: string
    url?: string
  }
}

export type LeadCard = Omit<Lead, 'provider'> & {
  provider: string
}

export type StoreLeadCard = Omit<LeadCard, 'date'> & {
  date: string
}

export const leadToStoreLeadCard = (lead: Lead): StoreLeadCard => {
  return {
    ...lead,
    provider: lead.provider._id,
    date: lead.date.toISOString()
  }
}

export const integrationToText = (status: string) => {
  switch (status as LeadIntegrationType) {
    case 'opaSuite':
      return "Opa Suite"
    // case 'szChat':
    //   return "SZ Chat"
    case 'matrix':
      return "Matrix"
    case 'whatsappUnofficial':
      return "Whatsapp"
    case 'google_sheets':
      return "Google Planilhas"
    default:
      return ""
  }
}

export const channelToText = (status: ILeadChannel) => {
  switch (status) {
    case "whatsapp":
      return "Whatsapp"
    case "pabx":
      return "PABX"
    case "facebook_messenger":
      return "Facebook Messenger"
    case "telegram":
      return "Telegram"
    case "web_chat":
      return "Web Chat"
    case "instagram_direct":
      return "Instagram Direct"
    case 'google_ads':
      return "Google Ads"
    case "other":
      return "Outro"
    default:
  }
}