import { HTMLInputTypeAttribute } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

type AuthInputProps = {
  id?: string
  type?: HTMLInputTypeAttribute
  label: string
  placeholder: string
  leftIcon?: string
  error?: string
  className?: string
  register: UseFormRegisterReturn
}

export function AuthInput({ 
  id, type, label, placeholder, error, className, register, leftIcon
}: AuthInputProps) {

  return (
    <div className={`${className} text-left`}>
      <label className="text-white pl-2.5 text-xs" htmlFor={id}>{label}</label>
      <div className="flex px-4 bg-gradient-to-r from-primary to-secondary items-center gap-1.5 rounded-full mt-0.5">
        {leftIcon && <img src={leftIcon} />}
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          className="bg-transparent text-white focus:outline-none focus:border-white placeholder:text-white/50 py-2 text-xs w-full"
          {...register}
        />
      </div>
      { error && <span className="px-2.5 mt-3 text-xs text-inactive">{error}</span> }
    </div>
  )
}