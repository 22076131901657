import { useEffect, useState } from "react"
import { AxiosError } from "axios"
import useAuth from "hooks/useAuth"
import { useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import { postConfirmRecover, postLogin } from "services/authService"
import { useQueryParams } from "hooks/useQueryParams"
import { toast } from "react-toastify"

type LoginFormInputs = {
  password: string
  passwordConfirm: string
}

export const useViewModel = () => {
  const { register, handleSubmit, formState: { errors }, setError, watch } = useForm<LoginFormInputs>()
  const navigate = useNavigate()
  const { code } = useQueryParams<{ code: string }>()
  const [isLoading, setIsLoading] = useState(false)

  const password = watch("password")

  useEffect(() => {
    if (code === undefined) {
      navigate("/404")
    }
  }, [])

  async function handleConfirmRecover({ password }: LoginFormInputs) {
    if (isLoading) return
    setIsLoading(true)
    try {
      await postConfirmRecover(password, code!)
      toast.success("Senha alterada com sucesso")
      navigate("/login")
    } catch(err) {
      const _err = err as AxiosError
      if (_err.response?.status === 400) {
        setError("password", { message: "Código inválido" })
      } else {
        setError("password", { message: "Ocorreu um erro inesperado, por favor tente mais tarde" })
      }
    }
    setIsLoading(false)
  }

  return {
    register,
    errors,
    handleSubmit,
    handleConfirmRecover,
    password,
    isLoading
  }
}