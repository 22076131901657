export {}

declare global {
  interface Array<T> {
    findIndexOrUndefined(predicate: (value: T, index: number, obj: T[]) => boolean): number | undefined;
  }
}

if (!Array.prototype.findIndexOrUndefined) {
  Array.prototype.findIndexOrUndefined = function<T>(predicate: (value: T, index: number, obj: T[]) => boolean): number | undefined {
    const index = this.findIndex(predicate);
    return index === -1 ? undefined : index;
  }
}