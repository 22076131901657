import { useEffect, useMemo, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"

import { SheetColumn } from "models/integration"
import { AxiosError } from "axios"
import { messageToError, ValidationError } from "utils/validation"
import { toast } from "react-toastify"
import { getSheetsHeaders } from "services/sheetIntegrationService"
import { SaveOrUpdateSheetIntegrationFunction } from "../viewModel"

export type SheetIntegrationForm = {
  name: string
  sheetId: string
  columns: SheetColumn[]
}

const leadsFields = [
  { value: "name", label: "Nome", required: false },
  { value: "phone", label: "Telefone", required: true },
  { value: "email", label: "E-mail", required: true },
  { value: "document", label: "CPF/CNPJ", required: false },
  { value: "date", label: "Data de entrada", required: false },
]

export const useViewModel = (selectedIntegration: SheetIntegrationForm | undefined, handleSaveOrUpdate: SaveOrUpdateSheetIntegrationFunction) => {
  const {
    register, control, formState: { errors }, setValue, handleSubmit, setError, watch
  } = useForm<SheetIntegrationForm>({
    defaultValues: {
      name: "",
      sheetId: "",
      columns: []
    }
  })

  const { append: appendColumn, update: updateColumn, remove: removeColumn } = useFieldArray({
    name: "columns",
    control
  })

  const columnsList = watch("columns") ?? [] as SheetColumn[]

  const [step, setStep] = useState(0)
  const [loadingForm, setLoadingForm] = useState(false)
  const [headers, setHeaders] = useState<string[]>([])

  const headersOptions = useMemo(() => {
    return headers.map((header, index) => ({ value: String(index), label: header }))
  }, [headers])

  useEffect(() => {
    if (selectedIntegration) {
      setValue("name", selectedIntegration?.name ?? "")
      setValue("sheetId", selectedIntegration?.sheetId ?? "")
      setValue("columns", selectedIntegration?.columns ?? [])
    }
  }, [selectedIntegration])

  async function handleSubmitFormFirstStep(data: SheetIntegrationForm) {
    if (loadingForm) return
    setLoadingForm(true)

    try {
      toast.info("Buscando os dados da planilha...")
      const { data: headers } = await getSheetsHeaders(data.sheetId)
      setHeaders([
        "Não associar",
        ...headers
      ])
      setLoadingForm(false)
      setStep(1)
      toast.success("Dados buscados com sucesso!")
    } catch (error) {
      toast.error(errorMessage(error as Error))
      setLoadingForm(false)
    }
  }

  const errorMessage = (error: Error) => {
    const _err = error as AxiosError
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as { errors: ValidationError[] | undefined }

      if (data.errors) {
        const fields = data.errors.map(error => {
          setError(error.key as keyof SheetIntegrationForm, { message: messageToError(error.message) })
          return error.key
        })
        return `Os campos ${fields.join(", ")} são inválidos`
      }
      if (_err.response.status === 400) return "Não foi possível buscar os dados da planilha"
    }
    return "Ocorreu um erro, por favor tente novamente"
  }

  async function handleSubmitFormSecondStep(data: SheetIntegrationForm) {
    if (loadingForm) return
    if (data.columns.length === 0) return toast("Você precisa associar pelo menos um campo", { type: "error" })
    const doesntHavePhone = !data.columns.some(column => column.field === 'phone')
    const doesntHaveEmail = !data.columns.some(column => column.field === 'email')
    if (doesntHavePhone && doesntHaveEmail) return toast("O campo de telefone ou e-mail é obrigatório", { type: "error" })
    
    setLoadingForm(true)
    await handleSaveOrUpdate(data)
    setLoadingForm(false)
  }

  const handleChangeHeaderAssociation = (index: number, value: number) => {
    const existIndex = columnsList.findIndex(column => column.field === leadsFields[index].value)
    const alreadyExists = existIndex !== -1

    if (alreadyExists) {
      if (value === 0) return removeColumn(existIndex)

      updateColumn(existIndex, { field: leadsFields[index].value, sheetColumn: headers[value] })
      return
    }

    appendColumn({ field: leadsFields[index].value, sheetColumn: headers[value] })
  }

  return {
    selectedIntegration,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    step,
    headers,
    handleChangeHeaderAssociation,
    leadsFields,
    columnsList,
    headersOptions
  }
}