import styles from './index.module.scss';

export type DotLoadingStyle = "default" | "primary"

type DotLoadingProps = {
  style?: DotLoadingStyle
  className?: string
}

export function DotLoading({ style = "primary", className }: DotLoadingProps) {
  return (
    <div className={`
      ${className}
      ${styles.dotLoading}
      ${style === "primary"
        ? styles.dotLoadingPrimary
        : styles.dotLoadingDefault
      }
    `}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}