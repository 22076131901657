import { Button } from "components/Button";

export type ConfirmDialogProps = {
  show: boolean
  title: string
  message: string
  onConfirm: () => void
  onCancel: () => void
  isLoading?: boolean
}

export function ConfirmDialog({ show, title, message, onConfirm, onCancel, isLoading }: ConfirmDialogProps) {
  if (!show) {
    return <></>
  }

  return (
    <div className="w-screen h-screen fixed left-0 top-0 z-50 flex items-center justify-center">
      <div className="w-screen h-screen fixed z-40 bg-black/30 left-0 top-0" onClick={onCancel} />
      <div className="bg-primaryMultiply z-50 px-7 py-8 rounded-2xl">
        <p className="text-base">{title}</p>
        <p className="text-sm my-4 font-input">{message}</p>
        <div className="flex justify-end w-96 mt-4 gap-8">
          <Button theme="cancel" onClick={onCancel}>Cancelar</Button>
          <Button theme="highlight" onClick={onConfirm} loading={isLoading}>Confirmar</Button>
        </div>
      </div>
    </div>
  );
}