import { SellerForm } from "../form"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { FormFields } from "../form/viewModel"
import { addSeller } from "services/sellerService"
import { queryClient } from "services/queryClient"
import { SELLER_CLIENTS_QUERY_KEY } from "../viewModel"
import { PageHeader } from "components/Page/PageHeader"

export function NewSeller() {

  const navigate = useNavigate()

  async function handleSubmit(fields: FormFields) {
    toast("Adicionando dados do vendedor...", { type: "info" })
    const id = await addSeller({ ...fields, password: fields.password! })
    queryClient.invalidateQueries(SELLER_CLIENTS_QUERY_KEY)
    toast("Vendedor cadastrado com sucesso!", { type: "success" })
    navigate(`/vendedores/${id}`)
  }

  return (
    <section>
      <PageHeader 
        title="Novo Vendedor"
        backPath="/vendedores"
      />
      <SellerForm 
        enabled
        seller={undefined}
        onSubmit={handleSubmit}
        action="Cadastrar"
      />
    </section>
  )
}