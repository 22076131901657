import { CampaignType } from "models/campaign"
import { Integration } from "models/integration"

import { Pagination } from "utils/pagination"

import { api } from "./api"

type CreateIntegration = {
  name: string
  type: CampaignType
  adsId?: string
  token?: string
  sheetId?: string
  provider: string
}

type UpdateIntegration = CreateIntegration & {
  id: string
}

export const getIntegrations = async (clientId: string): Promise<Pagination<Integration>> => {
  const { data } = await api.get<Pagination<Integration>>(`v1/integrations?providerId=${clientId}`)
  return data
}

export const createIntegration = async (integration: CreateIntegration): Promise<Integration> => {
  const { data } = await api.post<Integration>("v1/integrations", integration)
  return data
}

export const updateIntegration = async (integration: UpdateIntegration): Promise<Integration> => {
  const { data } = await api.put<Integration>(`v1/integrations/${integration.id}`, integration)
  return data
}

export const deleteIntegration = async (id: string): Promise<void> => {
  await api.delete(`v1/integrations/${id}`)
}
