import { toCurrency } from "utils/format"
import { cn } from "utils/styles"

type Props = {
  variant?: 'primary' | 'secondary'
  mrrPeriod: number
  mrrHistoric: number
}

export const MRRCard = ({ variant = 'primary', mrrPeriod, mrrHistoric }: Props) => {
  return (
    <div className={cn(
      "rounded-2xl flex flex-col items-center justify-center gap-2 min-h-[7rem] laptop:flex-1",
      variant === 'primary' ? 'bg-primaryMultiply/70 px-4' : 'border border-white/30 py-2 px-2 desktop-lg:px-4'
    )}>
      <div className="flex items-center gap-2.5">
        <img className={cn(variant === 'primary' ? '' : 'h-3.5')} src="/icons/campaigns/cart.svg" alt="" />
        <p className={cn(variant === 'primary' ? '' : 'text-xs')}>MRR</p>
      </div>
      <div className="font-input">
        <p className={cn(
          "font-bold text-center",
          variant === 'primary' ? 'text-2xl' : 'text-xl'
        )}>{toCurrency(mrrPeriod)}</p>
        <p className="text-secondary text-xs text-center">{toCurrency(mrrHistoric)}/histórico</p>
      </div>
    </div>
  )
}