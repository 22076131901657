export type PaginationItemArrowDirection =  "left" | "right"

type PaginationItemArrowProps = {
  currentPage: number
  direction: PaginationItemArrowDirection
  enabled: boolean
  handleChangePage: (page: number) => void
  size?: 'small' | 'default'
}

export function PaginationItemArrow({
  currentPage,
  direction,
  enabled,
  handleChangePage,
  size = 'default'
}: PaginationItemArrowProps) {

  function handleChangePageClick() {
    if (enabled) {
      handleChangePage(direction === "left" ? currentPage - 1 : currentPage + 1)
    }
  }

  return (
    <button
      onClick={handleChangePageClick}
      disabled={!enabled}
      className={`${size === 'default' ? 'h-10' : 'h-8'} w-10 flex items-center justify-center rounded-lg bg-secondary disabled:opacity-50`}
    >
      <img
        src={`/icons/table/arrow_${direction}.svg`}
        alt={direction === "left" ? "Página anterior" : "Próxima página"}
      />
    </button>
  )
}