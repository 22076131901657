import { SheetIntegration } from "models/integration"
import { SaveOrUpdateSheetIntegrationFunction } from "../viewModel"
import { TextField } from "components/Forms/TextField"
import { useViewModel } from "./viewModel"
import { Button } from "components/Button"
import { Select } from "components/Forms/Select"
import { CopyText } from "components/Forms/CopyText"

type Props = {
  selectedIntegrationObject: SheetIntegration | undefined
  onClose: () => void
  onSaveOrUpdate: SaveOrUpdateSheetIntegrationFunction
}
export const SheetIntegrationsForm = ({ selectedIntegrationObject, onSaveOrUpdate, onClose }: Props) => {
  
  const viewModel = useViewModel(selectedIntegrationObject, onSaveOrUpdate)

  return (
    <div>
      <div 
        onClick={onClose}
        className="modalBackground"
      />
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-md py-8 px-12 rounded-xl w-96 z-50 bg-modal flex flex-col gap-5">
        <a
          href="https://www.loom.com/share/ddcfa02f91474305a6e82df2757ce38a"
          className="absolute right-12"
          target='_blank'
          rel='noreferrer'
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#F15A22" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
          </svg>
        </a>
        {
          viewModel.step === 0 && (
            <form onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)} className="flex flex-col gap-5">
              <CopyText
                label="E-mail de integração"
                text="delipe-sheets@glaper-afiliado.iam.gserviceaccount.com"
                variant="secondary"
              />
              <TextField
                label='Nome da integração'
                placeholder='Digite o nome da integração'
                error={viewModel.errors.name?.message}
                register={viewModel.register("name", {
                  required: { value: true, message: "O nome é obrigatório" }
                })}
                variant="secondary"
              />
              <TextField
                label='Identificador da planilha'
                placeholder='Digite o identificador da planilha'
                error={viewModel.errors.sheetId?.message}
                register={viewModel.register("sheetId", {
                  required: { value: true, message: "O id da planilha é obrigatório" }
                })}
                variant="secondary"
              />
              <div className="modalButtons">
                <Button onClick={onClose} theme="cancel">
                  Cancelar
                </Button>
                <Button loading={viewModel.loadingForm} theme="highlight" className="px-7">
                  Salvar
                </Button>
              </div>
            </form>
          )
        }
        {
          viewModel.step === 1 && (
            <form onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)} className="flex flex-col gap-5">
              {
                viewModel.leadsFields.map((field, index) => {
                  const fieldMatch = viewModel.columnsList.find(column => column.field === field.value)
                  const selectedColumn = viewModel.headersOptions.find(column => column.label === fieldMatch?.sheetColumn)?.value

                  return (
                    <Select
                      key={index}
                      label={`${field.label} ${field.required ? '(obrigatório)' : ''}`}
                      onChange={(value) => viewModel.handleChangeHeaderAssociation(index, Number(value))}
                      options={viewModel.headersOptions}
                      selected={String(selectedColumn ?? 0)}
                      invertOptionsBox={index === viewModel.leadsFields.length - 1}
                      variant="secondary"
                    />
                  )
                })
              }
              <div className="modalButtons">
                <Button onClick={onClose} theme="cancel">
                  Cancelar
                </Button>
                <Button loading={viewModel.loadingForm} theme="highlight" className="px-5">
                  Salvar
                </Button>
              </div>
            </form>
          )
        }
      </div>
    </div>
  )
}