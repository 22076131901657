import { PROVIDER_STATUS, statusToText } from "models/provider"

import { Pagination } from "components/Tables/Pagination"
import { Table } from "components/Tables/Table"
import { ConfirmDialog } from "components/ConfirmDialog"

import { useViewModel } from "./viewModel"
import { Masks, maskText } from "utils/mask"
import { PageHeader } from "components/Page/PageHeader"

export default function Clients() {

  const { 
    navigate, providers, currentPage, numberOfPages, confirmDialogData,
    handleChangeStatus, setCurrentPage, isLoading, isError, retry
  } = useViewModel()

  return (
    <div>
      { confirmDialogData && <ConfirmDialog {...confirmDialogData} /> }
      <PageHeader 
        title="Clientes"
        action={{ label: "Adicionar", leftIcon: "/icons/form/add.svg", onClick: () => navigate(`/clientes/novo`) }}
      />
      <Table
        className="mt-7"
        headers={["Provedor", "Cliente", "Whatsapp", "Responsável", "Status"]}
        fields={[{key: "name"}, {key: "client"}, {key: "whatsapp"}, {key: "responsible"}, {key: "status", type: "status"}]}
        data={providers}
        transforms={[
          { key: "status", transform: (provider) => statusToText(provider.status) },
          { key: "whatsapp", transform: provider => maskText(provider.whatsapp, Masks.PHONE, provider.whatsapp.length) }
        ]}
        overrides={[{ key: "responsible", override: (index) => <span className="w-full text-center desktop:text-left">{providers![index].responsible ?? "-"}</span> }]}
        isLoading={isLoading}
        hasError={isError}
        onRetry={retry}
        actions={(provider, index) => {
          return (
            <>
              <button onClick={() => navigate(`/clientes/${provider.id}`)}>Ver mais</button>
              <button onClick={() => navigate(`/clientes/editar/${provider.id}`)}>Editar</button>
              { provider.status !== PROVIDER_STATUS.Ativo && (
                <button onClick={() => handleChangeStatus(index, PROVIDER_STATUS.Ativo)}>Ativar</button>
              )}
              { provider.status !== PROVIDER_STATUS.Inativo && (
                <button onClick={() => handleChangeStatus(index, PROVIDER_STATUS.Inativo)}>Inativar</button>
              )}
              { provider.status !== PROVIDER_STATUS.Pausado && (
                <button onClick={() => handleChangeStatus(index, PROVIDER_STATUS.Pausado)}>Pausar</button>
              )}
            </>
          )
        }}
      />      
      <Pagination 
        currentPage={currentPage}
        handleChangePage={setCurrentPage}
        numberOfPages={numberOfPages ?? 0}
      />
    </div>
  )
}