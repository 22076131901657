import { Button } from "components/Button"
import { DotLoading } from "components/DotLoading"

type FormStatesProps = {
  isLoading: boolean
  hasError: boolean
  onRetry: () => void
}

export const FormStates = ({ isLoading, hasError, onRetry }: FormStatesProps) => {

  if (!isLoading && !hasError) {
    return <></>
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center flex-col mt-16 gap-4">
        <p>Buscando informações...</p>
        <DotLoading/>
      </div>
    )
  }

  return (
    <div className="flex flex-col justify-center items-center mt-16 gap-4">
      <p>Ocorreu um erro ao buscar as informações</p>
      <Button onClick={onRetry}>
        Tentar novamente
      </Button>
    </div>
  )
}