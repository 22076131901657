import { useViewModel } from "./viewModel"

import { LeadsBoard } from "./components"

export const LeadsSalesFunnel = () => {

  const viewModel = useViewModel()

  if (viewModel.selectedProvider === 'admin') {
    return (
      <div className="flex flex-col px-8 tablet:px-18 h-[calc(70svh)] items-center justify-center">
        <p className="text-xl text-center text-center">Selecione um provedor, para ver o seu funil de leads</p>
      </div>
    )
  }

  return (
    <LeadsBoard />
  )
}