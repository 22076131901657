import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

import { queryClient } from "services/queryClient"

import { ProviderForm } from "../form"
import { FormFields } from "../form/viewModel"

import { PROVIDER_CLIENTS_QUERY_KEY } from "../viewModel"
import { addProvider } from "services/providerService"
import { PageHeader } from "components/Page/PageHeader"
import { PROVIDER_SELECT_QUERY } from "components/Page/PageFilter/ProviderFilter"

export function NewClient() {

  const navigate = useNavigate()

  async function handleSubmit(fields: FormFields) {
    toast("Adicionando cliente...", { type: "info" })
    const id = await addProvider(fields)
    queryClient.invalidateQueries(PROVIDER_CLIENTS_QUERY_KEY)
    queryClient.invalidateQueries(PROVIDER_SELECT_QUERY)
    toast("Cliente cadastrado com sucesso!", { type: "success" })
    navigate(`/clientes/${id}`)
  }

  return (
    <section>
      <PageHeader 
        title="Novo Cliente"
        backPath="/clientes"
      />
      <ProviderForm 
        enabled
        provider={undefined}
        onSubmit={handleSubmit}
        action="Adicionar"
      />
    </section>
  )
}