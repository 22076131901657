import { Provider } from "models/provider"

import { Button } from "components/Button"
import { FormColumn, FormRow, Form } from "components/Forms/Form"
import { MaskedTextField } from "components/Forms/MaskedTextField"
import { TextField } from "components/Forms/TextField"

import { Masks } from "utils/mask"

import { FormFields, useViewModel } from "./viewModel"
import { StatusSelect } from "components/Forms/StatusSelect"
import { MoneyTextField } from "components/Forms/MoneyTextField"

type ProviderFormProps = {
  provider?: Provider
  onSubmit?: (fields: FormFields) => Promise<void>
  enabled: boolean
  action?: string
}

export function ProviderForm({ provider, enabled, action, onSubmit }: ProviderFormProps) {

  const {
    handleSubmit, errors, register, whatsapp, date, setValue, loading, status, averageTicket
  } = useViewModel(provider, onSubmit)

  return (
    <Form onSubmit={handleSubmit} className="mt-12 tablet:mt-24">
      <FormRow>
        <FormColumn>
          <TextField 
            label="Nome do provedor"
            placeholder="Digite o nome do provedor"
            error={errors.name?.message}
            type="text"
            disabled={!enabled}
            register={register("name", {
              required: { value: true, message: "O nome do provedor é obrigatório" }
            })}
          />
          <TextField 
            label="Nome do cliente"
            placeholder="Digite o nome do cliente"
            error={errors.client?.message}
            disabled={!enabled}
            register={register("client", {
              required: { value: true, message: "O nome do cliente é obrigatório" }
            })}
          />
          <MaskedTextField 
            label="WhatsApp do cliente"
            placeholder="(00) 90000-0000"
            type="phone"
            error={errors.whatsapp?.message}
            mask={Masks.PHONE}
            value={whatsapp}
            disabled={!enabled}
            onChange={(value) => {setValue("whatsapp", value)}}
          />
          <TextField 
            label="Email"
            type="email"
            placeholder="Digite o email do login"
            error={errors.email?.message}
            disabled={!enabled}
            register={register("email", {
              required: { value: true, message: "O nome da empresa é obrigatório" },
              pattern: { value: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: "O email não é válido" }
            })}
          />
        </FormColumn>
        <FormColumn>
          {
            provider === undefined && (
              <TextField 
                label="Senha"
                type="password"
                placeholder="Digite o senha do login"
                error={errors.password?.message}
                disabled={!enabled}
                register={register("password", {
                  required: { value: true, message: "O nome da empresa é obrigatório" },
                  minLength: { value: 6, message: "A senha deve ter no mínimo 6 caracteres" }
                })}
              />
            )
          }
          <MaskedTextField 
            label="Data do cadastro"
            placeholder="DD/MM/AAAA"
            type="text"
            disabled={!enabled}
            error={errors.date?.message}
            mask={Masks.DATE}
            value={date}
            onChange={(value) => {setValue("date", value)}}
          />
          <TextField 
            label="Responsável"
            placeholder="Digite o responsável na Delipe"
            error={errors.responsible?.message}
            disabled={!enabled}
            register={register("responsible", {
              required: { value: true, message: "O responsável na Delipe é obrigatório" }
            })}
          />
          <StatusSelect
            label="Status"
            selected={String(status)}
            disabled={!enabled}
            options={[
              {label: "Ativo", value: "0"},
              {label: "Pausa", value: "1"},
              {label: "Inativo", value: "2"}
            ]}
            onChange={(value) => setValue("status", Number(value))}
          />
          <MoneyTextField 
            label="Ticket médio"
            placeholder="0,00"
            type="text"
            disabled={!enabled}
            error={errors.averageTicket?.message}
            value={averageTicket}
            onChange={(value) => {setValue("averageTicket", value)}}
          />
        </FormColumn>
      </FormRow>
      { action && (
        <Button
          type="submit" 
          loading={loading}
          className="w-48 mt-20"
        >
          {action}
        </Button>
      ) }
    </Form>
  )
}