import { InfoCardProps } from "components/InfoCard"
import { useFilters } from "hooks/useFilters"
import { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { CampaignCounters, getCampaignsResume } from "services/campaignService"
import { isDifferentCounters } from "utils/counters"

export const useViewModel = () => {

  const navigate = useNavigate()
  const { selectedDate } = useFilters()
  const [campaignsPage, setCampaignsPage] = useState(1)
  const [counters, setCounters] = useState<CampaignCounters>()

  const range = `${selectedDate?.initDate.toDateString()}-${selectedDate?.endDate.toDateString()}`

  const {
    data: campaigns, isLoading: isLoadingPage, isError: isErrorPage, refetch: retryPage
  } = useQuery(
    ["campaigns", campaignsPage, range],
    () => getCampaignsResume(selectedDate!.initDate, selectedDate!.endDate),
    {
      refetchOnWindowFocus: true
    }
  )

  const infoCards: InfoCardProps[] = useMemo(() => {
    return [
      { type: 'visits', value: counters?.visits },
      { type: 'leads', value: counters?.leads },
      { type: 'invested', value: counters?.invested },
    ] as InfoCardProps[]
  }, [counters])

  function handleChangePage(page: number) {
    setCampaignsPage(page)
  }

  useEffect(() => {
    if (campaigns?.counters && (!counters || isDifferentCounters(campaigns.counters, counters))) {
      setCounters(campaigns?.counters)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns?.counters])

  const handleRetry = () => retryPage()

  return {
    campaignsPage: campaignsPage,
    handleChangePage: handleChangePage,
    campaigns: campaigns,
    isLoadingPage: isLoadingPage,
    isErrorPage: isErrorPage,
    retryPage: handleRetry,
    navigate: navigate,
    infoCards
  }
}