import { toPercent } from "utils/format"
import { cn } from "utils/styles"

type Props = {
  variant?: 'primary' | 'secondary'
  convertion: number
}

export const ConvertionCard = ({ variant = 'primary', convertion }: Props) => {

  return (
    <div className={cn(
      "rounded-2xl flex flex-col items-center justify-center gap-2  min-h-[7rem] laptop:flex-1 desktop:flex-none desktop-xl:flex-1",
      variant === 'primary' ? 'bg-primaryMultiply/70 px-8 desktop:px-4' : 'border border-white/30 py-2 px-2 desktop-lg:px-4'
    )}>
      <div className="flex items-center gap-2 text-center">
        <img className={cn(variant === 'primary' ? '' : 'h-3.5')} src="/icons/campaigns/cart.svg" alt="" />
        <p className={cn(variant === 'primary' ? '' : 'text-xs')}>Taxa de Conversão</p>
      </div>
      <div className="font-input">
        <p className={cn(
          "font-bold text-center",
          variant === 'primary' ? 'text-2xl' : 'text-xl'
        )}>{toPercent(convertion)}</p>
      </div>
    </div>
  )
}
