import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { Seller } from "models/seller"
import { getAllProviders } from "services/providerService"
import { useQuery } from "react-query"
import useAuth from "hooks/useAuth"
import { PROVIDER_SELECT_QUERY } from "components/Page/PageFilter/ProviderFilter"

export type FormFields = {
  id: string
  email: string
  name: string
  password?: string
  providerId: string
  providerName: string
  status: number
}

export const useViewModel = (seller: Seller | undefined, onSubmit: ((fields: FormFields) => Promise<void>) | undefined) => {
  
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()
  const { register, formState: { errors }, handleSubmit, setValue, watch } = useForm<FormFields>()
  const { data: providers } = useQuery(PROVIDER_SELECT_QUERY, getAllProviders, { 
    enabled: user?.role === "admin",
    refetchInterval: false,
    staleTime: Infinity
  })

  const status = watch("status")
  const selectedProviderId = watch("providerId")

  useEffect(() => {
    if (seller) {
      setValue("name", seller.name)
      setValue("email", seller.email)
      setValue("status", seller.status)
      setValue("providerId", seller.providerId)
    } else {
      setValue("status", 0)
      if (user && user.role === 'provider') {
        setValue("providerId", user.providerId!)
      }
    }
  }, [seller, setValue, user?._id])

  useEffect(() => {
    register("providerId", {
      required: { value: true, message: "O provedor é obrigatório" }
    })
  }, [register])

  const handleChangeProvider = (value: string | undefined) => {
    setValue("providerId", value || "")
  }

  async function handleOnSubmit(fields: FormFields) {
    if (onSubmit) {
      setLoading(true)
      try {
        await onSubmit({
          ...fields,
          status: parseInt(fields.status.toString())
        })
      } catch(err) {
        const _err = err as AxiosError
        if (_err.isAxiosError) {
          if (_err.response?.status === 400) {
            toast.error("E-mail já está em uso")
            return
          }
        }
        toast("Ocorreu um erro ao salvar o vendedor", { type: "error" })
      } finally {
        setLoading(false)
      }
    }
  }

  return {
    loading,
    register,
    errors,
    handleSubmit: handleSubmit(handleOnSubmit),
    selectedProviderId,
    status,
    setValue,
    providers,
    handleChangeProvider,
    user
  }
}