import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

import { IState } from "store/index"
import { getUser } from "services/authService";
import { TokenCookie } from "cookies/authCookie";
import { onLogin, onLogout, setIsLoadingUser, setSelectedProvider } from "store/slices/auth";
import { IAuthUser } from "models/authUser";
import { useNavigate } from "react-router-dom";
import { Log } from "utils/log";

export const SELLER_CLIENTS_QUERY_KEY = "sellers"

const AuthCache: Map<string, boolean> = new Map()

export default function useAuth() {
  const { user, isLoading, selectedProvider, sellers, selectedSeller } = useSelector((state: IState) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleGetUser = () => {
    const token = TokenCookie.get()
    if (token) {
      const isLoadingUser = AuthCache.get(token)
      
      if (isLoadingUser) return

      AuthCache.set(token, true)

      getUser()
        .then(({ data }) => {
          handleOnLogin(data)
        }).catch(() => {
          TokenCookie.remove()
          navigate("/login")
          dispatch(setIsLoadingUser(false))
        })
      return
    }
    
    navigate("/login")
  }

  const login = (token: string, user: IAuthUser) => {
    TokenCookie.set(token)
    handleOnLogin(user)
  }

  const handleOnLogin = (user: IAuthUser) => {
    try {
      Sentry.setUser({
        email: user.email,
        id: user._id,
        username: user.name
      })
    } catch (error) {
      Log.error("Error on Sentry setUser", error as Error)
    }
    dispatch(onLogin(user))
    dispatch(setIsLoadingUser(false))
    dispatch(setSelectedProvider(user.providerId ? user.providerId : "admin"))
  }

  const logout = () => {
    Sentry.setUser(null)
    TokenCookie.remove()
    dispatch(onLogout())
  }

  const handleSelectProvider = (provider: string) => {
    dispatch(setSelectedProvider(provider))
  }

  return {
    user, login, logout, isLoading, handleGetUser,
    selectedProvider, handleSelectProvider, sellers,
    selectedSeller
  };
}