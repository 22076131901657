import { useEffect, useState } from "react"
import { useQuery } from "react-query"

import { queryClient } from "services/queryClient"

import { ChatIntegration, ChatIntegrationType, chatIntegrationTypes } from "models/chatIntegration"
import { AxiosError } from "axios"
import { ValidationError } from "utils/validation"
import { toast } from "react-toastify"
import { createChatIntegration, deleteChatIntegration, getChatIntegrations, updateChatIntegration } from "services/chatIntegrationService"
import { OpaIntegrationForm } from "./form/OpaSuiteForm/viewModel"
// import { SZChatIntegrationAPIForm } from "./form/SZChatForm/viewModel"
import { MatrixIntegrationForm } from "./form/MatrixChatForm/viewModel"
import { WhatsappIntegrationForm } from "./form/WhatsappForm/viewModel"
import { useQueryParams } from "hooks/useQueryParams"
import { UpChatIntegrationForm } from "./form/UpChatForm/viewModel"
import { BlipForm } from './form/BlipForm/viewModel'
import { Multi360IntegrationForm } from "./form/Multi360Form/viewModel"

const key = 'chatIntegrations'

type SaveOrUpdateData = OpaIntegrationForm //| SZChatIntegrationAPIForm
  | MatrixIntegrationForm | WhatsappIntegrationForm | UpChatIntegrationForm
  | BlipForm | Multi360IntegrationForm

export type SaveOrUpdateChatIntegrationFunction = (data: SaveOrUpdateData) => Promise<void>

export const useViewModel = (clientId: string) => {

  const { data: chatIntegrations, isLoading, isFetching, isError, refetch } = useQuery(
    [key, clientId],
    () => getChatIntegrations(clientId)
  )
  const { "new-integration": newIntegration } = useQueryParams<{ 'new-integration': string }>()
  
  const [showIntegrationModal, setShowIntegrationModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedIntegration, setSelectedIntegration] = useState<ChatIntegration>()
  const [isDeleting, setIsDeleting] = useState(false)
  const [initialIntegrationType, setInitialIntegrationType] = useState<ChatIntegrationType>("opaSuite")

  useEffect(() => {
    if (newIntegration && chatIntegrationTypes.includes(newIntegration as ChatIntegrationType)) {
      setInitialIntegrationType(newIntegration as ChatIntegrationType)
      handleOpenIntegrationModal()
    }
  }, [newIntegration])

  const handleSaveOrUpdateChatIntegration: SaveOrUpdateChatIntegrationFunction = async (data) => {
    try {
      if (selectedIntegration) {
        toast.info("Atualizando a integração...")

        await updateChatIntegration(selectedIntegration.id, {
          ...data,
          provider: clientId,
        })
      } else {
        toast.info("Criando a integração...")
        await createChatIntegration({
          ...data,
          provider: clientId,
        })
      }

      queryClient.invalidateQueries([key, clientId])
    
      toast("Integração salva com sucesso!", { type: "success" })
      handleCloseIntegrationModal()
    } catch(err) {
      const _err = err as AxiosError
      if (_err.isAxiosError && _err.response?.data !== undefined) {
        if (_err.response.status === 400) {
          const data = _err.response.data as { errors: ValidationError[] | undefined }

          if (data.errors) {
            const fields = data.errors.map(error => error.key)
            toast(`Os campos ${fields.join(", ")} não são válidos`, { type: "error" })
          } else {
            toast("Ocorreu um erro, por favor tente novamente", { type: "error" })
          }
        } else if (_err.response.status === 501) {
          toast("O domínio ou o token não são válidos", { type: "error" })
        } else {
          toast("Ocorreu um erro, por favor tente novamente", { type: "error" })  
        }
      } else {
        toast("Ocorreu um erro, por favor tente novamente", { type: "error" })
      }
    }
  }

  function handleAddIntegration() {
    setSelectedIntegration(undefined)
    handleOpenIntegrationModal()
  }

  function handleEditIntegration(integration: ChatIntegration) {
    setSelectedIntegration(integration)
    handleOpenIntegrationModal()
  }

  function handleOpenDeleteIntegrationModal(integration: ChatIntegration) {
    setSelectedIntegration(integration)
    setShowDeleteModal(true)
  }

  const handleOpenIntegrationModal = async () => {
    setShowIntegrationModal(true)
  }

  function handleCloseIntegrationModal() {
    setSelectedIntegration(undefined)
    setShowIntegrationModal(false)
    setInitialIntegrationType("opaSuite")
  }

  async function handleDeleteIntegration() {
    if (selectedIntegration && !isDeleting) {
      setIsDeleting(true)
      toast.info("Deletando a integração...")

      try {
        await deleteChatIntegration(selectedIntegration.id, clientId)
        queryClient.invalidateQueries([key, clientId])
        toast("Integração deletada com sucesso!", { type: "success" })
        setIsDeleting(false)
        handleCloseDeleteModal()
      } catch (error) {
        toast("Ocorreu um erro, por favor tente novamente", { type: "error" })
        setIsDeleting(false)
      }
    }
  }

  function handleCloseDeleteModal() {
    setSelectedIntegration(undefined)
    setShowDeleteModal(false)
  }
  
  return {
    chatIntegrations: chatIntegrations,
    refetch: refetch,
    showIntegrationModal,
    selectedIntegration,
    showDeleteModal,
    isLoading: isLoading || (isFetching && !chatIntegrations),
    isError,
    handleAddIntegration,
    handleEditIntegration,
    handleDeleteIntegration,
    handleCloseIntegrationModal,
    handleCloseDeleteModal,
    handleSaveOrUpdateChatIntegration,
    handleOpenDeleteIntegrationModal,
    isDeleting,
    initialIntegrationType
  }
}