type Props = {
  handleAddNewColumn: () => void
}

export const LeadColumnNew = ({ handleAddNewColumn }: Props) => {
  return (
    <button
      onClick={handleAddNewColumn}
      className="flex flex-col items-center justify-center h-[calc(100svh-16rem)] w-72 min-w-[18rem] border border-white/30 rounded-2xl"
    >
      <p className="font-light text-2xl">+</p>
      <p>Adicionar coluna</p>
    </button>
  )
}