import { Button } from "components/Button"
import { TextField } from "components/Forms/TextField"
import { useViewModel } from "./viewModel"
import { ChatIntegration } from "models/chatIntegration"
import { CopyText } from "components/Forms/CopyText"

type Props = {
  providerId: string
  selectedIntegration: ChatIntegration | undefined
  onClose: () => void
}

export const HuggyForm = ({ providerId, selectedIntegration, onClose }: Props) => {
  
  const viewModel = useViewModel(selectedIntegration, providerId)

  return (
    <form 
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
      className="flex flex-col gap-5"
    >
      <TextField
        label='Id do cliente do app'
        placeholder='Digite o ID do cliente'
        error={viewModel.errors.clientId?.message}
        register={viewModel.register("clientId", {
          required: { value: true, message: "O ID do cliente é obrigatório" }
        })}
        disabled={viewModel.status === 'old'}
        variant="secondary"
      />
      <CopyText
        text={import.meta.env.VITE_HOST_URL + '-' + providerId}
        label="URL de redirecionamento"
        variant="secondary"
      />
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button loading={viewModel.loadingForm} theme="highlight">
          Salvar
        </Button>
      </div>
    </form>
  )
}