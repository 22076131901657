import { BoardTag } from "./board"

export type Provider = {
  id: string
  email: string
  name: string
  client: string
  whatsapp: string
  responsible: string
  status: number
  date: string
  leadsStatus: LeadStatus[]
  averageTicket: number | null
}

type LeadStatus = {
  id: string
  name: string
  tag?: BoardTag
}

export type ProviderItem = {
  id: string
  name: string
}

export const PROVIDER_STATUS = {
  "Ativo": 0,
  "Pausado": 1,
  "Inativo": 2
}

export const statusToText = (status: number) => {
  switch (status) {
    case 0:
      return "Ativo"
    case 1:
      return "Pausa"
    case 2:
      return "Inativo"
    default:
      return ""
  }
}