import { Select } from "components/Forms/Select";
import {
  PaginationItemArrow,
  PaginationItemDot,
  PaginationItemPage
} from "./components";

type PaginationProps = {
  currentPage: number
  numberOfPages: number
  handleChangePage: (page: number) => void
}

export function Pagination({ currentPage, numberOfPages, handleChangePage }: PaginationProps) {

  function generatePagesArray(from: number, to: number) {
    return [...new Array(to - from)]
      .map((_, index) => {
        return from + index + 1;
      })
      .filter(page => page > 0 && page <= numberOfPages)
  }

  const siblingCount = 1
  const adjustPreviousPages = currentPage > numberOfPages - 3 - siblingCount ? 3 - (numberOfPages - currentPage) : 0
  const adjustNextPages = currentPage < siblingCount + 4 ? 4 - currentPage : 0

  const previousPages = currentPage > 1
    ? generatePagesArray(currentPage - 1 - siblingCount - adjustPreviousPages, currentPage - 1)
    : []

  const nextPages = currentPage < numberOfPages
    ? generatePagesArray(currentPage, Math.min(currentPage + siblingCount + adjustNextPages, numberOfPages))
    : []
  
  if (numberOfPages === 0) {
    return <></>
  }

  return (
    <>
      <div className="flex items-center justify-center tablet:hidden gap-2 mt-6">
        <PaginationItemArrow 
          currentPage={currentPage}
          direction="left"
          enabled={currentPage > 1}
          handleChangePage={handleChangePage}
          size="small"
        />
        <Select
          options={[...new Array(numberOfPages)].map((_, index) => ({ value: String(index + 1), label: `Página ${index + 1}` }))}
          selected={String(currentPage)}
          onChange={(value) => handleChangePage(Number(value))}
          invertOptionsBox
          borderless
          centered
          variant="secondary"
        />
        <PaginationItemArrow 
          currentPage={currentPage}
          direction="right"
          enabled={currentPage < numberOfPages}
          handleChangePage={handleChangePage}
          size="small"
        />
      </div>
      <div className="hidden tablet:flex items-center justify-end mt-6 gap-1">
        <PaginationItemArrow 
          currentPage={currentPage}
          direction="left"
          enabled={currentPage > 1}
          handleChangePage={handleChangePage}
        />

        {currentPage > (1 + siblingCount + adjustPreviousPages) && (
          <>
            <PaginationItemPage page={1} handleChangePage={handleChangePage}/>
            { currentPage > (2 + siblingCount) && <PaginationItemDot /> }
          </>
        )}

        {previousPages.length > 0 && previousPages.map(page => {
          return <PaginationItemPage key={page} page={page} handleChangePage={handleChangePage}/>
        })}

        {numberOfPages > 0 && (
          <PaginationItemPage
            page={currentPage}
            handleChangePage={handleChangePage}
            isCurrent
          />
        )}

        {nextPages.length > 0 && nextPages.map(page => {
          return <PaginationItemPage key={page} page={page} handleChangePage={handleChangePage}/>
        })}

        {(currentPage + siblingCount + adjustNextPages) < numberOfPages && (
          <>
            { (currentPage + 1 + siblingCount) < numberOfPages && <PaginationItemDot />}
            <PaginationItemPage page={numberOfPages} handleChangePage={handleChangePage}/>
          </>
        )}

        <PaginationItemArrow 
          currentPage={currentPage}
          direction="right"
          enabled={currentPage < numberOfPages}
          handleChangePage={handleChangePage}
        />
      </div>
    </>
  )
}