import { Button } from "components/Button"
import { DotLoading } from "components/DotLoading"

type IntegrationContainerProps = {
  title: string
  action: {
    label: string
    onClick: () => void
  }
  isLoading: boolean
  isError: boolean
  hasData: boolean
  onRetry: () => void
  children: React.ReactNode
  className?: string
}

export const IntegrationContainer = ({
  title, action, isLoading, isError, onRetry, hasData, children, className
}: IntegrationContainerProps) => {
  return (
    <section className={className}>
      <section className="flex justify-between items-center">
        <h2 className="text-white/50 text-2xl">{title}</h2>
        <Button onClick={action.onClick}>
          {action.label}
        </Button>
      </section>
      <section className="mt-8">
        {
          isLoading && (
            <div className="flex flex-col justify-center items-center gap-4 mt-4">
              <p>Buscando integrações</p>
              <DotLoading />
            </div>
          )
        }
        {
          isError && (
            <div
              className="flex flex-col justify-center items-center gap-y-4 mt-4"
            >
              <p>Ocorreu um erro ao buscar as integrações</p>
              <Button onClick={onRetry}
              >
                Tentar novamente
              </Button>
            </div>
          )
        }
        {
          !hasData && (
            <div className="flex flex-col justify-center items-center gap-y-4 mt-4">
              <p>O cliente não tem integrações cadastradas</p>
            </div>
          )
        }
        {
          hasData && (
            <section className="flex flex-col gap-4">
              {children}
            </section>
          )
        }
      </section>
    </section>
  )
}