import { useNavigate, useParams } from "react-router-dom"

import { FormStates } from "components/Forms/FormStates"

import { getSeller } from "services/sellerService"

import { SellerForm } from "../form"

import { PageHeader } from "components/Page/PageHeader"
import { useQuery } from "react-query"
import { SELLER_CLIENTS_QUERY_KEY } from "../viewModel"

type QueryParams = {
  id: string
}

export function ShowSeller() {

  const { id } = useParams<QueryParams>()
  const navigate = useNavigate()

  const { data, isLoading, isError, refetch } = useQuery(
    [SELLER_CLIENTS_QUERY_KEY, 'form', id],
    () => getSeller(id!),
    { enabled: !!id }
  )

  return (
    <div>
      <PageHeader 
        title="Ver Vendedores"
        backPath="/vendedores"
        action={{ label: "Editar", onClick: () => navigate(`/vendedores/editar/${id!}`) }}
      />
      <FormStates 
        isLoading={isLoading}
        hasError={isError}
        onRetry={refetch}
      />
      { data && (
        <SellerForm 
          enabled={false}
          seller={data}
        />
      )}
    </div>
  )
}