import { Button } from "components/Button"
import { DotLoading } from "components/DotLoading"
import { PageHeader } from "components/Page/PageHeader"
import { useQueryParams } from "hooks/useQueryParams"
import { useEffect, useMemo, useState } from "react"
import { WppResponseStatus, getWppConnectionData } from "services/wppService"
import { toMs } from "utils/date"

type QueryParams = {
  id: string
  client: string
}

export function WhatsappConnect() {

  const { id, client } = useQueryParams<QueryParams>()
  const [status, setStatus] = useState<WppResponseStatus>("connecting")
  const [qrCode, setQRCode] = useState<string|null>(null)
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleGetWppConnectionData(id!)
    }, 100)
    
    return () => clearTimeout(timeout)
  }, [id])

  const handleRetryConnection = () => {
    setStatus('connecting')
    handleGetWppConnectionData(id!)
  }

  const handleGetWppConnectionData = async (id: string, attempts = 0) => {
    try {
      const { data: wppData } = await getWppConnectionData(id)

      setStatus(wppData.status)
      setQRCode(wppData.qrCode)

      if (wppData.status === 'waiting_qr_code') {
        const timeOut = 5
        if (attempts < 10) {
          setTimeout(() => handleGetWppConnectionData(id, attempts + 1), toMs({ seconds: timeOut }))
        } else {
          setStatus('error')
        }
      }
    } catch (error) {
      console.error(error)
      setStatus('error')
    }
  }

  const Component = useMemo(() => {
    switch (status) {
      case 'connecting':
        return (
          <div className="flex flex-col gap-5 items-center">
            <span className="text-xl">Conectando ao whatsapp</span>
            <DotLoading style="primary" />
          </div>
        )
      case 'error':
        return (
          <div className="flex flex-col gap-5 items-center">
            <p className="text-xl text-red-600">Ocorreu um erro ao conectar-se ao whatsapp</p>
            <Button variant="outline" onClick={handleRetryConnection}>Tentar novamente</Button>
          </div>
        )
      case 'connected':
        return (
          <p className="text-xl text-green-600">O whatsapp foi conectado com sucesso!</p>
        )
    }
    if (qrCode === null) {
      return (
        <div className="flex flex-col gap-5 items-center">
          <span className="text-xl">Carregando QR Code</span>
          <DotLoading style="primary" />
        </div>
      )
    }

    return (
      <div className="flex items-center justify-center flex-col text-center gap-5">
        <span className="text-xl">Leia o QR Code abaixo para conectar-se ao whatsapp</span>
        <div className="bg-white p-4 rounded">
          <img src={qrCode} alt="QR Code" className="w-96 h-96 rounded" />
        </div>
      </div>
    )
  }, [status, qrCode])

  return (
    <section className="w-screen h-screen py-8 px-8 tablet:py-15 tablet:px-18">
      <PageHeader
        title="Conectar ao Whatsapp"
        backPath={`/clientes/${client}`}
      />
      <div className="w-full h-full flex items-center justify-center pb-8 tablet:pb-15">
        {Component}
      </div>
    </section>
  )
}