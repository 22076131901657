import { Button } from "components/Button"
import { IntegrationContainer } from "../components/integration-container"
import { useViewModel } from "./viewModel"
import { IntegrationRow } from "../components/integration-row"
import { SheetIntegrationsForm } from "./form"

type SheetsIntegrationProps = {
  clientId: string
}

export const SheetsIntegration = ({ clientId }: SheetsIntegrationProps) => {

  const { 
    sheetsIntegrations, showIntegrationModal, handleAddIntegration,
    handleCloseIntegrationModal, refetch, handleEditIntegration,
    selectedIntegration, isLoading, isError, handleSaveOrUpdateSheetsIntegration
  } = useViewModel(clientId)

  return (
    <>
      {
        showIntegrationModal && <SheetIntegrationsForm
          onClose={handleCloseIntegrationModal}
          onSaveOrUpdate={handleSaveOrUpdateSheetsIntegration}
          selectedIntegrationObject={selectedIntegration}
        />
      }
      <IntegrationContainer
        action={{
          label: "Adicionar",
          onClick: handleAddIntegration
        }}
        hasData={(sheetsIntegrations?.length ?? 0) > 0}
        isError={isError}
        isLoading={isLoading}
        onRetry={refetch}
        title="Integrações com Google Sheets"
        className="mt-12"
      >
        {
          sheetsIntegrations?.map(integration => (
            <IntegrationRow key={integration._id} path={`/icons/integrations/sheets.svg`} {...integration}>
              <Button onClick={() => handleEditIntegration(integration)} size="tiny" className="w-10">
                <img src="/icons/form/edit.svg" alt="Editar" />
              </Button>
            </IntegrationRow>
          ))
        }
      </IntegrationContainer>
    </>
  )
}