import { Integration } from "models/integration"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { SaveOrUpdateCampaignsIntegrationFunction } from "../../viewModel"
import { TextField } from "components/Forms/TextField"
import { Button } from "components/Button"
import { CopyText } from "components/Forms/CopyText"

export type MetaIntegrationForm = {
  type: string
  name: string
  adsId: string
  token: string
}

type Props = {
  selectedIntegration: Integration | undefined
  onSaveOrUpdate: SaveOrUpdateCampaignsIntegrationFunction
  onClose: () => void
}

export const MetaForm = ({ selectedIntegration, onClose, onSaveOrUpdate }: Props) => {

  const { register, formState: { errors }, setValue, handleSubmit, setError } = useForm<MetaIntegrationForm>()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" }
    })
  }, [register])

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "")
    setValue("type", selectedIntegration?.type ?? "facebook")
    setValue("adsId", selectedIntegration?.adsId ?? "")
    setValue("token", selectedIntegration?.token ?? "")
  }, [selectedIntegration, setValue])

  const handleSubmitForm = async (data: MetaIntegrationForm) => {
    if (isLoading) return
    setIsLoading(true)
    try {
      toast.info(selectedIntegration ? "Atualizando a integração..." : "Criando a integração...")
      await onSaveOrUpdate(data)
      toast.success(selectedIntegration ? "Integração atualizada com sucesso!" : "Integração criada com sucesso!")
      onClose()
    } catch(error) {
      toast.error("Ocorreu um erro ao salvar a integração")
    }
    setIsLoading(false)
  }

  return (
    <form 
      onSubmit={handleSubmit(handleSubmitForm)}
      className="flex flex-col gap-5"
    >
      <CopyText
        label='Link para gerar o token'
        text="https://developers.facebook.com/apps/433547785692456/marketing-api/tools/?business_id=338986327442366"
        variant="secondary"
      />
      <TextField
        label='Nome da conta de anúncios'
        placeholder='Digite o nome da conta'
        error={errors.name?.message}
        register={register("name", {
          required: { value: true, message: "O nome é obrigatório" }
        })}
        variant="secondary"
      />
      <TextField
        label='Identificador da campanha'
        placeholder='Digite o identificador da campanha'
        error={errors.adsId?.message}
        register={register("adsId", {
          required: { value: true, message: "O identificador da campanha é obrigatório" }
        })}
        variant="secondary"
      />
      <TextField
        label='Token de acesso'
        placeholder='Digite o token de acesso'
        error={errors.token?.message}
        register={register("token", {
          required: { value: true, message: "O token é obrigatório" }
        })}
        variant="secondary"
      />
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button loading={isLoading} theme="highlight">
          {selectedIntegration ? 'Editar' : 'Adicionar'}
        </Button>
      </div>
    </form>
  )
}
