import { useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"

import { ChatIntegration, ChatIntegrationType } from "models/chatIntegration"
import { AxiosError } from "axios"
import { messageToError, ValidationError } from "utils/validation"
import { toast } from "react-toastify"
import { getDepartments } from "services/chatIntegrationService"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { SelectItem } from "components/Forms/MultiSelect"

type UpChatIntegrationUpChatForm = {
  host: string
  token: string
  queues: number[] // departmentId
  departments: string[] // leadsChannels
}

export type UpChatIntegrationForm = {
  type: ChatIntegrationType
  name: string
  upChat: UpChatIntegrationUpChatForm
}

type UpChatIntegrationFormFields = Omit<UpChatIntegrationForm, "upChat"> & {
  upChat: Omit<UpChatIntegrationUpChatForm, "queues" | "departments"> & {
    queues: Array<{ id: string }>
    departments: string
  }
}

export const useViewModel = (
  selectedIntegration: ChatIntegration | undefined, handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction
) => {
  const {
    register, formState: { errors }, setValue, handleSubmit, setError, watch, control
  } = useForm<UpChatIntegrationFormFields>({
    defaultValues: {
      upChat: {
        queues: [{ id: "" }],
      }
    }
  })

  const { fields: queueFields, append, remove } = useFieldArray({ name: "upChat.queues", control })

  const [step, setStep] = useState(0)
  const [loadingForm, setLoadingForm] = useState(false)
  const [channels, setChannels] = useState<SelectItem[]>([])

  const selectedChannel = watch("upChat.departments") ?? undefined

  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" }
    })
  }, [register])

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "")
    setValue("type", selectedIntegration?.type ?? "upChat")
    setValue("upChat.host", selectedIntegration?.upChat?.host ?? "")
    setValue("upChat.queues", (selectedIntegration?.upChat?.queues ?? []).map(channel => ({ id: String(channel) })))
    setValue("upChat.token", selectedIntegration?.upChat?.token ?? "")
    setValue("upChat.departments", selectedIntegration?.upChat?.departments.join(",") ?? "")
  }, [selectedIntegration, setValue])

  const handleSelectChannel = (channel: string | undefined) => {
    if (!channel) return
    setValue("upChat.departments", channel)
  }

  async function handleSubmitFormFirstStep(data: UpChatIntegrationFormFields) {
    if (loadingForm) return
    setLoadingForm(true)

    try {
      toast.info("Buscando os canais...")
      const { departments } = await getDepartments({
        type: data.type,
        host: data.upChat.host,
        token: data.upChat.token,
      })
      setChannels([...departments.map(({ _id, name }) => ({ value: _id, label: name }))])
      setLoadingForm(false)
      setStep(1)
      toast.success("Canais buscados com sucesso!")
    } catch (error) {
      toast.error(errorMessage(error as Error))
      setLoadingForm(false)
    }
  }

  const errorMessage = (error: Error) => {
    const _err = error as AxiosError
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as { errors: ValidationError[] | undefined }

      if (data.errors) {
        const fields = data.errors.map(error => {
          setError(error.key as keyof UpChatIntegrationFormFields, { message: messageToError(error.message) })
          return error.key
        })
        return `Os campos ${fields.join(", ")} são inválidos`
      }
      if (_err.response.status === 400) return "O domínio ou o token são inválidos"
    }
    return "Ocorreu um erro, por favor tente novamente"
  }

  async function handleSubmitFormSecondStep(data: UpChatIntegrationFormFields) {
    if (loadingForm) return
    setLoadingForm(true)
    await handleSaveOrUpdate({
      ...data,
      upChat: {
        ...data.upChat,
        queues: data.upChat.queues.map(queue => Number(queue.id)),
        departments: data.upChat.departments.split(",")
      }
    })
    setLoadingForm(false)
  }

  const handleAppenedQueue = () => {
    append({ id: "" })
  }

  const handleRemoveQueue = (index: number) => () => {
    remove(index)
  }

  return {
    selectedIntegration,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    step,
    channels,
    handleSelectChannel,
    selectedChannel,
    queueFields,
    handleAppenedQueue,
    handleRemoveQueue
  }
}
