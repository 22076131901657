import { useViewModel } from "./viewModel";
import { Select } from "components/Forms/Select";
import { DatePickerModal } from "./DatePickerModal";

export function DateFilter() {

  const { 
    selectedPeriod, handleChangePeriods, datePeriodOptions, selectedProvider,
    sellerOptions, selectedSeller, handleChangeSeller, isSeller
  } = useViewModel()

  return (
    <div className="flex flex-col gap-4">
      {
        selectedProvider !== 'admin' && !isSeller && (
          <>
            <p>Selecione o vendedor(a):</p>
            <Select
              options={sellerOptions ?? []}
              onChange={handleChangeSeller}
              selected={selectedSeller}
              variant="secondary"
              placeholder="Selecione o vendedor(a)"
              centered
              isLoading={sellerOptions === undefined}
            />
          </>
        )
      }
      <p>Selecione o período:</p>
      <Select
        options={datePeriodOptions}
        onChange={handleChangePeriods}
        selected={selectedPeriod}
        variant="secondary"
        centered
      />
      <DatePickerModal />
    </div>
  )
}