import { useState } from "react"
import { AxiosError } from "axios"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { postRecover } from "services/authService"
import { toast } from "react-toastify"

type LoginFormInputs = {
  email: string
}

export const useViewModel = () => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm<LoginFormInputs>()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  async function handleRecoverPassword({ email }: LoginFormInputs) {
    if (isLoading) return
    setIsLoading(true)
    try {
      await postRecover(email)
      toast.success("Email enviado com sucesso")
      navigate("/login")
    } catch(err) {
      const _err = err as AxiosError
      if (_err.response?.status === 400) {
        setError("email", { message: "Email incorreto ou inexistente" })
      } else {
        setError("email", { message: "Ocorreu um erro inesperado, por favor tente mais tarde" })
      }
    }
    setIsLoading(false)
  }

  return {
    register,
    errors,
    handleSubmit,
    handleRecoverPassword,
    isLoading
  }
}