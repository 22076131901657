import { Pagination } from "components/Tables/Pagination"
import { Table } from "components/Tables/Table"
import { ConfirmDialog } from "components/ConfirmDialog"

import { useViewModel } from "./viewModel"
import { Seller, SELLER_STATUS, statusToText } from "models/seller"
import { TableField } from "components/Tables/Table/components/TableBody"
import { PageHeader } from "components/Page/PageHeader"
import { PageFilter } from "components/Page/PageFilter"
import { Button } from "components/Button"
import { Link } from "react-router-dom"

export default function Sellers() {

  const { 
    navigate, sellers, page, nbPages, confirmDialogData,
    handleChangeStatus, setPage, isLoading, isError, retry,
    user
  } = useViewModel()

  const headers = user?.role === "admin"
    ? ["Nome", "E-mail", "Provedor", "Status"]
    : ["Nome", "E-mail", "Status"]
  const fields: TableField<Seller>[] = user?.role === "admin"
    ? [{key: "name"}, {key: "email"}, {key: "providerName"}, {key: "status", type: "status"}]
    : [{key: "name"}, {key: "email"}, {key: "status", type: "status"}]

  return (
    <div>
      { confirmDialogData && <ConfirmDialog {...confirmDialogData} /> }
      <div className="flex justify-between items-center">
        <PageHeader 
          title="Vendedores"
        />
        <div className="flex items-center gap-3">
          <PageFilter hideTimeFilter />
          <Link to="/vendedores/novo">
            <Button leftIcon="/icons/form/add.svg">
              Adicionar
            </Button>
          </Link>
        </div>
      </div>
      <Table
        className="mt-7"
        headers={headers}
        fields={fields}
        data={sellers}
        transforms={[{ key: "status", transform: (seller) => statusToText(seller.status) }]}
        isLoading={isLoading}
        hasError={isError}
        onRetry={retry}
        actions={(seller, index) => {
          return (
            <>
              <button onClick={() => navigate(`/vendedores/${seller.id}`)}>Ver mais</button>
              <button onClick={() => navigate(`/vendedores/editar/${seller.id}`)}>Editar</button>
              { seller.status !== SELLER_STATUS.Ativo && (
                <button onClick={() => handleChangeStatus(index, SELLER_STATUS.Ativo)}>Ativar</button>
              )}
              { seller.status !== SELLER_STATUS.Inativo && (
                <button onClick={() => handleChangeStatus(index, SELLER_STATUS.Inativo)}>Desativar</button>
              )}
            </>
          )
        }}
      />      
      <Pagination 
        currentPage={page}
        handleChangePage={setPage}
        numberOfPages={nbPages ?? 0}
      />
    </div>
  )
}