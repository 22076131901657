import { cn } from "utils/styles"

type Props = {
  variant?: 'primary' | 'secondary'
  salles: number
  oldSalles: number
}

export const SalesCard = ({ variant = 'primary', salles, oldSalles }: Props) => {

  return (
    <div className={cn(
      "rounded-2xl flex flex-col items-center justify-center gap-1 min-h-[7rem] laptop:flex-1 desktop:flex-none desktop-xl:flex-1",
      variant === 'primary' ? 'bg-primaryMultiply/70 px-4 py-4' : 'border border-white/30 py-2 px-2 desktop-lg:px-4'
    )}>
      <div className="flex items-center gap-2.5">
        <img className={cn(variant === 'primary' ? '' : 'h-3.5')} src="/icons/campaigns/cart.svg" alt="" />
        <p className={cn(variant === 'primary' ? '' : 'text-xs')}>Vendas</p>
      </div>
      <div className="font-input">
       <div className="flex flex-col mb-1">
          <span className="text-xs text-secondary text-center">Total</span>
          <p className={cn(
            "font-bold text-center",
            variant === 'primary' ? 'text-2xl' : 'text-xl'
          )}>{salles + oldSalles}</p>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col">
            <span className="text-xs text-secondary">Período atual</span>
            <p className={cn(
              "font-bold text-center",
              variant === 'primary' ? 'text-xl' : 'text-lg'
            )}>{salles}</p>
          </div>
          <div className="flex flex-col">
            <span className="text-xs text-secondary">Período anterior</span>
            <p className={cn(
              "font-bold text-center",
              variant === 'primary' ? 'text-xl' : 'text-lg'
            )}>{oldSalles}</p>
          </div>
        </div>
      </div>
    </div>
  )
}