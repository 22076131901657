import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { ChatIntegration, ChatIntegrationType } from "models/chatIntegration"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"

export type BlipForm = {
  type: ChatIntegrationType
  name: string
}

export const useViewModel = (selectedIntegration: ChatIntegration | undefined, handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction) => {
  const {
    register, formState: { errors }, setValue, handleSubmit, watch, control
  } = useForm<BlipForm>({
    defaultValues: {
      type: "blip",
      name: "",
    }
  })

  const [loadingForm, setLoadingForm] = useState(false)

  const whatsapp = watch("name") ?? ""

  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" }
    })
  }, [register])

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "")
    setValue("type", selectedIntegration?.type ?? "blip")
  }, [selectedIntegration, setValue])

  async function handleSubmitSuccess(data: BlipForm) {
    if (loadingForm) return
    setLoadingForm(true)
    await handleSaveOrUpdate({
      ...data,
    })
    setLoadingForm(false)
  }

  return {
    selectedIntegration,
    setValue,
    register,
    whatsapp,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitSuccess
  }
}