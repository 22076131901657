import { FormHTMLAttributes, HTMLAttributes } from "react"
import { cn } from "utils/styles"

export function Form({ children, onSubmit, className, ...props }: FormHTMLAttributes<HTMLFormElement>) {

  return (
    <form onSubmit={onSubmit} className={cn(className, "flex flex-col items-center")} {...props}>
      {children}
    </form>
  )
}

export const FormRow = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn(className, "flex flex-col tablet:flex-row gap-5 w-full justify-center")} {...props}>
      {children}
    </div>
  )
}

export const FormColumn = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn(className, "flex flex-col gap-5 flex-1")} {...props}>
      {children}
    </div>
  )
}