import { type MutableRefObject, useCallback } from 'react'

type LoadMorePage = () => void

const useInfinityScroll = <HtmlElement extends Element>(
  ref: MutableRefObject<IntersectionObserver | undefined>,
  loading: boolean,
  loadMore: LoadMorePage,
  hasMore: boolean,
  ...deps: any[]
) => {
  return ((node: HtmlElement | undefined | null) => {
    if (loading) return
    if (ref.current != null) ref.current.disconnect()

    ref.current = new IntersectionObserver(entries => {
      if ((entries[0]?.isIntersecting ?? false) && !loading && hasMore) {
        loadMore()
      }
    })
    if (node !== undefined && node !== null) ref.current.observe(node)
  })
}

export default useInfinityScroll
