import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { type ZodIssue } from 'zod'

import { Provider } from "models/provider"
import { Masks, maskText, unMaskMoney, unMaskText } from "utils/mask"
import { AxiosError } from "axios"
import { toast } from "react-toastify"

export type FormFields = {
  email: string
  password?: string
  name: string
  client: string
  whatsapp: string
  responsible: string
  status: number
  date: string
  averageTicket: number
}

type RawFormFields = Omit<FormFields, "averageTicket"> & {
  averageTicket: string
}

export const useViewModel = (provider: Provider | undefined, onSubmit: ((fields: FormFields) => Promise<void>) | undefined) => {
  
  const [loading, setLoading] = useState(false)
  const { register, formState: { errors }, handleSubmit, setValue, watch, setError } = useForm<RawFormFields>()

  const whatsapp = watch("whatsapp", "")
  const status = watch("status")
  const date = watch("date", "")
  const averageTicket = watch("averageTicket", "0")

  useEffect(() => {
    if (provider) {
      const formattedDate = provider.date.substring(0, 10).split("-").reverse().join("/")
      
      setValue("name", provider.name)
      setValue("email", provider.email)
      setValue("client", provider.client)
      setValue("whatsapp", maskText(provider.whatsapp, Masks.PHONE, provider.whatsapp.length))
      setValue("responsible", provider.responsible)
      setValue("status", provider.status)
      setValue("date", maskText(formattedDate, Masks.DATE, formattedDate.length))
      setValue("averageTicket", String(provider.averageTicket ?? 0))
    } else {
      setValue("whatsapp", "")
      setValue("status", 0)
      setValue("date", "")
      setValue("averageTicket", "0")
    }
  }, [provider, setValue])

  useEffect(() => {
    register("whatsapp", {
      required: { value: true, message: "O campo WhatsApp é obrigatório" },
      pattern: { value: /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/, message: "Whatsapp inválido" }
    })
    register("date", {
      required: { value: true, message: "A data do cadastro do cliente é obrigatória" },
      pattern: { value: /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/, message: "Data inválida" }
    })
  }, [register])

  async function handleOnSubmit(fields: RawFormFields) {
    if (onSubmit) {
      setLoading(true)
      try {
        await onSubmit({
          ...fields,
          date: fields.date.split("/").reverse().join("-"),
          whatsapp: unMaskText(fields.whatsapp),
          averageTicket: Number(unMaskMoney(fields.averageTicket)) / 100,
          status: parseInt(fields.status.toString())
        })
      } catch(err) {
        const _err = err as AxiosError
        if (_err.isAxiosError && _err.response?.data !== undefined) {
          const data = _err.response.data as { errors: ZodIssue[] | undefined }

          if (data.errors) {
            const fields = data.errors.map(error => {
              error.code
              setError(error.path[0] as keyof FormFields, { message: error.message })
              return error.path[0]
            })
            toast(`Os campos ${fields.join(", ")} não são válidos`, { type: "error" })
            setLoading(false)
            return;
          }
        }
        toast("Ocorreu um erro ao salvar o cliente", { type: "error" })
      }
      setLoading(false)
    }
  }

  return {
    loading,
    register,
    errors,
    handleSubmit: handleSubmit(handleOnSubmit),
    whatsapp,
    status,
    date,
    setValue,
    averageTicket
  }
}