import { cn } from "utils/styles"

type Props = {
  plataform: 'meta_ads' | 'google_ads' | 'organic'
  children: React.ReactNode | React.ReactNode[]
  className?: string
}

export const PlatformRow = ({ plataform, children, className }: Props) => {

  const platafomIcon = {
    meta_ads: '/icons/campaigns/facebook.svg',
    google_ads: '/icons/campaigns/google.svg',
    organic: '/icons/campaigns/organic.svg'
  }

  const plataformName = {
    meta_ads: 'Meta',
    google_ads: 'Google',
    organic: 'Orgânico'
  }

  return (
    <div className={cn(
      "bg-primaryMultiply rounded-2xl px-8 py-4 flex flex-col desktop:flex-row",
      className
    )}>
      <div className="flex items-center gap-3.5 justify-center desktop:justify-start w-full desktop:w-44">
        <div className="w-11">
          <img src={platafomIcon[plataform]} alt="Meta ADS" className="h-8" />
        </div>
        <p className="text-xl">{plataformName[plataform]}</p>
      </div>
      {children}
    </div>
  )
}