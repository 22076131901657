import { Button } from "components/Button"
import { TextField } from "components/Forms/TextField"
import { useViewModel } from "./viewModel"
import { ChatIntegration } from "models/chatIntegration"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { MultiSelect } from "components/Forms/MultiSelect"

type Props = {
  selectedIntegration: ChatIntegration | undefined
  onClose: () => void
  onSave: SaveOrUpdateChatIntegrationFunction
}

export const MatrixForm = ({ selectedIntegration, onClose, onSave }: Props) => {
  
  const viewModel = useViewModel(selectedIntegration, onSave)

  if (viewModel.step === 0) {
    return (
      <form 
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
        className="flex flex-col gap-5"
      >
        <TextField
          label='Nome da integração'
          placeholder='Digite o nome da integração'
          error={viewModel.errors.name?.message}
          register={viewModel.register("name", {
            required: { value: true, message: "O nome é obrigatório" }
          })}
          variant="secondary"
        />
        <TextField
          label='Domínio Matrix'
          placeholder='Digite o seu domínio personalizado do Matrix'
          error={viewModel.errors.matrix?.host?.message}
          register={viewModel.register("matrix.host", {
            required: { value: true, message: "O domínio é obrigatório" }
          })}
          variant="secondary"
        />
        <TextField
          label='Token de acesso'
          placeholder='Digite o token de acesso'
          error={viewModel.errors.matrix?.token?.message}
          register={viewModel.register("matrix.token", {
            required: { value: true, message: "O token é obrigatório" }
          })}
          variant="secondary"
        />
        <div className="modalButtons">
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button loading={viewModel.loadingForm} theme="highlight">
            Salvar
          </Button>
        </div>
      </form>
    )
  }
  
  return (
    <form 
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)}
      className="flex flex-col gap-5"
    >
      <MultiSelect
        label='Selecione os vendedores'
        onChange={(value) => viewModel.setValue("matrix.sellers", value)}
        options={viewModel.sellers.map(seller => ({value: seller.name, label: seller.name}))}
        selected={viewModel.selectedSellers}
        error={viewModel.errors.matrix?.sellers?.message}
        variant="secondary"
      />
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button loading={viewModel.loadingForm} theme="highlight">
          Salvar
        </Button>
      </div>
    </form>
  )
}