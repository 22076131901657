import { useNavigate, useParams } from "react-router-dom"

import { FormStates } from "components/Forms/FormStates"

import { getProvider } from "services/providerService"

import { CampaignsIntegration } from "./campaings-integration"

import { ChatsIntegration } from "./chats-integration"
import { SheetsIntegration } from "./sheets-integration"
import { PageHeader } from "components/Page/PageHeader"
import { ShowForm } from "./show-form"
import { useQuery } from "react-query"
import { PROVIDER_CLIENTS_QUERY_KEY } from "../viewModel"

type QueryParams = {
  id: string
}

export function ShowClient() {

  const { id } = useParams<QueryParams>()
  const navigate = useNavigate()

  const { data, isLoading, isError, refetch } = useQuery(
    [PROVIDER_CLIENTS_QUERY_KEY, 'form', id],
    () => getProvider(id!),
    { enabled: !!id }
  )

  return (
    <section>
      <PageHeader 
        title="Ver Clientes"
        backPath="/clientes"
        action={{ label: "Editar", onClick: () => navigate(`/clientes/editar/${id!}`) }}
      />
      <FormStates 
        isLoading={isLoading}
        hasError={isError}
        onRetry={refetch}
      />
      { data && (
        <ShowForm {...data} />
      )}
      <CampaignsIntegration clientId={id!} />
      <ChatsIntegration clientId={id!} />
      <SheetsIntegration clientId={id!} />
    </section>
  )
}