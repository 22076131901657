import { useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"

import { ChatIntegration, ChatIntegrationSocialMatching, ChatIntegrationType } from "models/chatIntegration"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { ILeadSocialOrigin } from "models/lead"

export type WhatsappIntegrationForm = {
  type: ChatIntegrationType
  name: string
  whatsappUnofficial: {
    socialMatching: ChatIntegrationSocialMatching[]
  }
}

export const useViewModel = (selectedIntegration: ChatIntegration | undefined, handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction) => {
  const {
    register, formState: { errors }, setValue, handleSubmit, watch, control
  } = useForm<WhatsappIntegrationForm>({
    defaultValues: {
      type: "whatsappUnofficial",
      name: "",
      whatsappUnofficial: {
        socialMatching: [
          { socialOrigin: "facebook", keywords: "" },
          { socialOrigin: "google", keywords: "" }
        ]
      }
    }
  })
  const { fields: socialMatchingFields, append, remove, update } = useFieldArray({ control, name: "whatsappUnofficial.socialMatching" })

  const [loadingForm, setLoadingForm] = useState(false)

  const whatsapp = watch("name") ?? ""

  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" }
    })
    register("name", {
      required: { value: true, message: "O campo WhatsApp é obrigatório" },
      pattern: { value: /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/, message: "Whatsapp inválido" }
    })
    register("whatsappUnofficial.socialMatching", {
      validate: (value: ChatIntegrationSocialMatching[]) => {
        if (value.length === 0) {
          return "É necessário adicionar pelo menos uma origem social"
        }
        if (value.some((item) => item.keywords === "")) {
          return "É necessário preencher o campo de palavras chave para todas as origens sociais"
        }
        return true
      }
    })
  }, [register])

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "")
    setValue("type", selectedIntegration?.type ?? "whatsappUnofficial")
    if (selectedIntegration?.whatsappUnofficial?.socialMatching) {
      setValue("whatsappUnofficial.socialMatching", selectedIntegration?.whatsappUnofficial?.socialMatching ?? [])
    }
  }, [selectedIntegration, setValue])

  async function handleSubmitSuccess(data: WhatsappIntegrationForm) {
    if (loadingForm) return
    setLoadingForm(true)
    await handleSaveOrUpdate({
      ...data,
      whatsappUnofficial: {
        socialMatching: data.whatsappUnofficial.socialMatching
        .filter((item) => item.keywords !== "")
        .map((item) => ({ 
          socialOrigin: item.socialOrigin,
          keywords: item.keywords.split(',').map(word => word.trim()).join(',')
        }))
      }
    })
    setLoadingForm(false)
  }

  const handleAppendSocialMatching = () => {
    append({ socialOrigin: "facebook", keywords: "" })
  }

  const handleRemoveSocialMatching = (index: number) => {
    remove(index)
  }

  const handleUpdateSocialMatching = (index: number, value: string) => {
    const field = socialMatchingFields[index]
    field.socialOrigin = value as ILeadSocialOrigin
    update(index, field)
  }

  return {
    selectedIntegration,
    setValue,
    register,
    whatsapp,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitSuccess,
    socialMatchingFields,
    handleAppendSocialMatching,
    handleRemoveSocialMatching,
    handleUpdateSocialMatching
  }
}