import { Button } from "components/Button"
import { IntegrationContainer } from "../components/integration-container"
import { useViewModel } from "./viewModel"
import { IntegrationRow } from "../components/integration-row"
import { ChatIntegrationsForm } from "./form"
import { ConfirmDialog } from "components/ConfirmDialog"
import { Link } from "react-router-dom"

type ChatsIntegrationProps = {
  clientId: string
}

export const ChatsIntegration = ({ clientId }: ChatsIntegrationProps) => {

  const { 
    chatIntegrations, refetch, showIntegrationModal, selectedIntegration,
    isLoading, isError, handleAddIntegration, handleEditIntegration,
    handleCloseIntegrationModal, handleSaveOrUpdateChatIntegration,
    handleDeleteIntegration, handleCloseDeleteModal, isDeleting, showDeleteModal,
    initialIntegrationType, handleOpenDeleteIntegrationModal,
  } = useViewModel(clientId)

  return (
    <>
      {
        showIntegrationModal && <ChatIntegrationsForm
          selectedIntegrationObject={selectedIntegration}
          onClose={handleCloseIntegrationModal}
          onSaveOrUpdate={handleSaveOrUpdateChatIntegration}
          providerId={clientId}
          initialIntegration={initialIntegrationType}
        />
      }
      {
        showDeleteModal && selectedIntegration && (
          <ConfirmDialog
            title="Remover integração"
            message={`Tem certeza que deseja remover a integração ${selectedIntegration.name}?`}
            show
            onConfirm={handleDeleteIntegration}
            onCancel={handleCloseDeleteModal}
            isLoading={isDeleting}
          />
        )
      }
      <IntegrationContainer
        action={{ label: "Adicionar", onClick: handleAddIntegration }}
        hasData={(chatIntegrations?.length ?? 0) > 0}
        isError={isError}
        isLoading={isLoading}
        onRetry={refetch}
        title="Integrações de Chat bots"
        className="mt-12"
      >
        {
          chatIntegrations?.map(integration => (
            <IntegrationRow key={integration.id} path={`/icons/chat-bots/${integration.type}.png`} {...integration}>
              {
                integration.type === 'whatsappUnofficial' && (
                  <Link to={`/conectar?id=${integration.id}&client=${integration.provider}`}>
                    <Button size="tiny" className="min-w-[5rem]">
                      Conectar
                    </Button>
                  </Link>
                )
              }
              {
                (integration.type === 'huggy' && integration.huggy!.tags.length === 0) ? (
                  <Button onClick={() => handleEditIntegration(integration)} size="tiny" className="min-w-[5rem]">
                    Concluir
                  </Button>
                ) : (
                  <Button onClick={() => handleEditIntegration(integration)} size="tiny" className="w-10">
                    <img src="/icons/form/edit.svg" alt="Editar" className="h-5" />
                  </Button>
                )
              }
              <Button onClick={() => handleOpenDeleteIntegrationModal(integration)} size="tiny" className="w-10">
                <img src="/icons/form/delete.svg" alt="Remover" className="h-5" />
              </Button>
            </IntegrationRow>
          ))
        }
      </IntegrationContainer>
    </>
  )
}