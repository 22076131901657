import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'

import { Label } from "../Label";

import { cn } from 'utils/styles';
import { Checkbox } from '../Checkbox';

export type SelectItem = {
  label: string
  value: string
}

type SelectProps = {
  id?: string
  label?: string
  selected?: string[]
  error?: string
  disabled?: boolean
  options: SelectItem[]
  onChange: (value: string[]) => void
  required?: boolean
  borderless?: boolean
  invertOptionsBox?: boolean
  variant?: "primary" | "secondary"
  centered?: boolean
  clearable?: boolean
  placeholder?: string
}

function MultiSelect({
  label, selected = [], error, disabled, options, onChange, variant = 'primary',
  invertOptionsBox = false, centered, clearable, placeholder
}: SelectProps) {

  return (
    <div>
      {
        label && <Label className='px-5'>{label}</Label>
      }
      <Listbox value={selected} onChange={onChange} disabled={disabled} multiple>
        <div className={cn("relative font-input", label ? 'mt-3' : '')}>
          <div className={`w-full border-none outline-none py-2 px-5 text-base  text-white rounded-full ${variant === 'primary' ? 'bg-primaryMultiply placeholder:text-white/30' : 'bg-white/30'} ${centered && "text-center"}`}>
            <Listbox.Button className="flex flex-1 w-full justify-between h-6 items-center">
              <span className='block truncate flex-1 text-start'>
                {
                  selected.length === 0
                  ? placeholder
                  : selected.map((selected) => options.find(option => option.value === selected)?.label).join(", ")
                }
              </span>
              {
                clearable && selected.length > 0 && !disabled && (
                  <XMarkIcon
                    className="h-5 w-5 text-secondary"
                    onClick={() => onChange([])}
                  />
                )
              }
              <img
                src="/icons/form/select_arrow.svg"
                aria-hidden="true"
              />
            </Listbox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className={`absolute ${invertOptionsBox && "bottom-10"} z-50 mt-1 max-h-60 w-full overflow-auto bg-primaryMultiply/90 shadow-xl focus:outline-none rounded-2xl`}>
              {
                options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) => cn(
                      "relative select-none text-white",
                      active ? 'bg-primary/40 rounded-xl' : '',
                      centered ? "text-center" : "text-left"
                    )}
                    value={option.value}
                  >
                    {({ selected }) => (
                      <span
                        className={`block truncate cursor-pointer py-2 px-4`}
                      >
                        <Checkbox isChecked={selected} label={option.label} />
                      </span>
                    )}
                  </Listbox.Option>
                ))
              }
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export { MultiSelect }