import { Button } from "components/Button"
import { Dialog } from "components/Dialog"
import { DatePicker } from "components/Forms/DatePicker"
import { queryClient } from "services/queryClient"
import { REPORT_CLIENTS_QUERY_KEY } from "../viewModel"
import { Controller, useForm } from "react-hook-form"
import { useState } from "react"
import { toast } from "react-toastify"
import { createReport } from "services/reporService"
import { delay } from "utils/utils"
import { toMs } from "utils/date"
import { ReportType } from "models/report"
import { Select } from "components/Forms/Select"

type ReportForm = {
  initDate: string
  endDate: string
  type: string
}

const reportTypeOptions: { value: ReportType, label: string }[] = [
  { value: 'lead.list', label: 'Leads por data de entrada' },
  { value: 'lead.list.updated', label: 'Leads por data de atualização' },
  { value: 'lead.list.selled', label: 'Leads por data de venda' }
]

type Props = {
  onClose: () => void
}

export const CreateReportForm = ({ onClose }: Props) => {

  const { handleSubmit, register, watch, control, formState: { errors } } = useForm<ReportForm>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  const watchInitDate = watch('initDate')

  const handleCreateReport = async (data: ReportForm) => {
    if (isSubmitting || !reportTypeOptions.some(option => option.value === data.type)) return

    setIsSubmitting(true)

    try {
      await createReport(data.initDate, data.endDate, data.type as ReportType)
      await delay(toMs({ milliseconds: 500 }))
      await queryClient.invalidateQueries(REPORT_CLIENTS_QUERY_KEY)
      onClose()
    } catch (error) {
      console.log(error)
      toast.error('Erro ao criar relatório')
    }

    setIsSubmitting(false)
  }

  return (
    <Dialog onClose={onClose}>
      <form className="flex flex-col gap-5" onSubmit={handleSubmit(handleCreateReport)}>
        <p className="text-center">Selecione o período que será gerado o relatório</p>
        <div className="flex flex-col gap-2">
          <DatePicker
            name="initDate"
            register={register("initDate", {
              required: 'Data inicial é obrigatória',
              validate: value => {
                if (new Date(value).toString() === 'Invalid Date') {
                  return 'Data inicial inválida'
                }
                if (new Date(value) > new Date()) {
                  return 'Data inicial não pode ser maior que a data atual'
                }
                return true
              }
            })}
            variant="secondary"
            label="Data inicial"
            error={errors.initDate?.message}
          />
          <DatePicker 
            name="endDate"
            register={register("endDate", {
              required: 'Data final é obrigatória',
              validate: value => {
                if (new Date(value).toString() === 'Invalid Date') {
                  return 'Data final inválida'
                }
                if (new Date(value) > new Date()) {
                  return 'Data final não pode ser maior que a data atual'
                }
                if (new Date(value) < new Date(watchInitDate)) {
                  return 'Data final não pode ser menor que a data inicial'
                }
                return true
              }
            })}
            variant="secondary"
            label="Data final"
            error={errors.endDate?.message}
          />
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                selected={field.value}
                options={reportTypeOptions}
                label="Tipo de relatório"
                error={errors.type?.message}
                variant="secondary"
                placeholder="Selecione o tipo de relatório"
              />
            )}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button theme="cancel" type="button" onClick={onClose}>Cancelar</Button>
          <Button theme="highlight" type="submit" loading={isSubmitting}>Gerar</Button>
        </div>
      </form>
    </Dialog>
  )
}