import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useQuery } from "react-query"

import { ConfirmDialogProps } from "components/ConfirmDialog"
import { statusToText } from "models/provider"
import { changeProviderStatus, getProviders } from "services/providerService"
import { queryClient } from "services/queryClient"

export const PROVIDER_CLIENTS_QUERY_KEY = "providers"

export const useViewModel = () => {

  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState<number | undefined>(undefined)
  const { data, isLoading, isError, refetch } = useQuery(
    [PROVIDER_CLIENTS_QUERY_KEY, currentPage],
    () => getProviders(currentPage, 20)
  )

  useEffect(() => {
    if (data?.pagination.nbPages) {
      setNumberOfPages(data.pagination.nbPages)
    }
  }, [data?.pagination.nbPages])

  const [confirmDialogData, setConfirmDialogData] = useState<ConfirmDialogProps>()

  async function handleChangeStatus(index: number, newStatus: number) {
    setConfirmDialogData({
      show: true,
      title: "Alterar Status",
      message: `Deseja realmente alterar o status do provedor para ${statusToText(newStatus)}?`,
      onConfirm: async () => {
        setConfirmDialogData(undefined)
        const loadingToast = toast("Atualizando status do cliente...", { type: "info", autoClose: false })
        await changeProviderStatus(data!.data![index].id, newStatus)
        toast.dismiss(loadingToast)
        toast("Status atualizado com sucesso!", { type: "success" })
        queryClient.invalidateQueries([PROVIDER_CLIENTS_QUERY_KEY, currentPage])
      },
      onCancel: () => {
        setConfirmDialogData(undefined)
      }
    })
  }

  return {
    providers: data?.data,
    currentPage: currentPage,
    numberOfPages: numberOfPages,
    isLoading: isLoading,
    isError: isError,
    retry: refetch,
    confirmDialogData: confirmDialogData,
    setCurrentPage: setCurrentPage,
    handleChangeStatus: handleChangeStatus,
    navigate: navigate
  }
}