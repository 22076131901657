import * as Sentry from "@sentry/react";

export class Log {
    static info(message: string, ...args: any[]) {
        console.log(message, ...args);
    }
    static error(message: string, error: Error) {
        console.error(message, JSON.stringify(error));
        if (import.meta.env.PROD) {
          Sentry.captureException(error);
        }
    }
    static warn(message: string, ...args: any[]) {
        console.warn(message, ...args);
    }
    static debug(message: string, ...args: any[]) {
        console.debug(message, ...args);
    }
}