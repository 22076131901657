import { AuthInput } from 'components/Auth/AuthInput'

import { useViewModel } from './viewModel'

import { Link } from 'react-router-dom'
import { Button } from 'components/Button'

export default function ConfirmRecover() {

  const { register, errors, handleConfirmRecover, handleSubmit, password, isLoading } = useViewModel()

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div className="mix-blend-multiply bg-primary py-10 rounded-3xl w-[18.5rem] h-[25rem]"></div>
      <div className='absolute px-12 py-10 rounded-3xl text-center'>
        <img src="/images/brand/logo.png" alt='Login logo' />
        <form
          onSubmit={handleSubmit(handleConfirmRecover)}
          className="flex flex-col gap-4 mt-7 mb-4"
        >
          <AuthInput 
            label="Senha"
            placeholder="Digite a senha"
            error={errors.password?.message}
            type="password"
            leftIcon='/icons/auth/pass.svg'
            register={register("password", {
              required: { value: true, message: "A senha é obrigatório" },
              minLength: { value: 6, message: "A senha deve ter no mínimo 6 caracteres" }
            })}
          />
          <AuthInput 
            label="Confirmação da senha"
            placeholder="Digite a confirmação da senha"
            error={errors.passwordConfirm?.message}
            type="password"
            leftIcon='/icons/auth/pass.svg'
            register={register("passwordConfirm", {
              required: { value: true, message: "A senha é obrigatório" },
              minLength: { value: 6, message: "A senha deve ter no mínimo 6 caracteres" },
              validate: value => value === password || "As senhas não conferem"
            })}
          />
          <Button
            type='submit'
            size='big'
            theme='highlight'
            loading={isLoading}
            className='mt-4'
          >
            Recuperar
          </Button>
        </form>
        <Link to="/login" className="text-white text-xs">Voltar ao login</Link>
      </div>
    </div>
  )
}