import { useMemo } from "react"
import { useSelector } from "react-redux"
import { IState } from "store"

type SelectedDateString = {
  initDate: string
  endDate: string
}

export const useFilters = () => {
  const { selectedPeriod, selectedDate: seletorDate, selectedLeadPeriodFilter } = useSelector((state: IState) => state.filters)

  const selectedDate = useMemo(() => {
    if (seletorDate === undefined) return undefined
    const _date = JSON.parse(seletorDate) as SelectedDateString
    return { initDate: new Date(_date.initDate), endDate: new Date(_date.endDate) }
  }, [seletorDate])

  return {
    selectedPeriod,
    selectedDate,
    selectedLeadPeriodFilter
  }
}
