import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"

import { ChatIntegration, ChatIntegrationType } from "models/chatIntegration"
import { AxiosError } from "axios"
import { messageToError, ValidationError } from "utils/validation"
import { toast } from "react-toastify"
import { getIntegrationSellers, webhookCheck } from "services/chatIntegrationService"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { queryClient } from "services/queryClient"
import { IntegrationSeller } from "models/integrationSeller"

export type Multi360IntegrationForm = {
  name: string
  type: ChatIntegrationType
  multi360: {
    email: string
    password: string
    sellers: string[]
  }
}

export const useViewModel = (
  selectedIntegration: ChatIntegration | undefined, handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction
) => {
  const {
    register, formState: { errors }, setValue, handleSubmit, setError, watch
  } = useForm<Multi360IntegrationForm>()

  const [step, setStep] = useState(0)
  const [loadingForm, setLoadingForm] = useState(false)
  const [sellers, setSelers] = useState<IntegrationSeller[]>([])

  const selectedSellers = watch("multi360.sellers") ?? []
  
  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" }
    })
  }, [register])

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "")
    setValue("type", selectedIntegration?.type ?? "multi360")
    setValue("multi360.email", selectedIntegration?.multi360?.email ?? "")
    setValue("multi360.password", selectedIntegration?.multi360?.password ?? "")
    setValue("multi360.sellers", selectedIntegration?.multi360?.sellers ?? [])
  }, [selectedIntegration, setValue])

  async function handleSubmitFormFirstStep(data: Multi360IntegrationForm) {
    if (loadingForm) return
    setLoadingForm(true)

    try {
      toast.info("Buscando os vendedores...")
      const { attendents } = await getIntegrationSellers({
        type: data.type as ChatIntegrationType,
        multi360: {
          email: data.multi360.email,
          password: data.multi360.password
        }
      })
      setSelers(attendents)
      setLoadingForm(false)
      setStep(1)
      toast.success("Vendedores buscados com sucesso!")
    } catch (error) {
      toast.error(errorMessage(error as Error))
      setLoadingForm(false)
    }
  }

  const errorMessage = (error: Error) => {
    const _err = error as AxiosError
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as { errors: ValidationError[] | undefined }

      if (data.errors) {
        const fields = data.errors.map(error => {
          setError(error.key as keyof Multi360IntegrationForm, { message: messageToError(error.message) })
          return error.key
        })
        return `Os campos ${fields.join(", ")} são inválidos`
      }
      if (_err.response.status === 400) return "O email ou senha são inválidos"
    }
    return "Ocorreu um erro, por favor tente novamente"
  }

  async function handleSubmitFormSecondStep(data: Multi360IntegrationForm) {
    if (loadingForm) return
    setLoadingForm(true)
    await handleSaveOrUpdate(data)
    setLoadingForm(false)
  }

  return {
    selectedIntegration,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    step,
    sellers,
    selectedSellers
  }
}
