import { toPercent } from "utils/format"
import { cn } from "utils/styles"

type Props = {
  variant?: 'primary' | 'secondary'
  lostOportunity: number
  lostLeads: number
  stopResponding: number
  gaveUp: number
}

export const Resume2Card = ({ variant = 'primary', lostLeads, lostOportunity, gaveUp, stopResponding }: Props) => {

  return (
    <div className={cn(
      "rounded-2xl font-input text-sm relative flex flex-col justify-center laptop:flex-1",
      variant === 'primary' ? 'bg-primaryMultiply/70 py-4 px-6 text-sm' : 'border border-white/30 py-4 px-4 text-xs'
    )}>
      <div className="h-2 w-2 bg-paused rounded-full absolute top-2.5 left-2.5" />
      <div className="flex items-center justify-between">
        <p className="text-secondary">Oportunidade perdida</p>
        <p>{toPercent(lostOportunity)}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-secondary">Leads perdidos</p>
        <p>{lostLeads}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-secondary">Parou de responder</p>
        <p>{stopResponding}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-secondary">Desistiu</p>
        <p>{gaveUp}</p>
      </div>
    </div>
  )
}